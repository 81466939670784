import React from 'react';
import { useEffect, useState, useRef } from 'react';
import Filters from '../dashboard/Filters';
import Token from '../dashboard/Token';
import Featured from '../dashboard/Featured';
import MiniSwapper from '../dashboard/MiniSwapper';
import { useParams } from "react-router-dom";

export default function Dashboard() {

    const { chain } = useParams();

    const [data, setData] = useState([]);
    const dataRefetch = useRef([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    const [page, setPage] = useState(1);
    const [loadingMore, setLoadingMore] = useState(false);
    const [maxLoaded, setmaxLoaded] = useState(false);
    const [etherfunFilter,setetherfunFilter] = useState("all")
    const [filters, setFilters] = useState("fdv");
    const [order, setOrder] = useState("DESC");

    const [searchTerm, setSearchTerm] = useState("");
    const [search, setSearch] = useState("");
    const [isSearching, setIsSearching] = useState(false); // New state to track search mode

    const [shakeIndices, setShakeIndices] = useState([]);
    const [isConnected, setIsConnected] = useState(false);
    const [walletAddress, setWalletAddress] = useState("");
    const itemsPerPage = 30; 

    const changeFilters = async (newFilters) => {
        setLoading(true);
        if (newFilters === filters) {
            if (order === "DESC") {
                setOrder("ASC");
            } else if (order === "ASC") {
                setOrder("DESC");
            }
        } else {
            setFilters(newFilters);
            setOrder("DESC");
        }
    };
    const handleEFfilter = async (filter) => {
        setetherfunFilter(filter)

    }
    const searchFilters = async (search) => {
        try {
            setError(null);
            if (search === '') {
                setIsSearching(false); // Reset search mode
                await fetchData();
                return;
            }
            setIsSearching(true); // Set search mode
            setLoading(true);
            setData([]);
            let response = await fetch(
                `https://etherfun.pro/vistadex/search?query=${search}&chain=ethereum`
            );

            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            const fetchedData = await response.json();
            setData(fetchedData.data);
        } catch (error) {
            setError(error);
            //console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const fetchData = async () => {
        try {
            setError(null);
            setLoading(true);
            setPage(1); // Reset page to 1
            let response;
            if(etherfunFilter === 'etherfun'){
                response = await fetch(
                    `https://etherfun.pro/vistadex/EF?page=1&sort=${filters}:${order}&chain=${chain}`

                );
            } else {
                response = await fetch(                    
                    `https://etherfun.pro/vistadex/tokens?page=1&sort=${filters}:${order}&chain=${chain}`
                );
            }

            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            const fetchedData = await response.json();
            console.log(fetchedData)
            if(fetchedData.data.length < 50){
                setmaxLoaded(true)
            }else{
                setmaxLoaded(false)

            }
            setData(fetchedData.data);
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    // Handle "Load More" button click
    const handleLoadMore = async () => {
        try {
            setError(null);
            setLoadingMore(true); // Start loading more data
            const nextPage = page + 1;
            let response;
            if(etherfunFilter === 'etherfun'){
                response = await fetch(
                    `https://etherfun.pro/vistadex/EF?page=${nextPage}&sort=${filters}:${order}&chain=${chain}`

                );
            } else {
                response = await fetch(                    
                    `https://etherfun.pro/vistadex/tokens?page=${nextPage}&sort=${filters}:${order}&chain=${chain}`
                );
            }


            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            const fetchedData = await response.json();
            
            ////console.log(fetchedData);
            setData(prevData => [...prevData, ...fetchedData.data]);
            
            setPage(nextPage); // Update page number
            if(fetchedData.data.length < 50){
              setmaxLoaded(true)
            }
        } catch (error) {
            setError(error);
            //console.log(error);
        } finally {
            setLoadingMore(false); // End loading more data
        }
    };

    useEffect(() => {
        document.title = "Ethervista | Dashboard";
    }, []);

    useEffect(() => {
       fetchData()
    }, [etherfunFilter,chain]);

    useEffect(() => {
        if (filters || order) {
            setIsSearching(false); // Reset search mode when filters change
            fetchData();
        }
    }, [filters, order]);
    const [opacity, setOpacity] = useState(1);

    useEffect(() => {
      const handleScroll = () => {
        const newOpacity = Math.max(1 - window.scrollY / 500, 0); // Adjust divisor for sensitivity
        setOpacity(newOpacity);
      };
  
      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    }, []);
    return (
        <div className='px-4 mt-20 sm:mt-32'>
            {/*<div
                style={{ opacity }}
                className=" transition-opacity duration-100 mt-36 mb-16"
            >

    
                <MiniSwapper />

            </div>*/}

            {/* 3 TABLES */}
            <div className='text-start text-2xl md:text-3xl mx-auto w-[1240px] font-[600] noto-sans-display flex'>
                {chain.toUpperCase()}
                <div className="relative ml-2 group">
              <svg
                className="w-4 h-4 text-gray-400 cursor-pointer"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M18 10c0 4.418-3.582 8-8 8s-8-3.582-8-8 3.582-8 8-8 8 3.582 8 8zM9 7v2h2V7H9zm0 4v4h2v-4H9z" />
              </svg>
              <div className="absolute top-full left-1/2 transform kanit-light font-[300] text-white/70 -translate-x-1/2 mb-2 w-64 p-2 bg-[#0e1018] border border-white/15 text-xs rounded-lg hidden group-hover:block transition-opacity duration-200">
              Ethervista is the first DEX to charge fees in ETH instead of tokens. Each token can have custom fees set by its own creator, providing a direct revenue stream for projects. Liquidity providers earn ETH from "LP fees", while "protocol fees" enable creators to execute arbitrary smart contract logic using the ETH generated from every swap. Creators can permanently lock their liquidity and still earn ETH rewards from LP fees. Additionally, users are safeguarded by a mandatory 5-day liquidity lock after launch, which mitigates risks and enhances trust in new projects
              </div>
            </div>
            </div>

            <Featured />

            <div className='h-12'></div>
            <div className='flex gap-2 mx-auto max-w-[1240px] mb-8 sm:mb-4 justify-center sm:justify-start w-full  sm:mt-8 text-[12px] font-[600] text-gray-300 noto-sans-display'>
                
                <button
                onClick={() => handleEFfilter("all")}
                className={`rounded-lg px-3 py-[2px] ${
                    etherfunFilter === "all" ? "bg-white/10 text-white" : "bg-white/0 hover:bg-white/10 hover:text-white"
                }`}
                >
                All
                </button>
                <button
                onClick={() => handleEFfilter("etherfun")}
                className={`rounded-lg px-3 py-[2px] ${
                    etherfunFilter === "etherfun" ? "bg-white/10 text-white" : "bg-white/0 hover:bg-white/10 hover:text-white"
                }`}
                >
                Etherfun Launched
                </button>

            </div>
            {/* FILTERS */}
            <Filters
                order={order}
                filters={filters}
                changeFilters={changeFilters}
                searchFilters={searchFilters}
            />

            {/* TOKENS LIST*/}
            <div className='flex-row mx-auto max-w-[1240px] w-full h-auto '>
                {loading ? (
                    // Display skeletons while loading
                    Array.from({ length: 50 }).map((_, index) => (
                      <div className='flex items-center justify-between outfit font-[600] w-full hover:bg-gray-500/5 py-5 rounded-sm px-4'>
                      <div className='flex gap-2 items-center kanit-light'>
                        <div className='w-6 h-6 bg-gray-800 rounded-full animate-pulse'></div>
                        <div className='w-[30px] h-[17px] bg-gray-800 rounded-[4px] animate-pulse'></div>
                        <div className='w-[70px] h-[15px] bg-gray-800 rounded-[4px] ml-1 animate-pulse'></div>
                      </div>
                      <div className='flex gap-[135px] text-[13px] outfit text-gray-200 font-[400] items-center'>
                        <div className='w-[40px] h-[17px] bg-gray-800 rounded-[4px] animate-pulse'></div>
                        
                        {/* Placeholder for conditional color (24h Change) */}
                        <div className='w-[40px] h-[17px] bg-gray-800 rounded-[4px] animate-pulse'></div>
                
                        <div className='w-[40px] h-[17px] bg-gray-800 rounded-[4px] animate-pulse'></div>
                        <div className='w-[30px] h-[17px] bg-gray-800 rounded-[4px] animate-pulse'></div>
                
                        {/* Placeholder for conditional color (APY) */}
                        <div className='w-[30px] h-[17px] bg-gray-800 rounded-[4px] animate-pulse'></div>
                      </div>
                    </div>
                    ))
                ) : (
                    <>
                        {data.map((token, index) => (
                          <Token key={index} token={token} />
                      ))}
                      {/* Show "Load More" button if not searching */}
                      {!isSearching && (
                          loadingMore ? (

                              <div></div>
                          ) : (
                              // "Load More" button
                             maxLoaded === false && <button onClick={handleLoadMore} className='px-4 py-2 rounded-full border outfit mt-8 text-gray-400 font-semibold border-white/20 text-[15px] hover:bg-white/10'>Load More</button>
                          )
                      )}
                      <div className='h-20'></div>

                </>
                )}
            </div>
        </div>
    );
}
