import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Login from '../components/common/Login';
import { useParams } from "react-router-dom";
import ChainDropdown from './ChainDropdown'


function getChainFromUrl() {
  const path = window.location.pathname; // e.g., "/ethereum", "/base", "/ethereum/swap", etc.
  const segments = path.split("/").filter(Boolean); // splits into ["ethereum", "swap"] or ["base"]
  
  const firstSegment = segments[0];
  if (firstSegment === "ethereum" || firstSegment === "base") {
    return firstSegment;
  }
  
  return null; // default can be handled later if needed
}

export default function Navbar() {

  const location = useLocation();
  const chain = getChainFromUrl(); // "ethereum" or "base"



  return (
    <div className="flex gap-2 pb-0 w-full overflow-x-hidden h-16 items-center px-1 sm:px-4 xl:px-12 pt-1 navbar">
      <div className="w-full h-full flex items-center">
        <ul className="flex justify-between w-full [#bbbbbb]">
          <li className="flex justify-start items-center">
            <Link to={`/${chain}`} className="hover:underline flex items-center ">
              <img
                className="w-auto h-5 sm:h-7 ml-1 sm:ml-1"
                src="/EthervistaSmall.png"
                alt="Ethervistaa"
              />
            </Link>
            <Link to={`/${chain}`} className='hidden xl:flex items-center'>
              <h1 className='pl-2 kanit-light font-[500] text-[19px] [#bbbbbb]'>ETHERVISTA</h1>
            </Link>
            <li className="flex justify-center sm:gap-1  mt-[2px] kanit-light font-[400] ml-[5px] md:ml-[20px] lg:ml-[26px] items-center text-[9px] xxxs:text-[11px] xxs:text-[12px] xs:text-[14px] sm:text-[14px] lg:text-[15px] text-[#cfcfcf]">
              <Link 
                to={`/${chain}`} 
                className={`px-2 lg:px-4 py-1 hover:bg-white/0 rounded-lg hidden sm:block hover:text-[#f4f4f4] ${(location.pathname === "/"|| location.pathname.includes("/token")) ? "text-[#f4f4f4]" : ""}`}
              >
                Market
              </Link>
              <Link 
                to={`/${chain}/swap`} 
                className={`px-2 lg:px-4 py-1 hover:bg-white/0 rounded-lg hover:text-[#f4f4f4] ${location.pathname === "/swap" ? "text-[#f4f4f4]" : ""}`}
              >
                Swap
              </Link>
              <Link 
                to={`/${chain}/hardlock`} 
                className={`px-2 lg:px-4 py-1 hover:bg-white/0 rounded-lg hover:text-[#f4f4f4]  ${location.pathname === "/hardlock" ? "text-[#f4f4f4]" : ""}`}
              >
                Hardlock
              </Link>
              {chain !== "base" &&<Link 
                to={`/${chain}/hardstake`} 
                className={`px-2 lg:px-4 py-1 hover:bg-white/0 rounded-lg hover:text-[#f4f4f4] ${location.pathname === "/hardstake" ? "text-[#f4f4f4]" : ""}`}
              >
                Hardstake
              </Link>}
              <Link 
                to={`/${chain}/launch`} 
                className={`px-2 lg:px-4 py-1 hover:bg-white/0 rounded-lg hover:text-[#f4f4f4] ${location.pathname === "/launch" ? "text-[#f4f4f4]" : ""}`}
              >
                Launch
              </Link>
              <a
                href='https://etherfun.app/' target='blank'
                className={`px-2 lg:px-4 py-1 hover:bg-white/0 rounded-lg hover:text-[#f4f4f4] hidden md:block `}
              >
                Etherfun
              </a>
            </li>        
          </li>

          <li className=" justify-end gap-3 items-center flex">
            {/*<ChainDropdown />*/}
            <a href='https://docs.ethervista.app/' className='hidden lg:flex' rel="noreferrer" target='_blank'>
              <img alt='whitepaper' className='w-4 h-4 mr-1 text-gray-100 opacity-80 hover:opacity-100' src='/whitepaper.svg' />
            </a>
            <a href='https://x.com/ethervista' className='hidden lg:flex' rel="noreferrer" target='_blank'>
              <img alt='x' className='w-4 h-4 mr-4 text-gray-300 opacity-80 hover:opacity-100' src='/xicon.png' />
            </a>
            <Login chain={chain} />
            <div className="hidden xl:block"><ChainDropdown  /></div>

          </li>
        </ul>
      </div>
    </div>
  );
}
