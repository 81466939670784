const { ethers } = require("ethers");
const { abi } = require("./saleAbi.json");
const { abi: routerABI } = require("./router.json");

const provider = new ethers.JsonRpcProvider(
  "https://mad-ultimate-halibut.callstaticrpc.com/"
); // Uses default provider, adjust as needed
function getContract(contractAddress) {
  return new ethers.Contract(contractAddress, abi, provider);
}
/* global BigInt */

//======== ALL OF THESE ARE READ ONLY =============

//Function to get ETH amount for a given token amount (SELL)
async function getEthIn(contractAddress, tokenAmount) {
  try {
    const contract = getContract(contractAddress);
    const ethAmount = await contract.getEthIn(
      BigInt(Math.round(tokenAmount * 10 ** 18))
    );
    return ethAmount.toString(); //has 18 decimals
  } catch (error) {
    // console.error("Error fetching ETH amount:", error); // Removed //console.log
    throw error;
  }
}

// Function to get token amount for a given ETH amount (BUY)
async function getTokenIn(contractAddress, ethAmount) {
  try {
    const contract = getContract(contractAddress);
    const tokenAmount = await contract.getTokenIn(
      BigInt(Math.round(ethAmount * 10 ** 18))
    );
    return tokenAmount.toString(); //has 18 decimals
  } catch (error) {
    // console.error("Error fetching token amount:", error); // Removed //console.log
    throw error;
  }
}

async function getTokenHoldersCount(contractAddress) {
  try {
    const contract = getContract(contractAddress);
    const count = await contract.getTokenHoldersCount();
    return Number(count);
  } catch (error) {
    // console.error("Error fetching token holders count:", error); // Removed //console.log
    throw error;
  }
}

//gives balances for all token holders
async function getAllTokenHolders(contractAddress) {
  try {
    const contract = getContract(contractAddress);
    const holders = await contract.getAllTokenHolders();

    const holdersWithBalances = await Promise.all(
      holders.map(async (holder) => {
        const balance = await contract.tokenBalances(holder);
        return {
          user: holder,
          balance: (Number(balance) / 10 ** 18).toString(),
        };
      })
    );

    return holdersWithBalances;
  } catch (error) {
    // console.error("Error fetching all token holders with balances:", error); // Removed //console.log
    throw error;
  }
}

//Check if price formula is correct
async function getAllHistoricalData(contractAddress) {
  try {
    const routerContract = new ethers.Contract(
      "0xCEDd366065A146a039B92Db35756ecD7688FCC77",
      routerABI,
      provider
    );
    const contract = getContract(contractAddress);
    const historicalData = await contract.getAllHistoricalData();

    const k = 0.222;
    const alpha = 2878e-12;
    const eth_price = Math.round(
      1 / (Number(BigInt(await routerContract.usdcToEth(1))) / 10 ** 18)
    );

    return historicalData.map((data) => ({
      timestamp: Number(data.timestamp),
      price: eth_price * alpha * (k + Number(data.totalRaised) / 10 ** 18),
    }));
  } catch (error) {
    // console.error("Error fetching all historical data:", error); // Removed //console.log
    throw error;
  }
}

//Include in get holders
async function getTokenBalance(contractAddress, userAddress) {
  try {
    const contract = getContract(contractAddress);
    const balance = await contract.tokenBalances(userAddress);
    return (Number(balance) / 10 ** 18).toString();
  } catch (error) {
    // console.error("Error fetching token balance:", error); // Removed //console.log
    throw error;
  }
}

//filter by useful
async function getContractData(contractAddress) {
  try {
    const contract = getContract(contractAddress);
    const [
      token,
      creator,
      factory,
      totalTokens,
      totalRaised,
      maxContribution,
      creatorshare,
      launched,
      status,
      k,
      alpha,
      saleGoal,
      tokensSold,
    ] = await Promise.all([
      contract.token(),
      contract.creator(),
      contract.factory(),
      contract.totalTokens(),
      contract.totalRaised(),
      contract.maxContribution(),
      contract.creatorshare(),
      contract.launched(),
      contract.status(),
      contract.k(),
      contract.alpha(),
      contract.saleGoal(),
      contract.tokensSold(),
    ]);

    return {
      token,
      creator,
      factory,
      totalTokens: totalTokens.toString(),
      totalRaised: totalRaised.toString(),
      maxContribution: maxContribution.toString(),
      creatorshare: creatorshare,
      launched,
      status,
      k: k.toString(),
      alpha: alpha.toString(),
      saleGoal: saleGoal.toString(),
      tokensSold: tokensSold.toString(),
    };
  } catch (error) {
    console.error("Error fetching contract data:", error);
    throw error;
  }
}

/*
const tokensPurchasedEvents = await getAllEvents('TokensPurchased');
const tokensSoldEvents = await getAllEvents('TokensSold');
*/

/*
async function getAllEvents(contractAddress, eventName, fromBlock = 0, toBlock = 'latest') {
  try {
    const contract = getContract(contractAddress);
    const filter = contract.filters[eventName]();
    const events = await contract.queryFilter(filter, fromBlock, toBlock);
    return events.map(event => ({
      transactionHash: event.transactionHash,
      args: event.args
    }));
  } catch (error) {
    console.error(`Error fetching ${eventName} events:`, error);
    throw error;
  }
}
*/
//filter by useful

/*
const tokensPurchasedEvents = await getAllEvents('TokensPurchased');
const tokensSoldEvents = await getAllEvents('TokensSold');
*/

/*
async function getAllEvents(contractAddress, eventName, fromBlock = 0, toBlock = 'latest') {
  try {
    const contract = getContract(contractAddress);
    const filter = contract.filters[eventName]();
    const events = await contract.queryFilter(filter, fromBlock, toBlock);
    return events.map(event => ({
      transactionHash: event.transactionHash,
      args: event.args
    }));
  } catch (error) {
    console.error(`Error fetching ${eventName} events:`, error);
    throw error;
  }
}
*/

module.exports = {
  getEthIn,
  getTokenIn,
  getTokenHoldersCount,
  getAllTokenHolders,
  getAllHistoricalData,
  getTokenBalance,
  getContractData,
};
