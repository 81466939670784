import React, { useState } from 'react';
import { quote,deployLiquidity,quoteMintLiquidity } from '../../functions/dexfunctions'
import { popupManager } from '../common/PopupManager';
import { useParams } from "react-router-dom";
export default function AddLiquidity({reserve,tokenAddress,signer,balance,dataToken,chain}) {
  const [ethAmount, setEthAmount] = useState('');
  const [tokenAmount, setTokenAmount] = useState('');
  const poolRatio = 100; // Example: 1 ETH = 100 Tokens (replace with actual pool ratio)
  const ethAddress = (chain === "base" ? "0x4200000000000000000000000000000000000006" : chain === 'ethereum' ? "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2" :"")

  const [loadingQuote,setloadingQuote] = useState(false)
  const [LPtokensOut,setLPtokensOut] = useState(0)
  
  const handleEthChange = (e) => {

    const value = e.target.value;
    setEthAmount(value);
    
    const tokens = quote(value,reserve[0],reserve[1])
    getLpTokensOut(value,tokens)
    setTokenAmount(tokens);
  };

  const handleTokenChange = (e) => {
    const value = e.target.value;
    setTokenAmount(value);

    const eth = quote(value,reserve[1],reserve[0])
    setEthAmount(eth);
    getLpTokensOut(eth,value)

  };
  const getLpTokensOut = async (ethAmount,tokenAmount) => {
    setloadingQuote(true)
    const tokensout = await quoteMintLiquidity(ethAddress,tokenAddress,Math.round(ethAmount*10**10)/(10**10),tokenAmount,signer,chain)
   console.log(tokensout)
    setLPtokensOut(tokensout)
    setloadingQuote(false)

  }
  const deploy = async () => {
    let id = Date.now();
    try{
    if (!signer) {
      popupManager.addPopup({
        id: Date.now(),
        type: "error",
        message: "Please connect your wallet before buying tokens.",
        duration: 6000,
      });
      return;
    }
    let chainIDHex = await window.ethereum.request({ method: "eth_chainId" });
    const chainId = await parseInt(chainIDHex, 16);
    let chainIdURL = chain === "ethereum" ? 1 : chain === "base" ?  8453 : 1;
    if (chainId !== chainIdURL) {
      popupManager.addPopup({
        id: Date.now(),
        type: "error",
        message: "Please Connect to Right Network! ",
        duration: 6000,
      });
      return;
    }
    console.log(tokenAmount)
    popupManager.addPopup({
      id: id,
      type: "message",
      message: `Adding ${ethAmount} ETH and ${tokenAmount} Tokens of Liquidity`,
    });
    const resp = await deployLiquidity(
        ethAddress,
        tokenAddress,
        ethAmount.toString(),
        tokenAmount.toString(),
        (parseFloat(ethAmount)*0.95.toFixed(6)).toString(),
        (parseFloat(tokenAmount)*0.95.toFixed(6)).toString(),
        signer,
        ethAddress,
        chain
    )
      popupManager.removePopup(id);
      let successful_id = Date.now();

      popupManager.addPopup({
        id: successful_id,
        type: "success",
        message:
          "Transaction sent!",
      });
      setEthAmount(0);
      setTokenAmount(0);
    }catch(e){
      popupManager.removePopup(id);
      console.log(e)
      popupManager.addPopup({
        id: Date.now(),
        type: "error",
        message: typeof e === 'string' ? e : e.toString(),
        duration: 6000,
      });
    }
    
  }
  return (
    <div className="py-1  rounded-lg shadow-md w-full  mx-auto">
      <form>
        <div className="mb-4">
          <label className="block text-start text-[13px] text-gray-300 font-[500] kanit-light mb-2" htmlFor="ethAmount">
            ETH Amount
          </label>
          <input
            type="number"
            id="ethAmount"
            value={ethAmount}
            onChange={handleEthChange}
            placeholder="ETH"
            className="w-full p-2 py-4  text-[13px] border border-white/15 text-gray-300 rounded-md bg-transparent focus:outline-none  "
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-300 text-[13px] text-start font-medium mb-2" htmlFor="tokenAmount">
            {dataToken.symbol} Amount
          </label>
          <input
            type="number"
            id="tokenAmount"
            value={tokenAmount}
            onChange={handleTokenChange}
            placeholder="Token"
            className="w-full p-2 py-4 text-[13px] text-gray-300  border border-white/15 rounded-md bg-transparent focus:outline-none"
          />
        </div>
        <div className='h-[1px] bg-white/10 w-full mt-4'></div>
        {loadingQuote === true ? 
        <div className='h-6 animate-pulse bg-white/5 mt-3 mb-4'></div>
        :
        <div className='h-6  noto-sans-display text-[#7b7f8f] text-[13px] font-[400] text-start mt-3 mb-4'>You will get {Math.round(LPtokensOut*10000)/10000} LP Tokens</div> 
        }
        {(balance[0] < tokenAmount || balance[1] < ethAmount)?
            <button
            disabled
                type="button"
                className="w-full text-white/50 bg-white/5 border  border-white/10 py-2 rounded-md font-semibold  transition duration-300 mb-8"
                >
                Not enough Balance
            </button>
            :
            <button
            type="button"
            className="w-full   text-white bg-white/5 hover:bg-white/10 border  border-white/10 py-2 rounded-md font-semibold  transition duration-300 mb-8"
            onClick={deploy}
            >
            Add 
            </button>        
        }
        
      </form>
    </div>
  );
}
