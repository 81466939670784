import React, { useState, useEffect } from 'react';
import { getStatsSTAKE,getStakerInfoSTAKE,stake,unstake,claimRewardsSTAKE } from '../../functions/dexfunctions'
import { ethers } from 'ethers';
import { popupManager } from '../common/PopupManager';
export default function Hardstake() {
  const [tokenAmount, setTokenAmount] = useState(0);
  const [stakedAmount, setStakedAmount] = useState(0);
  const [timeLeft, setTimeLeft] = useState(0); // in seconds
  const [signer, setSigner] = useState(null);
  const [provider, setProvider] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  const [rewardsStats,setRewardsStats] = useState([]);
  // Countdown timer for "Time Left"

  const fetchStakeInfo = async (signerInstance) => {
    try{
      const info = await getStakerInfoSTAKE(signerInstance)
      const rewards = await getStatsSTAKE(signerInstance)
      setRewardsStats(rewards)
      setStakedAmount(info.amount)
      setTimeLeft(info.timeLeftFormatted)
    }catch(e){
      //console.log(e)
    }
  }

  const initializeProvider = async () => {
    try {
      let provider;
      let signerInstance;

      if (window.ethereum == null) {

        provider = ethers.getDefaultProvider();
      } else {
        provider = new ethers.BrowserProvider(window.ethereum);
        signerInstance = await provider.getSigner();
        await setProvider(provider);
        await setSigner(signerInstance);
        fetchStakeInfo(signerInstance)
        
        setIsConnected(true);
          
      }

    } catch (e) {
     //console.log(e)
    }
  };

  useEffect(() => {
    const handleAccountsChanged = async (accounts) => {
      if (accounts.length === 0) {
        // User disconnected all accounts
        setSigner(null);
        setProvider(null);
      } else {
        // User switched accounts
        const newAccount = accounts[0];

        const provider = new ethers.BrowserProvider(window.ethereum);
        const signer = await provider.getSigner();
        setProvider(provider);
        setSigner(signer);

        // Detect wallet type again if necessary
        let detectedWallet = "Unknown Wallet";
        if (window.ethereum.isMetaMask) {
          detectedWallet = "MetaMask";
        } else if (window.ethereum.isCoinbaseWallet) {
          detectedWallet = "Coinbase Wallet";
        } else if (window.ethereum.isBraveWallet) {
          detectedWallet = "Brave Wallet";
        } else if (window.ethereum.isTrust) {
          detectedWallet = "Trust Wallet";
        }
      }
    };

    if (window.ethereum) {
      window.ethereum.on("accountsChanged", handleAccountsChanged);
    }

    initializeProvider();

    return () => {
      if (window.ethereum) {
        window.ethereum.removeListener("accountsChanged", handleAccountsChanged);
      }
    };
  }, []);




  useEffect(() => {
    let timer;
    if (timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prev) => prev - 1);
      }, 1000);
    } else if (timeLeft === 0 && stakedAmount > 0) {
      alert('Lock time has expired. You can now unstake your tokens.');
    }
    return () => clearInterval(timer);
  }, [timeLeft, stakedAmount]);

  // Handle staking
  const handleStake = async () => {
    let id = Date.now();
    try{
      let chainIDHex = await window.ethereum.request({ method: "eth_chainId" });
      const chainId = await parseInt(chainIDHex, 16);
      //console.log(chainId);
      if (chainId !== 1) {
        setIsConnected(false);
        popupManager.addPopup({
          id: Date.now(),
          type: "error",
          message: "Please Connect to ETH Network! ",
          duration: 6000,
        });
        return;
      }
      popupManager.addPopup({
        id: id,
        type: "message",
        message: "Staking "+tokenAmount+"...",
      });

    const response = await stake(tokenAmount,signer)
        
    popupManager.removePopup(id);
    let successful_id = Date.now();

      popupManager.addPopup({
        id: successful_id,
        type: "success",
        message:
          "Transaction sent!",
      });
    //console.log(response)      
    }catch(e){
      popupManager.removePopup(id);
      popupManager.addPopup({
        id: Date.now(),
        type: "error",
        message: e.toString(),
        duration: 6000,
      });
    }

  };
  
  const handleClaimRewardsStake = async () => {
    let id = Date.now();

  try{
    let chainIDHex = await window.ethereum.request({ method: "eth_chainId" });
    const chainId = await parseInt(chainIDHex, 16);
    //console.log(chainId);
    if (chainId !== 1) {
      setIsConnected(false);
      popupManager.addPopup({
        id: Date.now(),
        type: "error",
        message: "Please Connect to ETH Network! ",
        duration: 6000,
      });
      return;
    }
    popupManager.addPopup({
      id: id,
      type: "message",
      message: "Claiming Stake Rewards",
    });

    const resp = await claimRewardsSTAKE(signer)
    popupManager.removePopup(id);
    let successful_id = Date.now();

    popupManager.addPopup({
      id: successful_id,
      type: "success",
      message:
        "Transaction sent!",
    });
    //console.log(resp)
  }catch(e){
    popupManager.removePopup(id);
    popupManager.addPopup({
      id: Date.now(),
      type: "error",
      message: e.toString(),
      duration: 6000,
    });
    //console.log(e)
  }
  
  }
  // Handle unstaking
  const handleUnstake = async () => {
    let id = Date.now();
    try{
      let chainIDHex = await window.ethereum.request({ method: "eth_chainId" });
      const chainId = await parseInt(chainIDHex, 16);
      //console.log(chainId);
      if (chainId !== 1) {
        setIsConnected(false);
        popupManager.addPopup({
          id: Date.now(),
          type: "error",
          message: "Please Connect to ETH Network! ",
          duration: 6000,
        });
        return;
      }
      popupManager.addPopup({
        id: id,
        type: "message",
        message: "Unstaking "+tokenAmount,
      });

      const response = await unstake(tokenAmount,signer)
      popupManager.removePopup(id);
      let successful_id = Date.now();

      popupManager.addPopup({
        id: successful_id,
        type: "success",
        message:
          "Transaction sent!",
      });
      //console.log(response)
    }catch(e){
      popupManager.removePopup(id);
      popupManager.addPopup({
        id: Date.now(),
        type: "error",
        message: e.toString(),
        duration: 6000,
      });
        }
 
  };


  return (
    <div className="max-w-xl mx-auto mt-32 px-4 sm:px-0">
      <h2 className="text-[30px] font-[500] mb-12 kanit-light flex">STAKE VISTA
      <div className="relative group ml-2">
          <svg
            className="w-4 h-4 text-gray-400 cursor-pointer"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path d="M18 10c0 4.418-3.582 8-8 8s-8-3.582-8-8 3.582-8 8-8 8 3.582 8 8zM9 7v2h2V7H9zm0 4v4h2v-4H9z" />
          </svg>
          <div
            className="absolute top-full left-1/2 transform -translate-x-1/2 mt-2 w-64 p-2 bg-[#0e1018] border border-white/15 text-white text-xs rounded-lg 
            hidden group-hover:block transition-opacity duration-200 font-[300] text-white"
          >
             Stake your VISTA tokens for a minimum of two weeks to earn ETH rewards generated from protocol fees on every swap
          </div>
        </div>
        
      </h2>

      {/* Input Field */}
      <div className="mb-4">
        <label htmlFor="tokenAmount" className="block text-white mb-2 text-start">
          Enter Amount of Tokens:
        </label>
        <input
          type="number"
          id="tokenAmount"
          value={tokenAmount}
          onChange={(e) => setTokenAmount(e.target.value)}
          placeholder="Token Amount"
          className="w-full p-2 border-t border-b text-[15px]  border-white/15 text-gray-300 rounded-md bg-transparent focus:outline-none"
          min="0"
        />
      </div>

      {/* Labels */}
      <div className="mb-4 space-y-2 border-t border-white/10 text-gray-300 pt-12">
        <p className="flex justify-between">
          <p className=''>Staked Amount:</p> <span>{stakedAmount}</span>
        </p>
        <p className="flex justify-between">
          <p>Lock Time:</p> <span>14 days</span>
        </p>
        <p className="flex justify-between">
          <p>Time Left:</p> <span>{timeLeft}</span>
        </p>
        <p className="flex justify-between">
          <p>Your rewards:</p> <span>${rewardsStats[0]}</span>
        </p>
        <p className="flex justify-between">
          <p>All time rewards:</p> <span>${rewardsStats[1]}</span>
        </p>
        <p className="flex justify-between">
          <p>Current Pool:</p> <span>${rewardsStats[2]}</span>
        </p>
      </div>


      {/* Warning */}
      <div className="mb-4 px-4 py-3 bg-white/10 border-l-4 border-white-500 text-white-700">
        <p>
          Please claim any rewards before unstaking, or the rewards will be lost.
        </p>
      </div>

      {/* Buttons */}
      <div className="mb-4 mt-6 flex">
        {isConnected === true ? <><button
          onClick={handleStake}
          disabled={!tokenAmount || tokenAmount <= 0}
          className="px-4 py-2  w-full text-white bg-white/5 hover:bg-white/10 border  border-white/10 py-2 rounded-md font-semibold  transition duration-300"
        >
          Stake
        </button>

            
          <button
            onClick={handleUnstake}
            disabled={stakedAmount <= 0 || tokenAmount <=0}
            className={`${(stakedAmount <= 0 || tokenAmount <=0 )? 'text-white/50':'text-white hover:bg-white/10'} px-4 w-full py-2 mx-2  bg-white/5  border  border-white/10 py-2 rounded-md font-semibold  transition duration-300`}
          >
            Unstake
          </button></>:
          <button
          onClick={initializeProvider}
          className="px-4 py-2  w-full text-white bg-white/5 hover:bg-white/10 border  border-white/10 py-2 rounded-md font-semibold  transition duration-300"
        >
          Connect
        </button>
          }
          {rewardsStats[0] > 0 &&<button
            onClick={handleClaimRewardsStake}
            className={`text-white  px-4 py-2   bg-yellow-300/20 hover:bg-yellow-300/40 w-full transition-color border   border-yellow-300/50 shadow-[0_0_15px_5px_rgba(253,224,71,0.2)] duration-300 rounded-md font-semibold  transition duration-300`}
          >
            Claim
          </button> }   
      </div>
    </div>
  )
}
