import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route, Navigate, useParams } from 'react-router-dom';
import Dashboard from './components/pages/Dashboard';
import Token from './components/pages/Token';
import Navbar from './common/Navbar';
import Swap from './components/pages/Swap';
import Popups from './components/common/Popups';
import Hardlock from './components/pages/Hardlock';
import Hardstake from './components/pages/Hardstake';
import Launch from './components/pages/Launch';
import ChainDropdown from './common/ChainDropdown';

const VALID_CHAINS = ['ethereum', 'base'];

// Validation Wrapper Component
function ChainValidator({ children }) {
  const { chain } = useParams();
  if (!VALID_CHAINS.includes(chain)) {
    return <Navigate replace to="/ethereum" />;
  }
  return children;
}

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Popups />
        <div className="block xl:hidden absolute top-[73px] right-6">
          <ChainDropdown />
        </div>

        <Navbar />
        <Routes>
          {/* Wrap routes that need chain validation */}
          <Route
            path="/:chain"
            element={
              <ChainValidator>
                <Dashboard />
              </ChainValidator>
            }
          />
          <Route
            path="/:chain/swap"
            element={
              <ChainValidator>
                <Swap />
              </ChainValidator>
            }
          />
          <Route
            path="/:chain/hardlock"
            element={
              <ChainValidator>
                <Hardlock />
              </ChainValidator>
            }
          />
          <Route
            path="/:chain/hardstake"
            element={
              <ChainValidator>
                <Hardstake />
              </ChainValidator>
            }
          />
          <Route
            path="/:chain/launch"
            element={
              <ChainValidator>
                <Launch />
              </ChainValidator>
            }
          />
          <Route
            path="/:chain/token/:tokenAddress"
            element={
              <ChainValidator>
                <Token />
              </ChainValidator>
            }
          />

          {/* Default redirect to Ethereum */}
          <Route path="/" element={<Navigate replace to="/ethereum" />} />
        </Routes>
      </BrowserRouter>
      <div className="hidden sm:block fixed bottom-0 right-2 m-4 text-xs text-gray-400 opacity-70">
        Powered by <span className="font-semibold text-white">Ethervista and DegenAds</span>
      </div>
    </div>
  );
}

export default App;
