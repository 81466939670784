import React, { useState, useEffect, useRef } from 'react';

export default function Filters({ filters, order, changeFilters, searchFilters }) {
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);

  // Store a stable reference to searchFilters
  const searchFiltersRef = useRef(searchFilters);

  useEffect(() => {
    searchFiltersRef.current = searchFilters;
  }, [searchFilters]);

  // Update debounced search term after a delay
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 500); // delay of 500ms

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  // Call searchFilters whenever debouncedSearchTerm changes
  useEffect(() => {
      searchFiltersRef.current(debouncedSearchTerm);
    
  }, [debouncedSearchTerm]);

  return (
    <div className="flex justify-end sm:justify-between mx-auto max-w-[1240px] w-full  text-gray-500 text-[11px] sm:text-[13px] font-[500] mt-8  pb-3">
      <div className='hidden sm:flex'>
        {/* Search Bar */}
        <div className='flex gap-3 items-center'>
          <div className='relative flex items-center'>
            <img
              src="/searchIcon.svg"
              alt="Search Icon"
              className="absolute left-3 w-3 h-3 text-gray-500" // Adjust width and height as needed
            />
            <input
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="VISTA.."
              className="pl-10 py-[7px] px-8 lg:pr-20 border border-gray-500/50 focus:border-gray-500/75 text-[11px] bg-gray-700/0 rounded-[15px] placeholder-gray-500 placeholder:text-[12px] focus:outline-none"
            />
          </div>
        </div>
      </div>
      <div className="flex mr-2 sm:mr-12 lg:mr-0 gap-[30px] sm:gap-[50px] md:gap-[70px] lg:gap-[105px] xl:gap-[125px]">
        {/* Price Button */}
        <button
          onClick={() => changeFilters("price")}
          className="flex items-center gap-[5px] hover:text-white/80"
        >
          <div className={`${filters === "price" ? "text-white/80" : ""}`}>
            Price
          </div>
          <div className='w-[7px]'>
            {filters === "price" && (
            <img
              src={
                order === "ASC"
                  ? "/orderasc6.svg"
                  : "/orderdesc6.svg"
              }
              alt="filter arrow"
              className="w-[11px] h-[11px]"
            />
          )}</div>
        </button>

        {/* Change Button */}
        <button
          onClick={() => changeFilters("change24h")}
          className="flex items-center gap-[5px] hover:text-white/80"
        >
          <div className={`${filters === "change24h" ? "text-white/80" : ""}`}>
            Change
          </div>
         <div className='w-[7px]'>
            {filters === "change24h" && (
            <img
              src={
                order === "ASC"
                  ? "/orderasc6.svg"
                  : "/orderdesc6.svg"
              }
              alt="filter arrow"
              className="w-[11px] h-[11px]"
            />
          )}</div> 
        </button>

        {/* Volume Button */}
        <button
          onClick={() => changeFilters("volume")}
          className=" items-center gap-[5px] hover:text-white/80 hidden sm:flex"
        >
          <div className={`${filters === "volume" ? "text-white/80" : ""}`}>
            Volume
          </div>
          <div className='w-[7px]'>
            {filters === "volume" && (
            <img
              src={
                order === "ASC"
                  ? "/orderasc6.svg"
                  : "/orderdesc6.svg"
              }
              alt="filter arrow"
              className="w-[11px] h-[11px]"
            />
          )}</div>
        </button>

        {/* FDV Button */}
        <button
          onClick={() => changeFilters("fdv")}
          className="flex items-center gap-[5px] hover:text-white/80"
        >
          <div className={`${filters === "fdv" ? "text-white/80" : ""}`}>
            FDV
          </div>
          <div className='w-[7px]'>
            {filters === "fdv" && (
            <img
              src={
                order === "ASC"
                  ? "/orderasc6.svg"
                  : "/orderdesc6.svg"
              }
              alt="filter arrow"
              className="w-[11px] h-[11px]"
            />
          )}</div>
        </button>

        {/* APY Button */}
        <button
          onClick={() => changeFilters("apy")}
          className="hidden lg:flex items-center gap-[5px] hover:text-white/80"
        >
          <div className={`${filters === "apy" ? "text-white/80" : ""}`}>
            APY
          </div>
          <div className='w-[7px]'>
            {filters === "apy" && (
            <img
              src={
                order === "ASC"
                  ? "/orderasc6.svg"
                  : "/orderdesc6.svg"
              }
              alt="filter arrow"
              className="w-[11px] h-[11px]"
            />
          )}</div>
        </button>
      </div>
    </div>
  );
}
