import React, { useState, useEffect } from 'react';
import SwapperPopup from './SwapperPopup';
import { ethers } from 'ethers';
import { getBalanceAndSymbol } from '../../functions/dexfunctions';
import { popupManager } from '../common/PopupManager';
import { swapTokens, getAmountOut } from '../../functions/factoryFunctions';
import { useParams } from "react-router-dom";

export default function MiniSwapper() {
  const [tradeDirection, settradeDirection] = useState('buying');
  const [selectedToken, setSelectedToken] = useState({symbol:"Select"}); // This will be the chosen token opposite ETH
  const [ethAmount, setethAmount] = useState(0);
  const [tokenAmount, settokenAmount] = useState(0);
  const [signer, setSigner] = useState(null);
  const [provider, setProvider] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectingToken, setSelectingToken] = useState(null);
  const [featuredTokens, setfeaturedTokens] = useState([]);
  const [ethBalance,setethBalance] = useState(null)
  const [tokenBalance,settokenBalance] = useState(null)
  const [loadingTrade, setLoadingTrade] = useState(false);
  const [loadingToQuote,setloadingToQuote] = useState(false)
  const [loadingFromQuote,setloadingFromQuote] = useState(false)
  const [isSlippagePopupOpen, setIsSlippagePopupOpen] = useState(false);
  const [slippage, setslippage] = useState(0.5); // default slippage
  const [tempSlippage, setTempSlippage] = useState(slippage); // Temporary input state

  // Derived states for tokens and amounts:
  const fromToken = tradeDirection === 'buying' ? 'ETH' : selectedToken.symbol;
  const toToken = tradeDirection === 'buying' ? selectedToken.symbol : 'ETH';
  const fromAmount = tradeDirection === 'buying' ? ethAmount : tokenAmount;
  const toAmount = tradeDirection === 'buying' ? tokenAmount : ethAmount;
  const {chain,tokenAddress } = useParams();

  const ethAddress = (chain === "base" ? "0x4200000000000000000000000000000000000006" : chain === 'ethereum' ? "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2" :"")


  const openSlippagePopup = () => {
    setIsSlippagePopupOpen(true);
    setTempSlippage(slippage)
  };

  const closeSlippagePopup = () => {
    setIsSlippagePopupOpen(false);
  };

  const handleTempSlippageChange = (e) => {
    const value = parseFloat(e);
    
    if (e === "" || (!isNaN(value) && value >= 0.0 && value <= 100)) {
      setTempSlippage(e); // Use raw input value
    }
  };

  const handleSubmitSlippage = () => {
    if(tempSlippage === null || tempSlippage === 0 || tempSlippage === '' || tempSlippage === '0'){
      setslippage(0.5)
      setTempSlippage(0.5)
      closeSlippagePopup();
      return;
    }
    setslippage(tempSlippage); // Update parent state
    closeSlippagePopup();
  };

  const initializeProvider = async () => {
    try {
      let provider;
      let signerInstance;
      let chainIDHex = await window.ethereum.request({ method: "eth_chainId" });
      const chainId = await parseInt(chainIDHex, 16);
      let chainIdURL = chain === "ethereum" ? 1 : chain === "base" ?  8453 : 1;

      if (chainId !== chainIdURL) {
        setIsConnected(false);
        popupManager.addPopup({
          id: Date.now(),
          type: "error",
          message: "Please Connect to Right Network! ",
          duration: 6000,
        });
        return;
      }
      if (window.ethereum == null) {

        provider = ethers.getDefaultProvider();
      } else {
        provider = new ethers.BrowserProvider(window.ethereum);
        signerInstance = await provider.getSigner();
        await setProvider(provider);
        await setSigner(signerInstance);
        let balances = await getBalanceAndSymbol(ethAddress,ethAddress,signerInstance)
        setethBalance(balances)
        setIsConnected(true);   
      }
     

    } catch (e) {
     //console.log(e)
    }
  };

  useEffect(() => {
    initializeProvider();

    const handleAccountsChanged = async (accounts) => {
      if (accounts.length === 0) {
        // User disconnected all accounts
        setSigner(null);
        setProvider(null);
      } else {
        // User switched accounts
        const newAccount = accounts[0];

        const provider = new ethers.BrowserProvider(window.ethereum);
        const signer = await provider.getSigner();
        setProvider(provider);
        setSigner(signer);

        // Detect wallet type again if necessary
        let detectedWallet = "Unknown Wallet";
        if (window.ethereum.isMetaMask) {
          detectedWallet = "MetaMask";
        } else if (window.ethereum.isCoinbaseWallet) {
          detectedWallet = "Coinbase Wallet";
        } else if (window.ethereum.isBraveWallet) {
          detectedWallet = "Brave Wallet";
        } else if (window.ethereum.isTrust) {
          detectedWallet = "Trust Wallet";
        }
      }
    };

    if (window.ethereum) {
      window.ethereum.on("accountsChanged", handleAccountsChanged);
    }


    return () => {
      if (window.ethereum) {
        window.ethereum.removeListener("accountsChanged", handleAccountsChanged);
      }
    };

  }, []);
  const changeDirection = () => {
    // Toggle between buying and selling
    if (tradeDirection === 'buying') {
      settradeDirection('selling');
    } else {
      settradeDirection('buying');
    }
  };

  const fetchTokens = async () => {
    const response = await fetch('https://etherfun.pro/vistadex/tokens?page=1&sort=fdv:DESC&chain='+chain);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    setfeaturedTokens(data.data.slice(1, 20));
  };

  useEffect(() => {
    fetchTokens();
  }, []);

  const refreshQuotes = async () =>{
    if(tradeDirection === "buying"){
      handleETHChange(ethAmount)
    }
    else if(tradeDirection === "selling"){
      handleTokenChange(tokenAmount)
    }
  }
  const fetchTokenBalance = async (token) => {
    try{
      console.log(token.address)
      console.log(ethAddress)
      console.log(signer)
      let balances = await getBalanceAndSymbol(token.address,ethAddress,signer)
      console.log(balances)
      settokenBalance(balances)
    }catch(e){
      //console.log(e)
    }
  }
  const handleSelectToken = (token) => {
    setSelectedToken(token);
    setIsPopupOpen(false);
    fetchTokenBalance(token)
    if(ethAmount>0 ){
      handleETHChange(ethAmount.toString(),true,token)
    }
    else if(tokenAmount>0  ){
      handleTokenChange(tokenAmount)
    }
  };

  const handleETHChange = async (amount,select,token) => {
    let tokenTarget;
    if(select === true){
      tokenTarget=token
    }else{
      tokenTarget = selectedToken
    }
    setethAmount(amount);
    if(tokenTarget.symbol === "Select"){
      return;
    }
    if(tradeDirection === "buying"){
      setloadingToQuote(true)
      let tokenCount = await getAmountOut(ethAddress,tokenTarget.address,amount,chain);
      //console.log(tokenCount)
      settokenAmount(tokenCount)
      setloadingToQuote(false)
    }
    else if(tradeDirection === "selling"){
      setloadingFromQuote(true)
      let tokenCount = await getAmountOut(ethAddress,tokenTarget.address,amount,chain);
      //console.log(tokenCount)
      settokenAmount(tokenCount)
      setloadingFromQuote(false)

    }
  }
  const handleTokenChange = async (amount) => {
    settokenAmount(amount);
    if(selectedToken.symbol === "Select"){
      return;
    }
    if(tradeDirection === "buying"){
      setloadingFromQuote(true)
      let tokenCount = await getAmountOut(selectedToken.address,ethAddress,amount,chain);
      //console.log(tokenCount)
      setethAmount(tokenCount)
      setloadingFromQuote(false)
    }
    else if(tradeDirection === "selling"){
      setloadingToQuote(true)
      let tokenCount = await getAmountOut(selectedToken.address,ethAddress,amount,chain);
      //console.log(tokenCount)
      setethAmount(tokenCount)
      setloadingToQuote(false)

    }
  }


  const SwapBuy = async () => {
    if (!isConnected || !provider || !signer) {
      popupManager.addPopup({
        id: Date.now(),
        type: "error",
        message: "Please connect your wallet before buying tokens.",
        duration: 6000,
      });
      return;
    }
    let chainIDHex = await window.ethereum.request({ method: "eth_chainId" });
    const chainId = await parseInt(chainIDHex, 16);
    let chainIdURL = chain === "ethereum" ? 1 : chain === "base" ?  8453 : 1;

    if (chainId !== chainIdURL) {
      setIsConnected(false);
      popupManager.addPopup({
        id: Date.now(),
        type: "error",
        message: "Please Connect to Correct Network! ",
        duration: 6000,
      });
      return;
    }
    let id = Date.now();

    try {
      setLoadingTrade(true)
      let tx_signer = await provider.getSigner();
      popupManager.addPopup({
        id: id,
        type: "message",
        message: "Swapping " + ethAmount + " ETH of " + selectedToken.address,
      });

      let buy = await swapTokens(
        ethAddress,
        selectedToken.address,
        fromAmount,
        tx_signer,
        slippage,
        chain
      );
      popupManager.removePopup(id);
      let successful_id = Date.now();

      popupManager.addPopup({
        id: successful_id,
        type: "success",
        message:
          "Successfully Swapped " + fromAmount + " ETH of " + selectedToken.address,
      });
      setTimeout(() => {
        popupManager.removePopup(successful_id);
      }, 6000);
    } catch (e) {
      //console.log(e)
      popupManager.removePopup(id);
      popupManager.addPopup({
        id: Date.now(),
        type: "error",
        message: "Error trying to Swap Token: " + e.toString(),
        duration: 6000,
      });
    }finally{
      setLoadingTrade(false)

    }
  };
  const SwapSell = async () => {
    
    if (!isConnected || !provider || !signer) {
      popupManager.addPopup({
        id: Date.now(),
        type: "error",
        message: "Please connect your wallet before buying tokens.",
        duration: 6000,
      });
      return;
    }
    let chainIDHex = await window.ethereum.request({ method: "eth_chainId" });
    const chainId = await parseInt(chainIDHex, 16);
    let chainIdURL = chain === "ethereum" ? 1 : chain === "base" ?  8453 : 1;

    if (chainId !== chainIdURL) {
      setIsConnected(false);
      popupManager.addPopup({
        id: Date.now(),
        type: "error",
        message: "Please Connect to ETH Network! ",
        duration: 6000,
      });
      return;
    }
    let id = Date.now();

    try {
      setLoadingTrade(true)

      let tx_signer = await provider.getSigner();
      popupManager.addPopup({
        id: id,
        type: "message",
        message: "Swapping " + toAmount + " of " + selectedToken.address,
      });
      let sell = await swapTokens(
        selectedToken.address,
        ethAddress,

        fromAmount,
        tx_signer,
        parseFloat(slippage),
        chain
      );
      popupManager.removePopup(id);
      let successful_id = Date.now();

      popupManager.addPopup({
        id: successful_id,
        type: "success",
        message:
          "Successfully Swapped " + toAmount + " of " + selectedToken.address,
      });
      setTimeout(() => {
        popupManager.removePopup(successful_id);
      }, 6000);
    } catch (e) {
      //console.log(e)
      popupManager.removePopup(id);
      popupManager.addPopup({
        id: Date.now(),
        type: "error",
        message: "Error trying to Swap Token: " + e.toString().replace(/Uniswap/gi, "Ethervista"),
        duration: 6000,
      });
    } finally{
      setLoadingTrade(false)
    }
  };

  const SwapSend = async () => {
    if (tradeDirection==="buying"){
        SwapBuy()
    }else if(tradeDirection==="selling"){
        SwapSell()
    }
  }
  return (
    <div className="rounded-xl p-2 py-4 w-full max-w-[512px] mx-auto mb-60 mt-[60px]">
      <h1 className='kanit-light text-[35px] font-[500] mt-12 mb-12 flex justify-center'>
        
        ETHERVISTA
        <div className="relative group ">
          <svg
            className="w-4 h-4 text-gray-400 cursor-pointer"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path d="M18 10c0 4.418-3.582 8-8 8s-8-3.582-8-8 3.582-8 8-8 8 3.582 8 8zM9 7v2h2V7H9zm0 4v4h2v-4H9z" />
          </svg>
          <div
            className="absolute top-full left-1/2 transform -translate-x-1/2 mt-2 w-64 p-2 bg-[#0e1018] border border-white/15 text-white text-xs rounded-lg 
            hidden group-hover:block transition-opacity duration-200 font-[300] text-white/90"
          >
            Ethervista operates as an automated market maker (AMM), facilitating decentralized token swaps using liquidity pools provided by users. When executing a swap, the price is dynamically adjusted based on the pool's token ratio, which can lead to slippage. Slippage represents the difference between the expected price and the actual price received during a trade—higher slippage tolerance means accepting a less favorable rate to complete the swap. On Ethervista, every swap incurs an ETH fee that is distributed directly to projects as revenue and to liquidity providers as rewards, ensuring sustainable incentives for all participants.
          </div>
        </div>
        </h1>
      <div className='flex justify-between w-full'>
        <button onClick={openSlippagePopup} className='justify-center items-center flex rounded-full bg-white/5 hover:bg-white/10 w-10 h-8 mb-3'>
          <img src='/settings.svg' alt="settings" className="w-4 h-4 text-white" />
        </button>
        <button onClick={refreshQuotes} className='justify-center items-center flex rounded-full bg-white/5 hover:bg-white/10 w-10 h-8 mb-3'>
          <img src='/refresh2.svg' alt="settings" className="w-3 h-3 text-white" />
        </button>
      </div>
      {/* From Amount */}
      <div className="bg-white/5 border border-transparent transition-color hover:border-yellow-300/50 hover:shadow-[0_0_15px_5px_rgba(253,224,71,0.2)] rounded-xl p-8 mb-3 py-6 transition-shadow duration-300">
        <div className="flex justify-between items-center ">
          <span className="text-white font-[600] text-sm">
            {tradeDirection === 'buying' ? 'From' : 'From'}
          </span>
          <span className="text-white font-[600] text-sm">Balance: {tradeDirection === "buying"? Math.round(ethBalance*1000)/1000 : tokenBalance ? tokenBalance : 0 }</span>
        </div>
        <div className="flex items-center mt-4">
        {loadingFromQuote === true ?
          <div className="flex-1 bg-transparent text-start text-white text-2xl focus:outline-none">
            <div className='bg-white/10 animate-pulse h-6 w-56'></div>
          </div>
          :
          <input
            type="number"
            value={fromAmount}
            onChange={(e) => {
              if (tradeDirection === 'buying') {
                handleETHChange(e.target.value,false,null)
              } else {
                handleTokenChange(e.target.value);
              }
            }}
            className="flex-1 font-[600] bg-transparent w-24 sm:w-full text-white/80 text-[22px] focus:outline-none"
            placeholder="0.0"
          />}
          {/* If fromToken is ETH, show ETH icon, else show token icon */}
          <button
            onClick={() => {
              // Only open popup if tradeDirection is 'selling' and we need to select the token to sell.
              if (tradeDirection === 'selling') {
                setSelectingToken('from');
                setIsPopupOpen(true);
              }
            }}
            className="flex items-center text-white font-[600] text-sm border-white/20   px-4 py-2 rounded-full hover:bg-yellow-300/10 transition-colors hover:shadow-[0_0_15px_5px_rgba(253,224,71,0.1)] duration-300 border-[1px] hover:border-yellow-300/50 "
          >
            {!(tradeDirection ==="selling" && selectedToken.symbol==="Select") &&<img src={tradeDirection === 'buying' ? '/eth.svg' : selectedToken.logo } alt="" className="w-5 h-5 mr-2 rounded-full" />}
            {fromToken}
            {tradeDirection === 'selling' && (
              <svg className="w-4 h-4 ml-1" fill="currentColor" viewBox="0 0 20 20">
                <path
                  fillRule="evenodd"
                  d="M10 14l-5-5h10l-5 5z"
                  clipRule="evenodd"
                />
              </svg>
            )}
          </button>
        </div>
      </div>

      {/* Swap Button */}
      <div className="flex justify-center mb-3">
        <button
          onClick={changeDirection}
          className="p-2 bg-white/5 hover:bg-white/10 transition-colors hover:shadow-[0_0_15px_5px_rgba(253,224,71,0.1)] duration-300 border-transparent border-[1px] hover:border-yellow-300/50 rounded-full text-white/50 focus:outline-none"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 transform"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            {/* Arrows icon */}
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M16 17l-4 4m0 0l-4-4m4 4V3"
            />
          </svg>
        </button>
      </div>

      {/* To Amount */}
      <div className="bg-white/5 border border-transparent transition-color hover:border-yellow-300/50 hover:shadow-[0_0_15px_5px_rgba(253,224,71,0.2)] rounded-xl p-8 mb-4 py-6 transition-shadow duration-300">
        <div className="flex justify-between items-center">
          <span className="text-white font-[600] text-sm">
            {tradeDirection === 'buying' ? 'Buying (Token)' : 'Buying (ETH)'}
          </span>
          <span className="text-white font-[600] text-sm">Balance: {tradeDirection === "selling"? Math.round(ethBalance*1000)/1000 : tokenBalance ? tokenBalance : 0 }</span>
        </div>
        <div className="mt-4 flex items-center">
          {loadingToQuote === true ?
          <div className="flex-1 bg-transparent text-start text-white text-2xl focus:outline-none">
            <div className='bg-white/10 animate-pulse h-6 w-56'></div>
          </div>
          :
          <input
            type="number"
            value={toAmount}
            onChange={(e) => {
              if (tradeDirection === 'buying') {
                handleTokenChange(e.target.value);
              } else {
                handleETHChange(e.target.value,false,null);
              }
            }}
            className="flex-1 font-[600] bg-transparent  w-24 sm:w-full text-white/80 text-[22px] focus:outline-none"
            placeholder="0.0"
          />          
          }

          {/* If toToken is ETH, show ETH icon, else show token icon */}
          <button
            onClick={() => {
              // Only open popup if tradeDirection is 'buying' since we are selecting which token to buy.
              if (tradeDirection === 'buying') {
                setSelectingToken('to');
                setIsPopupOpen(true);
              }
            }}
            className="flex items-center border-white/20 text-white font-[600] text-sm px-4 py-2 rounded-full hover:bg-yellow-300/10 transition-colors hover:shadow-[0_0_15px_5px_rgba(253,224,71,0.1)] duration-300 border-[1px] hover:border-yellow-300/50 "
          >
            {!(tradeDirection ==="buying" && selectedToken.symbol==="Select") &&<img src={tradeDirection === 'selling' ? '/eth.svg' : selectedToken.logo } alt="" className="w-5 h-5 mr-2 rounded-full" />}
            {toToken}
            {tradeDirection === 'buying' && (
              <svg className="w-4 h-4 ml-1" fill="currentColor" viewBox="0 0 20 20">
                <path
                  fillRule="evenodd"
                  d="M10 14l-5-5h10l-5 5z"
                  clipRule="evenodd"
                />
              </svg>
            )}
          </button>
        </div>
      </div>

      {/* Swap Action Button */}
      {isConnected===true ? 
      <button
        onClick={SwapSend}
        className="w-full mt-4 text-[18px] font-[600] text-yellow-300 bg-yellow-300/20 border border-transparent hover:border-yellow-300/50 transition border-color duration-300 py-3 px-4 rounded-xl focus:outline-none"
      >
        Swap
      </button>   :
      <button
        onClick={initializeProvider}
        className="w-full mt-4 text-[18px] font-[600] text-yellow-300 bg-yellow-300/20 border border-transparent hover:border-yellow-300/50 transition border-color duration-300 py-3 px-4 rounded-xl focus:outline-none"
      >
        Connect
      </button>  
      }


      {/* SwapperPopup */}
      {isPopupOpen &&
      <SwapperPopup
        tokens={featuredTokens}
        onSelect={handleSelectToken}
        onClose={() => setIsPopupOpen(false)}
        isOpen={isPopupOpen}
        chain={chain}
      />}
      {isSlippagePopupOpen && (
        <div className="fixed inset-0 z-[9998] flex items-center justify-center">
          {/* Overlay */}
          <div
            className="absolute inset-0 bg-black/70"
            onClick={closeSlippagePopup}
          ></div>

          {/* Popup Content */}
          <div className="relative bg-[#0e1018] rounded-xl p-6 z-10 w-full max-w-sm shadow-lg">
            <button
              onClick={closeSlippagePopup}
              className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 font-bold"
            >
              ×
            </button>

            <h2 className="text-xl mb-4 kanit-light font-[400] text-white">Slippage</h2>
            <div className="flex items-center space-x-3">
              <input
                type="number"
                value={tempSlippage}
                onChange={(e)=>{handleTempSlippageChange(e.target.value)}}
                min="0"
                max="100"
                step="0.01"
                className="w-full p-2 bg-transparent text-white kanit-light border border-white/20  transition-color duration-300 hover:border-yellow-300/50 hover:shadow-[0_0_15px_5px_rgba(253,224,71,0.2)] rounded-md focus:outline-none"
                placeholder="Enter slippage (0.01 - 100)"
              />
              <span className="text-sm text-white font-semibold">%</span>
            </div>
            <div className="flex justify-end mt-4">
              <button
                onClick={handleSubmitSlippage}
                className="px-4 py-2 w-full text-white bg-white/5 hover:bg-white/10 border border-white/10 rounded-md font-semibold transition duration-300"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      )}


    </div>
  );
}
