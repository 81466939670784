import React, { useState, useEffect } from 'react';

export default function SwapperPopup({ onSelect, onClose, isOpen , chain}) {
  const [searchQuery, setSearchQuery] = useState('');
  const [fetchedTokens, setFetchedTokens] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!isOpen) return;

    const fetchTokens = async () => {
      setIsLoading(true);
      try {
        let apiUrl = '';

        if (searchQuery.trim() === '') {
          apiUrl = `https://etherfun.pro/vistadex/tokens?page=1&sort=fdv:DESC&chain=${chain}`;
        } else {
          apiUrl = `https://etherfun.pro/vistadex/search?query=${encodeURIComponent(
            searchQuery
          )}&chain=${chain}`;
        }

        const response = await fetch(apiUrl);

        if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);

        const data = await response.json();
        setFetchedTokens(Array.isArray(data.data) ? data.data : []);
      } catch (error) {
        console.error('Error fetching tokens:', error);
        setFetchedTokens([]);
      } finally {
        setIsLoading(false);
      }
    };

    fetchTokens();
  }, [searchQuery, isOpen]);

  const handleSelect = (token) => {
    onSelect(token);
    onClose();
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-[9948]">
      {/* Overlay */}
      <div className="absolute inset-0 bg-black opacity-50" onClick={onClose}></div>

      {/* Modal Content */}
      <div className="relative bg-[#0e1018] border border-white/5 rounded-lg z-10 w-[500px] h-[80vh] overflow-y-auto no-scrollbar">
        {/* Header with Search */}
        <div className="sticky top-0 bg-[#0e1018] z-50 pr-6 px-4 border-b border-white/10 pb-2 pt-4">
          <input
            type="text"
            className="w-full p-2 rounded-md bg-transparent text-sm text-gray-300 focus:outline-none placeholder-gray-500"
            placeholder="Search tokens by name or address"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
                  {/* Close Button */}
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-400 hover:text-gray-200 font-bold text-xl z-[500]"
        >
          &times;
        </button>
        </div>

        {/* Content */}
        {isLoading ? (
          <div className="p-4 space-y-4">
            {/* Skeleton Loading */}
            {[...Array(25)].map((_, i) => (
              <div key={i} className="animate-pulse flex items-center space-x-4">
                <div className="bg-gray-800 rounded-full h-12 w-12"></div>
                <div className="flex-1 space-y-2">
                  <div className="h-4 bg-gray-800 rounded w-3/4"></div>
                  <div className="h-4 bg-gray-800 rounded w-1/2"></div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <ul className="overflow-y-auto no-scrollbar">
            {fetchedTokens.length > 0 ? (
              fetchedTokens.map((token) => (
                <li key={token.symbol}>
                  <button
                    onClick={() => handleSelect(token)}
                    className="w-full text-left py-3 px-4 hover:bg-white/5 text-white flex items-center space-x-2"
                  >
                    {token.logo && (
                      <img
                        src={token.logo}
                        alt={token.symbol}
                        className="w-8 h-8 rounded-full mr-4"
                      />
                    )}
                    <div className="flex flex-col">
                      <span className="font-medium text-gray-300">{token.name}</span>
                      <span className="text-sm font-medium text-gray-500">{token.symbol}</span>
                      <span className="text-xs font-[600] text-gray-500">{token.address.slice(0,7)+"..."+token.address.slice(-8)}</span>
                    </div>
                  </button>
                </li>
              ))
            ) : (
              <li className="py-4 px-4 text-gray-400 text-center">No matching tokens found</li>
            )}
          </ul>
        )}


      </div>
    </div>
  );
}
