import React from 'react'

export default function TradesHistory({swapHistory,ETHPrice}) {
  return (
    <div className='h-[400px] overflow-y-scroll no-scrollbar' >{swapHistory.map((swap, index) => (
        <div key={index} className={`grid grid-cols-4 sm:grid-cols-5 md:grid-cols-6 lg:grid-cols-7 xl:grid-cols-8 gap-8 w-full items-center border-t kanit-regular font-[300] text-[12px] sm:text-[14px] hover:bg-[#ffffff03] text-white/60 border-white/5`}>
          <div className={`flex-1 items-center border-white/10 text-center py-1 flex items-center ${swap.txType === 'Buy' ? 'text-[#2dba83]':'text-[#f3455c]'}`}>
            <p className='font-[400] pl-3'>{swap.txType === 'Buy' ? 'Buy' : 'Sell'}</p> 
          </div>
          <div className={`${swap.txType === 'Buy' ? 'text-[#2dba83]':'text-[#f3455c]'} flex items-start flex-1 text-start  border-white/10  py-1 xl:pl-3`}>
          <p className='font-[400] pl-1'>{String(swap.tokensAmount)}</p> 
          </div>
          <div
            className={`${swap.txType === 'Buy' ? 'text-[#2dba83]' : 'text-[#f3455c]'} flex items-center flex-1 text-start border-white/10 py-1  pl-2 relative`}
            >
            {/* Progress Bar */}
            <div
                className={`absolute top-0 left-0 h-full py-2 ${swap.txType === 'Buy' ? 'bg-[#2dba8317]' : 'bg-[#f3455c17]'}`}
                style={{
                width: `${Math.min((swap.ethAmount / 6) * 100, 100)}%`,
                transition: 'width 0.3s ease-in-out',
                opacity: swap.ethAmount > 0 ? 1 : 0, // Hide progress bar if ethAmount is 0
                }}
            ></div>

            {/* Content */}
            <p className="font-[400] pl-1 relative z-10">{String(swap.ethAmount)}</p>
            </div>
          <div className={`${swap.txType === 'Buy' ? 'text-[#2dba83]':'text-[#f3455c]'} hidden lg:flex items-center flex-1 text-center  border-white/10  py-1 pl-3`}>
            <p className='font-[400] pl-1'>${String(Math.round(swap.tokensAmount*((ETHPrice*swap.ethAmount)/swap.tokensAmount)*100)/100)}</p> 
          </div>

          <div className={` flex-1 text-start hidden sm:block border-white/10  py-1 pl-2`}>
          <p className={`${swap.txType === 'Buy' ? 'text-[#2dba83]' : 'text-[#f3455c]'} font-[400] `}>{swap.from.slice(0,5)}</p> 
          </div>
          <div className={`${swap.txType === 'Buy' ? 'text-[#2dba83]' : 'text-[#f3455c]'} hidden xl:block text-start kanit-regular font-[400] border-white/10  py-1 pl-2`}>
            <p className='font-[400] '>${Math.round((ETHPrice*swap.ethAmount)/swap.tokensAmount*100000)/100000}</p> 
          </div>

          <div className={`${swap.txType === 'Buy' ? 'text-[#2dba83]' : 'text-[#f3455c]'} flex-1 text-start   border-white/10  py-[8px] pl-2`}>
          <p className='font-[400] '>{swap.timestamp}</p> 
          </div>
          <div className={`  hidden md:flex justify-center border-white/10   py-1 px-2`}>
          <a href={`https://etherscan.io/tx/${swap.transactionHash}`} target='blank' className={` font-[400] pl-5 flex items-center opacity-50 hover:opacity-100`}><img src='/link.svg' className='w-4' /></a> 
          </div>          
        </div>
      ))}</div>
  )
}
