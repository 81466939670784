import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import ethLogo from '../assets/eth.svg';
import baseLogo from '../assets/base.png';

const chains = [
  { name: 'ethereum', image: ethLogo },
  { name: 'base', image: baseLogo },
];

function getChainFromUrl() {
  const path = window.location.pathname; // e.g., "/ethereum", "/base", "/ethereum/swap", etc.
  const segments = path.split("/").filter(Boolean); // splits into ["ethereum", "swap"] or ["base"]
  
  const firstSegment = segments[0];
  if (firstSegment === "ethereum" || firstSegment === "base") {
    return firstSegment;
  }
  
  return null; // default can be handled later if needed
}

function ChainDropdown() {
  const navigate = useNavigate();
  const chain = getChainFromUrl(); // "ethereum" or "base"
  const selectedChainIndex = chain === 'ethereum' ? 0 : 1; // 0 for ETH, 1 for Base

  const handleSelect = (newChain) => {
    if (newChain !== chain) {
      navigate(`/${newChain}`);
    }
  };

  return (
    <div className="relative inline-flex items-center text-[9px] justify-center bg-white/10 rounded-xl overflow-hidden text-[#ffffff]   ">
      {/* Selector Background: moves behind the chosen chain */}
      <div
        className="absolute top-0 left-0 w-1/2 h-full bg-white/15 transition-transform duration-300 ease-in-out"
        style={{ transform: `translateX(${selectedChainIndex * 100}%)` }}
      />
      
      {chains.map((c) => (
        <button
          key={c.name}
          className="relative z-10 w-[74px] px-3 py-[4px] lg:py-[6px] flex gap-2 items-center justify-center text-[9px] lg:text-[12px] font-[500] noto-sans-display " 
          style={{
            //color: c.name === chain ? '#fff' : '#fff', 
           // fontWeight: c.name === chain ? '600' : '400'
          }}
          onClick={() => handleSelect(c.name)}
        >
          <p>{c.name === 'ethereum' ? 'ETH' : 'BASE'}</p>
          <img src={c.image} alt={c.name} className="h-3 w-3 lg:h-4 lg:w-4" />
        </button>
      ))}
    </div>
  );
}

// Example usage in a component
function HeaderComponent() {
  return (
    <li className="justify-end gap-4 items-center flex">
      <ChainDropdown />
    </li>
  );
}

export default HeaderComponent;
