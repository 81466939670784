class PopupManager {
  constructor() {
    this.listeners = [];
  }

  addPopup(popup) {
    this.listeners.forEach((listener) => listener(popup));
  }
  removePopup(id) {
    this.listeners.forEach((listener) => listener({ id, remove: true }));
  }
  clearPopups() {
    this.listeners.forEach((listener) => listener({ clear: true }));
  }
  subscribe(listener) {
    this.listeners.push(listener);
    return () => {
      this.listeners = this.listeners.filter((l) => l !== listener);
    };
  }
}

export const popupManager = new PopupManager();
