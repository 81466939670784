import React, { useState } from 'react';
import { quote,deployLiquidity,quoteMintLiquidity,quoteRemoveLiquidity,removeLiquidity } from '../../functions/dexfunctions'
import { popupManager } from '../common/PopupManager';
import { useParams } from "react-router-dom";

export default function RemoveLiquidity({reserve,tokenAddress,signer,dataToken,chain}) {
  const [ethAmount, setEthAmount] = useState('');
  const [tokenAmount, setTokenAmount] = useState([]);
  const [loadingQuote,setloadingQuote] = useState(false)
  const poolRatio = 100; // Example: 1 ETH = 100 Tokens (replace with actual pool ratio)
const ethAddress = (chain === "base" ? "0x4200000000000000000000000000000000000006" : chain === 'ethereum' ? "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2" :"")
    const [LPtokensOut,setLPtokensOut] = useState(0)
  const handleEthChange = (e) => {
    setloadingQuote(true)
    const value = e.target.value;
    setEthAmount(value)
    getLPout(value)


  };
  const getLPout = async (value) => {
    const LPout = await quoteRemoveLiquidity(ethAddress,tokenAddress,value,signer,chain)
    setTokenAmount(LPout)
    setloadingQuote(false)
  }

  const removeLiquidityAttempt = async () => {
    let id = Date.now();
    try{
      if (!signer) {
        popupManager.addPopup({
          id: Date.now(),
          type: "error",
          message: "Please connect your wallet before buying tokens.",
          duration: 6000,
        });
        return;
      }
      let chainIDHex = await window.ethereum.request({ method: "eth_chainId" });
      const chainId = await parseInt(chainIDHex, 16);
      let chainIdURL = chain === "ethereum" ? 1 : chain === "base" ?  8453 : 1;
      if (chainId !== chainIdURL) {
        popupManager.addPopup({
          id: Date.now(),
          type: "error",
          message: "Please Connect to Right Network! ",
          duration: 6000,
        });
        return;
      }
      popupManager.addPopup({
        id: id,
        type: "message",
        message: `Removing ${ethAmount} of ETH and ${tokenAmount[1]} of Tokens from Liquidity`,
      });
     const resp = await removeLiquidity(ethAddress,tokenAddress,ethAmount,0,0,signer,ethAddress,chain)

     popupManager.removePopup(id);
     let successful_id = Date.now();

     popupManager.addPopup({
       id: successful_id,
       type: "success",
       message:
         "Transaction sent!",
     });
    }catch(e){
        console.log(e)
        popupManager.removePopup(id);
      popupManager.addPopup({
        id: Date.now(),
        type: "error",
        message: e.toString(),
        duration: 6000,
      });
    }
  }

  return (
    <div className="  rounded-lg shadow-md w-full mx-auto">
      <form>
        <div className="mb-4 w-full">
        <label className="block text-start text-[13px] text-gray-300 font-[500] kanit-light mb-2" htmlFor="ethAmount">
            LP Tokens Amount
          </label>
          <input
            type="number"
            id="ethAmount"
            value={ethAmount}
            onChange={handleEthChange}
              min="0"
            placeholder="LP Token Amount"
            className="w-full p-2 py-4 text-[13px] border border-white/15 text-gray-300 rounded-md bg-transparent focus:outline-none "
          />
        </div>
        <div>
            {tokenAmount[0] !== 0 && 
                <div className=' border-t border-white/10 pt-3 mt-4 noto-sans-display text-[#7b7f8f] text-[13px] font-[400]'>
                  {loadingQuote === true ? 
                           <div className='h-5 animate-pulse bg-white/5 '></div>
                   :
                   <div className='flex gap-1 justify-start border-b border-black/40  '> 
                   <p>You send </p><p>{ethAmount ? ethAmount : 0} LP Tokens</p>
                   </div> 
                   }
                  {loadingQuote === true ? 
                    <div className='h-5 animate-pulse bg-white/5'></div>
                  :
                  <div className='flex gap-1 justify-start '> 
                    <p>You will receive </p><p>{tokenAmount[1] ? Math.round(tokenAmount[1]*1000)/1000 : 0} ETH</p>
                  </div> 
                  }
                  {loadingQuote === true ? <div className='h-5 animate-pulse bg-white/5 '></div>
                    :
                    <div className='flex gap-1 justify-start'>
                      <p>You will receive</p> <p>{tokenAmount[2] ? Math.round(tokenAmount[2]*1000)/1000 : 0} {dataToken.symbol}</p>
                    </div> 
                  } 
                </div>
            }
        </div>

        {ethAmount <= LPtokensOut ? 
        <button
            type="button"
            className="w-full text-white/50 bg-white/5 mb-8  border  border-white/10 py-2 rounded-md font-semibold  transition duration-300 mt-8 "
            >
            Not enough LP Tokens
        </button>:
        <button
            onClick={removeLiquidityAttempt}
              type="button"
              className="w-full text-white bg-white/5 mb-8 hover:bg-white/10 border  border-white/10 py-2 rounded-md font-semibold  transition duration-300 mt-8"
            >
              Remove 
        </button>
        }

      </form>
    </div>
  );
}
