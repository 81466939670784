import React, { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import { lpvistastaker ,lpvistalock,lpvistaunlock,claimRewardsLOCK,getStatsLOCK,getPairAdress,lock} from '../../functions/dexfunctions'
import { popupManager } from '../common/PopupManager';
import { useParams } from "react-router-dom";
import { getPairAddressForBondedTokenForGraph } from '../../functions/factoryFunctions';

export default function Hardlock() {
  const [activeTab, setActiveTab] = useState('vista');
  const [signer, setSigner] = useState(null);
  const [provider, setProvider] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  // State variables
  const [selectedToken, setSelectedToken] = useState(null);
  const [pairAddress, setPairAddress] = useState('');
  const [tokenAmount, setTokenAmount] = useState('');
  const [lockedAmountVista, setLockedAmountVista] = useState(0);
  const [timeLeftVista, setTimeLeftVista] = useState('');
  const [rewardsStatsVista, setrewardsStatsVista] = useState([]); // [currentRewards, allTimeRewards, currentPool]
  const [hasRewards, setHasRewards] = useState(false);
  const [stakedVistaLP, setstakedVistaLP] = useState(null); 
  const [stakedVistaLPTime, setstakedVistaLPTime] = useState(null); 
  
  //HARDLOCK OTHER TOKENS
  const [rewardsStats, setrewardsStats] = useState([]); // [currentRewards, allTimeRewards, currentPool]
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [featuredTokens, setfeaturedTokens] = useState([]);
  const [tokenLockInfo, settokenLockInfo] = useState([]);
  const [othertokenAmount, setothertokenAmount] = useState('');
  const {chain,tokenAddress } = useParams();
  const ethAddress = (chain === "base" ? "0x4200000000000000000000000000000000000006" : chain === 'ethereum' ? "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2" :"")

  const handleAddressInput = async () => {
    if (pairAddress) {

      setSelectedToken({ name:'Pair Address: '+ pairAddress.slice(0,6)+"..."+ pairAddress.slice(-6) });
      setIsPopupOpen(false);
      fetchRewardsForPair(pairAddress);
    }
  };
  const fetchTokens = async () => {
    if(chain === "base"){
      setActiveTab("hardlock")
    }
    const response = await fetch('https://etherfun.pro/vistadex/tokens?page=1&sort=fdv:DESC&chain='+chain);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    //console.log(data.data)
    if(chain==="ethereum"){
    setfeaturedTokens(data.data.slice(1,19))
    }else{
      setfeaturedTokens(data.data.slice(0,19))
    }

  }
const handleTokenSelect = async (token) => {
  setSelectedToken(token);
  let paircalc = await getPairAddressForBondedTokenForGraph(token.address,ethAddress,chain)
  setPairAddress(paircalc);
  setIsPopupOpen(false);
  // Fetch rewards data for the selected token
  fetchRewardsForPair(paircalc);
};
useEffect(() => {
    fetchTokens()
}, []);
  const initializeProvider = async () => {
    try {
      let provider;
      let signerInstance;
      let chainIDHex = await window.ethereum.request({ method: "eth_chainId" });
      const chainId = await parseInt(chainIDHex, 16);
      //console.log(chainId);
      let chainIdURL = chain === "ethereum" ? 1 : chain === "base" ?  8453 : 1;

      if (chainId !== chainIdURL) {
        setIsConnected(false);
        popupManager.addPopup({
          id: Date.now(),
          type: "error",
          message: "Please Connect to Right Network! ",
          duration: 6000,
        });
        return;
      }
      if (window.ethereum == null) {

        provider = ethers.getDefaultProvider();
      } else {
        provider = new ethers.BrowserProvider(window.ethereum);
        signerInstance = await provider.getSigner();
      await setProvider(provider);
      await setSigner(signerInstance);
      fetchVistaLock(signerInstance)
      fetchRewardsForVista(signerInstance)
      setIsConnected(true);
        
      }

    } catch (e) {
     //console.log(e)
    }
  };

  const fetchVistaLock = async (signerInstance) => {
    try{
      const vistaLockStats = await lpvistastaker(signerInstance)
      //console.log(vistaLockStats)
      settokenLockInfo(vistaLockStats)

    }catch(e){
      //console.log(e)
    }
  }

  const fetchRewardsForVista = async (signerInstance) => {
    try{
      const response = await getStatsLOCK('0xfdd05552F1377aA488AFed744c8024358AF02041',signerInstance,chain)
      console.log(response)
      setrewardsStatsVista(response)
    }catch(e){
      console.log(e)
    }
  }

  const fetchRewardsForPair = async (pairAddress) => {
    try{
      
      setPairAddress(pairAddress)
      const response = await getStatsLOCK(pairAddress,signer,chain)
      setrewardsStats(response)
    }catch(e){
      //console.log(e)
    }
  }

  const handleLockToken = async () => {

    let id = Date.now();
    try{
      let chainIDHex = await window.ethereum.request({ method: "eth_chainId" });
      const chainId = await parseInt(chainIDHex, 16);
      //console.log(chainId);
      let chainIdURL = chain === "ethereum" ? 1 : chain === "base" ?  8453 : 1;

      if (chainId !== chainIdURL) {
        setIsConnected(false);
        popupManager.addPopup({
          id: Date.now(),
          type: "error",
          message: "Please Connect to Right Network! ",
          duration: 6000,
        });
        return;
      }
      popupManager.addPopup({
        id: id,
        type: "message",
        message: "Hardlocking "+othertokenAmount +" of "+pairAddress,
      });
      const resp = await lock(pairAddress,othertokenAmount,signer,selectedToken.address,chain)
      popupManager.removePopup(id);
      let successful_id = Date.now();

      popupManager.addPopup({
        id: successful_id,
        type: "success",
        message:
          "Transaction Sent!",
      });
    }catch(e){
      console.log(e)
      popupManager.removePopup(id);
      popupManager.addPopup({
        id: Date.now(),
        type: "error",
        message: e.toString(),
        duration: 6000,
      });
      //console.log(e)
    }
  }


  useEffect(() => {
    const handleAccountsChanged = async (accounts) => {
      if (accounts.length === 0) {
        // User disconnected all accounts
        setSigner(null);
        setProvider(null);
      } else {
        // User switched accounts
        const newAccount = accounts[0];

        const provider = new ethers.BrowserProvider(window.ethereum);
        const signer = await provider.getSigner();
        setProvider(provider);
        setSigner(signer);

        // Detect wallet type again if necessary
        let detectedWallet = "Unknown Wallet";
        if (window.ethereum.isMetaMask) {
          detectedWallet = "MetaMask";
        } else if (window.ethereum.isCoinbaseWallet) {
          detectedWallet = "Coinbase Wallet";
        } else if (window.ethereum.isBraveWallet) {
          detectedWallet = "Brave Wallet";
        } else if (window.ethereum.isTrust) {
          detectedWallet = "Trust Wallet";
        }
      }
    };

    if (window.ethereum) {
      window.ethereum.on("accountsChanged", handleAccountsChanged);
    }

    initializeProvider();

    return () => {
      if (window.ethereum) {
        window.ethereum.removeListener("accountsChanged", handleAccountsChanged);
      }
    };
  }, []);


  const handleLockVista = async () => {
    // Implement staking logic here
    let id = Date.now();
    try{
      let chainIDHex = await window.ethereum.request({ method: "eth_chainId" });
      const chainId = await parseInt(chainIDHex, 16);
      //console.log(chainId);
      let chainIdURL = chain === "ethereum" ? 1 : chain === "base" ?  8453 : 1;

      if (chainId !== chainIdURL) {
        setIsConnected(false);
        popupManager.addPopup({
          id: Date.now(),
          type: "error",
          message: "Please Connect to Right Network! ",
          duration: 6000,
        });
        return;
      }
      popupManager.addPopup({
        id: id,
        type: "message",
        message: "Locking "+ tokenAmount + " VISTA-LP",
      });
      const response = await lpvistalock(tokenAmount,signer)
      
      popupManager.removePopup(id);
      let successful_id = Date.now();

      popupManager.addPopup({
        id: successful_id,
        type: "success",
        message:
          "Transaction Sent!",
      });
      setTokenAmount('');

    }catch(e){
      popupManager.removePopup(id);
      popupManager.addPopup({
        id: Date.now(),
        type: "error",
        message: e.toString(),
        duration: 6000,
      });
      //console.log(e)
    }
  };

  const handleUnlockVista = async() => {
    let id = Date.now();
    try {
      let chainIDHex = await window.ethereum.request({ method: "eth_chainId" });
      const chainId = await parseInt(chainIDHex, 16);
      let chainIdURL = chain === "ethereum" ? 1 : chain === "base" ?  8453 : 1;
      if (chainId !== chainIdURL) {
        setIsConnected(false);
        popupManager.addPopup({
          id: Date.now(),
          type: "error",
          message: "Please Connect to Right Network! ",
          duration: 6000,
        });
        return;
      }
      popupManager.addPopup({
        id: id,
        type: "message",
        message: "Unlocking Vista-LP for " + tokenAmount,
      });
      const resp = await lpvistaunlock(tokenAmount,signer)

      popupManager.removePopup(id);
      let successful_id = Date.now();

      popupManager.addPopup({
        id: successful_id,
        type: "success",
        message:
          "Transaction Sent!",
      });

      //console.log(resp)
    } catch (error) {
      popupManager.removePopup(id);
      popupManager.addPopup({
        id: Date.now(),
        type: "error",
        message: error.toString(),
        duration: 6000,
      });
      //console.log(error)
    }
  };

  const handleClaimRewards = async (pairAddress) => {
    let id = Date.now();
    try {
      let chainIDHex = await window.ethereum.request({ method: "eth_chainId" });
      const chainId = await parseInt(chainIDHex, 16);
      let chainIdURL = chain === "ethereum" ? 1 : chain === "base" ?  8453 : 1;

      if (chainId !== chainIdURL) {
        setIsConnected(false);
        popupManager.addPopup({
          id: Date.now(),
          type: "error",
          message: "Please Connect to Right Network! ",
          duration: 6000,
        });
        return;
      }
      popupManager.addPopup({
        id: id,
        type: "message",
        message: "Claiming Rewards for " + pairAddress,
      });
      const response = await claimRewardsLOCK(pairAddress,signer)
      
      popupManager.removePopup(id);
      let successful_id = Date.now();

      popupManager.addPopup({
        id: successful_id,
        type: "success",
        message:
          "Transaction Sent",
      });
      //console.log(response)
    } catch (e) {
      popupManager.removePopup(id);
      popupManager.addPopup({
        id: Date.now(),
        type: "error",
        message: e.toString(),
        duration: 6000,
      });
      //console.log(e)
    }
  };

  const handleClaimVistaRewards = async (pairAddress) => {
    let id = Date.now();
    try {
      let chainIDHex = await window.ethereum.request({ method: "eth_chainId" });
      const chainId = await parseInt(chainIDHex, 16);
      let chainIdURL = chain === "ethereum" ? 1 : chain === "base" ?  8453 : 1;

      if (chainId !== chainIdURL) {
        setIsConnected(false);
        popupManager.addPopup({
          id: Date.now(),
          type: "error",
          message: "Please Connect to Right Network! ",
          duration: 6000,
        });
        return;
      }
      popupManager.addPopup({
        id: id,
        type: "message",
        message: "Claiming Rewards for " + pairAddress,
      });
      const response = await claimRewardsLOCK(pairAddress,signer)
      
      popupManager.removePopup(id);
      let successful_id = Date.now();

      popupManager.addPopup({
        id: successful_id,
        type: "success",
        message:
          "Transaction Sent",
      });
      //console.log(response)
    } catch (e) {
      popupManager.removePopup(id);
      popupManager.addPopup({
        id: Date.now(),
        type: "error",
        message: e.toString(),
        duration: 6000,
      });
      //console.log(e)
    }
  };

  const renderContent = () => {
    switch (activeTab) {
      case 'vista':
        return (
          <div>
            

            {/* Input for Token Amount */}
            <div className="mb-4">
              <label htmlFor="tokenAmount" className="block text-white text-start mb-2">
                Enter Amount of VISTA LP tokens
              </label>
              <input
                type="number"
                id="tokenAmount"
                value={tokenAmount}
                onChange={(e) => setTokenAmount(e.target.value)}
                placeholder="Token Amount"
                className="w-full px-4 py-3 border-t border-b text-[15px]  border-white/15 text-gray-300 rounded-md bg-transparent focus:outline-none"
                min="0"
              />
            </div>

            {/* Labels */}
            <div className="mb-4 text-gray-300 space-y-2">
              <p className="flex justify-between">
                <p>Staked Amount:</p> <span>{tokenLockInfo.amount}</span>
              </p>
              <p className="flex justify-between">
                <p>Lock Time:</p> <span>14 days</span>
              </p>
              <p className="flex justify-between">
                <p>Time Left:</p> <span>{tokenLockInfo.timeLeftFormatted}</span>
              </p>

              {rewardsStatsVista && (
                <>
                  <p className="flex justify-between">
                    <p>Your Rewards:</p> <span>${rewardsStatsVista[0]}</span>
                  </p>
                  <p className="flex justify-between">
                    <p>All Time Rewards:</p> <span>${rewardsStatsVista[1]}</span>
                  </p>
                </>
              )}
            </div>


            {/* Warning */}
            <div className="mb-4 p-4 bg-white/10 border-l-4 border-white-500 text-white-700">
              <p>
              Please claim any rewards before unstaking, or the rewards will be lost.
              </p>
            </div>

            {/* Buttons */}
            <div className="mb-4 mt-6 flex">
  {/* Lock Button */}
  {isConnected === false ? 
   <button
   onClick={initializeProvider}
   className={` px-4 py-2 w-full mr-2 bg-white/5 border text-white hover:bg-white/10 border-white/10 rounded-md font-semibold transition duration-300`}
  >
   Connect
  </button>
  :
  <>
<button
    onClick={handleLockVista}
    disabled={!tokenAmount || tokenAmount <= 0}
    className={`${
      !tokenAmount || tokenAmount <= 0
        ? 'text-white/50'
        : 'text-white hover:bg-white/10'
    } px-4 py-2 w-full  bg-white/5 border border-white/10 rounded-md font-semibold transition duration-300`}
  >
    Lock
  </button>

  {/* Unlock Button */}
  <button
    onClick={handleUnlockVista}
    disabled={lockedAmountVista <= 0}
    className={`${
      lockedAmountVista <= 0 
        ? 'text-white/50'
        : 'text-white hover:bg-white/10'
    } px-4 py-2 w-full  bg-white/5 border border-white/10 mx-2 rounded-md font-semibold transition duration-300`}
  >
    Unlock
  </button>

  {/* Claim Rewards Button */}
  {rewardsStatsVista[0] > 0 && (
    <button
      onClick={() =>
        handleClaimRewards('0xfdd05552F1377aA488AFed744c8024358AF02041')
      }
      className={`text-white hover:bg-yellow-300/40 px-4 py-2 w-full bg-yellow-300/20 transition-color border border-yellow-300/50 shadow-[0_0_15px_5px_rgba(253,224,71,0.2)] duration-300 rounded-md font-semibold transition duration-300`}
    >
      Claim Rewards
    </button>
  )}  
  </>}
  


</div>

          </div>
        );
      case 'hardlock':
        return (
          <div>
                  {/* Input Field */}
      <div className="mb-4">
        <input
          type="text"
          placeholder="Select Token Pair" 
          onClick={() => setIsPopupOpen(true)}
          value={selectedToken ? "Pair Address: " + pairAddress.slice(0,5)+'...'+pairAddress.slice(-5) : ''}
          readOnly
          className="w-full p-2 border border-gray-600 rounded-md cursor-pointer bg-white/5 hover:bg-white/10 text-gray-400 placeholder-gray-400"
        />
      </div>
            {/* Popup */}
            {isPopupOpen && (
                    <>
                      {/* Overlay */}
                      <div
                        className="fixed inset-0 bg-black/70 bg-opacity-75 flex items-center justify-center z-[9994]"
                        onClick={() => setIsPopupOpen(false)}
                      ></div>

                      {/* Modal Content */}
                      <div className="fixed inset-0 flex items-center justify-center z-[9999]">
                        <div className="bg-[#0e1018] p-6 rounded-md shadow-md w-80 relative">
                          <h3 className="text-xl font-semibold mb-4 text-white">Select a Token Pair</h3>
                          <ul className="mb-4 h-64 overflow-y-auto no-scrollbar">
                            {featuredTokens.map((token) => (
                                <li key={token.address} className="mb-2">
                                <button
                                    onClick={() => handleTokenSelect(token)}
                                    className="w-full text-left p-2 flex justify-between bg-white/5 border border-white/10 hover:bg-gray-600 rounded-md text-white"
                                >
                                    {token.name}
                                    <img src={token.logo} className="w-auto h-6" alt="featured token" />
                                </button>
                                </li>
                            ))}
                            </ul>

                          <div className="flex mb-4">
                            <input
                              type="text"
                              placeholder="Paste token pair"
                              value={pairAddress}
                              onChange={(e) => setPairAddress(e.target.value)}
                              className="flex-1 p-2 border-t border-b text-[15px]  border-white/15 text-gray-300 rounded-md bg-transparent focus:outline-none"
                            />
                            <button
                              onClick={handleAddressInput}
                              className="p-2 bg-white/5 hover:bg-white/10 text-white rounded-r-md text-sm"
                            >
                              Select
                            </button>
                          </div>
                          <button
                            onClick={() => setIsPopupOpen(false)}
                            className="absolute top-2 right-2 text-gray-400 hover:text-gray-200"
                          >
                            ✕
                          </button>
                        </div>
                      </div>
                    </>
                  )}

            {/* Input for Token Amount */}
            <div className="mb-4">
              <label htmlFor="othertokenAmountHardlock" className="block text-gray-300 mt-8">
                Enter Amount of Tokens to Hardlock:
              </label>
              <input
                type="number"
                id="othertokenAmountHardlock"
                value={othertokenAmount}
                onChange={(e) => setothertokenAmount(e.target.value)}
                placeholder="Token Amount"
                className="w-full py-3 px-2 mt-2 border-t border-b text-[15px]  border-white/15 text-gray-300 rounded-md bg-transparent focus:outline-none"
                min="0"
              />
            </div>

            {/* Labels */}
            {selectedToken && <div className="mb-4 text-gray-300">
              <p className="flex justify-between mb-2">
                    <p>Your Rewards:</p> <span>${rewardsStats[0]}</span>
                  </p>
                  {/*<p className="flex justify-between mb-2">
                    <p>All Time Rewards:</p> <span>${rewardsStats[1]}</span>
              </p>*/}
              <p className="flex justify-between">
                <p>Current Pool:</p> <span>${rewardsStats[2]}</span>
              </p>
            </div>}

            {/* Warning */}
            <div className="mb-4 p-2 bg-white/10 border-l-4 border-white-500 text-white-700">
              <p>
                Warning: Once you hardlock your non-vista LP tokens, they cannot be unlocked.
              </p>
            </div>

            {/* Buttons */}
            <div className="mb-4  flex">
 {isConnected === true ? <>
 {/* Hardlock Button */}
 <button
    onClick={handleLockToken}
    disabled={othertokenAmount <= 0 || !pairAddress}
    className={`${
      othertokenAmount <= 0 || !selectedToken
        ? 'text-white/50 '
        : 'text-white hover:bg-white/10'
    } px-4 py-2 w-full mr-2 bg-white/5 border border-white/10 rounded-md font-semibold transition duration-300`}
  >
    Hardlock
  </button>

  {/* Claim Rewards Button */}
  <button
    onClick={() => handleClaimRewards(pairAddress)}
    disabled={rewardsStatsVista[0] <= 0}
    className={`${
      rewardsStatsVista[0] <= 0
        ? 'text-white/50 bg-gray-500/20 border border-gray-500/30'
        : 'text-white hover:bg-yellow-300/40 bg-yellow-300/20 border border-yellow-300/50 shadow-[0_0_15px_5px_rgba(253,224,71,0.2)]'
    } px-4 py-2 w-full rounded-md font-semibold transition duration-300`}
  >
    Claim Rewards
  </button>

 </>:
 <button
 onClick={initializeProvider}
 className={` px-4 py-2 w-full mr-2 bg-white/5 border text-white hover:bg-white/10 border-white/10 rounded-md font-semibold transition duration-300`}
>
 Connect
</button>
 }
  
  {/* Note: Unstake button is removed */}
</div>
          </div>
        );
      
      default:
        return null;
    }
  };

  return (
    <div className="w-full max-w-xl mx-auto mt-32 px-3 sm:px-0">
      <h1 className="text-[30px] flex font-[500] mb-12 kanit-light">
        HARDLOCK LP 
        <div className="relative group ml-2">
          <svg
            className="w-4 h-4 text-gray-400 cursor-pointer"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path d="M18 10c0 4.418-3.582 8-8 8s-8-3.582-8-8 3.582-8 8-8 8 3.582 8 8zM9 7v2h2V7H9zm0 4v4h2v-4H9z" />
          </svg>
          <div
            className="absolute top-full left-1/2 transform -translate-x-1/2 mt-2 w-64 p-2 bg-[#0e1018] border border-white/15 text-white text-xs rounded-lg 
           hidden group-hover:block transition-opacity duration-200 font-[300] text-white"
          >
            Securely lock your liquidity while continuing to earn rewards. Non VISTA LP locks are irreversible but they will continue to earn you rewards forever.
          </div>
        </div>
      </h1>
      {/* Tab Navigation */}
      <div className="flex ">
        {chain !== "base" && <button
          className={`flex-1 py-2 text-center ${
            activeTab === 'vista'
              ? 'border border-white/30 text-white font-bold bg-white/10'
              : 'text-gray-500 hover:text-white'
          } `}
          onClick={() => setActiveTab('vista')}
        >
          Vista
        </button>}
        <button
          className={`flex-1 py-2 text-center ${
            activeTab === 'hardlock'
              ? 'border border-white/30 text-white font-bold bg-white/10'
              : 'text-gray-500 hover:text-white'
          } `}
          onClick={() => setActiveTab('hardlock')}
        >
          Hardlock
        </button>

      </div>

      {/* Tab Content */}
      <div className="mt-4  py-4 rounded-lg shadow-md">
        {renderContent()}
      </div>
    </div>
  );
}
