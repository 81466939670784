import { type } from "@testing-library/user-event/dist/type";

const { ethers,Contract,parseUnits  } = require("ethers");
const ERC20 = require("./swapping/build/ERC20.json"); //DO NOT CHANGE Erc20 token not lp
const PAIR = require("./swapping/build/IUniswapV2Pair.json"); 
const provider = new ethers.JsonRpcProvider(
    "https://mad-ultimate-halibut.callstaticrpc.com/"
  );
const ROUTER = require("./swapping/build/UniswapV2Router02.json"); 
const HARDSTAKE = require("./swapping/build/hardstake.json");
const HARDLOCK = require("./swapping/build/hardlock.json");
const HARDLOCK_VISTA = require("./swapping/build/hardlock_vista.json")
const FACTORY = require("./swapping/build/IUniswapV2Factory.json");
const contractUni = new ethers.Contract("0x9a27cb5ae0B2cEe0bb71f9A85C0D60f3920757B4", FACTORY.abi, provider);
    /* global BigInt */



//BASE ADDRESSES
const baseprovider = new ethers.JsonRpcProvider(
  "https://base-mainnet.g.alchemy.com/v2/8ijxKDI5eYpsPiEAYGHwnOVVUEz8Mtsh"
); //We will use our own RPC
 
const BaseRouterSwap = new ethers.Contract("0x4Aab7db193bf3d0083e9db51735Ab3da0328C3d7",ROUTER.abi,baseprovider)
const BasecontractUni = new ethers.Contract("0x9C9Dfc8b5D8F1cb1b7f854108Db16BE1C21ea400", FACTORY.abi, baseprovider);


export async function getDecimals(token) {
    const decimals = await token
      .decimals()
      .then((result) => {
        return result;
      })
      .catch((error) => {
        return 18;
      });
    return decimals;
  }
  export async function getBalanceAndSymbol(
    address,
    weth_address,
    signer
  ) {
    const accountAddress = await signer.getAddress();
    const network = await signer.provider.getNetwork();
    const chainId = Number(network.chainId);
    let providerChain;
    if(chainId === 1){
      providerChain = provider
    }
    else if( chainId === 8453){
      providerChain = baseprovider
    }else{return}
   
    try {
      if (address === weth_address) {
        const balanceRaw = await providerChain.getBalance(accountAddress.toString());
        return ethers.formatEther(balanceRaw)
        
      } else {

        const token = new Contract(address, ERC20.abi, providerChain);
        const decimals = await getDecimals(token)
        const balanceRaw = await token.balanceOf(accountAddress.toString());
        return Number(balanceRaw / BigInt(10 ** Number(decimals)))
          
  
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  }
export async function fetchReserves(address1, address2, pair, signer) {
    try {
      // Get decimals for each coin
      const coin1 = new Contract(address1, ERC20.abi, signer);
      const coin2 = new Contract(address2, ERC20.abi, signer);
  
      const coin1Decimals = await getDecimals(coin1);
      const coin2Decimals = await getDecimals(coin2);
      // Get reserves
      const reservesRaw = await pair.getReserves();
  
      // Put the results in the right order
      const results = [
        (await pair.token0()) === address1 ? reservesRaw[0] : reservesRaw[1],
        (await pair.token1()) === address2 ? reservesRaw[1] : reservesRaw[0],
      ];
  
      // Scale each to the right decimal place
      ////console.log(results[0] / (BigInt(10) ** coin1Decimals))
      const formattedReserve0 = ethers.formatUnits(results[0], coin1Decimals);
      const formattedReserve1 = ethers.formatUnits(results[1], coin2Decimals);
      
      return [formattedReserve0, formattedReserve1];
    } catch (err) {
      //console.log("error!");
      //console.log(err);
      return [0, 0];
    }
  }

//Function to fetch liquidity for a pair 
export async function getReserves(
    address1,
    address2,
    signer,
    chain
  ) {
    let contractChain;
    if(chain === "ethereum"){
      contractChain = contractUni
    }else if(chain === "base"){
      contractChain = BasecontractUni
    }
    let accountAddress = await signer.getAddress();
    
    try {
      const pairAddress = await contractChain.getPair(address1, address2);
      const pair = new Contract(pairAddress, PAIR.abi, signer);
  
      if (pairAddress !== "0x0000000000000000000000000000000000000000") {
        const reservesRaw = await fetchReserves(address1, address2, pair, signer);
        const liquidityTokens_BN = await pair.balanceOf(accountAddress);
        const liquidityTokens = Number(
          ethers.formatEther(liquidityTokens_BN)
        );
        return [
          Number(reservesRaw[0]).toPrecision(6),
          Number(reservesRaw[1]).toPrecision(6),
          liquidityTokens,
        ];
      } else {
        return [0, 0, 0];
      }
    } catch (err) {
      //console.log("error!");
      //console.log(err);
      return [0, 0, 0];
    }
  }



  export const quote = (amount1, reserve1, reserve2) => {

    const amount2 = amount1 * (reserve2 / reserve1);
    return [amount2];
  };
  export const getAmountOutLP = () =>{

  }
  export async function quoteMintLiquidity(
    address1,
    address2,
    amountA,
    amountB,
    signer,
    chain
  ) {
try{
    let RouterChain;
    let ProviderChain;
    let contractChain;
    if(chain === 'ethereum'){
      ProviderChain = provider
      RouterChain = new ethers.Contract(
        "0xCEDd366065A146a039B92Db35756ecD7688FCC77",
        ROUTER.abi,
        provider
      );      
      contractChain = contractUni
    }
    else if(chain === 'base'){
      ProviderChain = baseprovider
      RouterChain = new ethers.Contract(
        "0x4Aab7db193bf3d0083e9db51735Ab3da0328C3d7",
        ROUTER.abi,
        baseprovider
      );  
      contractChain = BasecontractUni
    }
    const MINIMUM_LIQUIDITY = 1000;
    let _reserveA = 0;
    let _reserveB = 0;
    let totalSupply = 0;
    [_reserveA, _reserveB, totalSupply] = await contractChain
      .getPair(address1, address2)
      .then(async (pairAddress) => {
        if (pairAddress !== "0x0000000000000000000000000000000000000000") {
          const pair = new Contract(pairAddress, PAIR.abi, signer);
  
          const reservesRaw = await fetchReserves(
            address1,
            address2,
            pair,
            signer
          ); 
          const reserveA = reservesRaw[0];
          const reserveB = reservesRaw[1];
  
          const _totalSupply = await pair.totalSupply();
          const totalSupply = Number(ethers.formatEther(_totalSupply));
          return [reserveA, reserveB, totalSupply];
        } else {
          return [0, 0, 0];
        }
      });
  
    const token1 = new Contract(address1, ERC20.abi, signer);
    const token2 = new Contract(address2, ERC20.abi, signer);
  
    // Need to do all this decimals work to account for 0 decimal numbers
  
    const token1Decimals = await getDecimals(token1);
    const token2Decimals = await getDecimals(token2);
    const valueA = BigInt(amountA*10**18);
    const valueB = BigInt(amountB*10**18);
    
    const reserveA = BigInt(_reserveA*10**18);
    const reserveB = BigInt(_reserveB*10**18);
    
  
    if (totalSupply == 0) {
      return Math.sqrt(valueA * valueB - MINIMUM_LIQUIDITY) * 10 ** -18;
    }

    return Math.min(
      (Number(valueA) * totalSupply) / Number(reserveA),
      (Number(valueB) * totalSupply) / Number(reserveB)
    );
  }catch(e){
    console.log(e)
  }
  }  
  function minBigInt(a, b) {
    return a < b ? a : b;
  }

  
export async function quoteAddLiquidity(
  address1,
  address2,
  amountADesired,
  amountBDesired,
  signer
) {
  const pairAddress = await contractUni.getPair(address1, address2);
  const pair = new Contract(pairAddress, PAIR.abi, signer);

  const reservesRaw = await fetchReserves(address1, address2, pair, signer); // Returns the reserves already formated as ethers
  const reserveA = reservesRaw[0];
  const reserveB = reservesRaw[1];

  if (reserveA === 0 && reserveB === 0) {
    const amountOut = await quoteMintLiquidity(
      address1,
      address2,
      amountADesired,
      amountBDesired,
      contractUni,
      signer
    );
    return [amountADesired, amountBDesired, amountOut];
  } else {
    const amountBOptimal = quote(amountADesired, reserveA, reserveB);
    if (amountBOptimal <= amountBDesired) {
      const amountOut = await quoteMintLiquidity(
        address1,
        address2,
        amountADesired,
        amountBOptimal,
        contractUni,
        signer
      );
      return [amountADesired, amountBOptimal, amountOut];
    } else {
      const amountAOptimal = quote(amountBDesired, reserveB, reserveA);
      const amountOut = await quoteMintLiquidity(
        address1,
        address2,
        amountAOptimal,
        amountBDesired,
        contractUni,
        signer
      );
      return [amountAOptimal, amountBDesired, amountOut];
    }
  }
}
export async function deployLiquidity(
  address1,
  address2,
  amount1,
  amount2,
  amount1min,
  amount2min,
  signer,
  ethAdress,
  chain
) {
  const network = await signer.provider.getNetwork();
  const chainId = Number(network.chainId);
  let RouterChain;
  let ProviderChain;
  if(chain === 'ethereum'){
    ProviderChain = provider
    RouterChain = "0xCEDd366065A146a039B92Db35756ecD7688FCC77"
    if(chainId !== 1){
      throw Error("Please Switch to ETH chain")
      return;

    }
  }
  else if(chain === 'base'){
    ProviderChain = baseprovider
    RouterChain = "0x4Aab7db193bf3d0083e9db51735Ab3da0328C3d7"
    if(chainId !== 8453){
      throw Error("Please Switch to BASE chain")
      return;
    }
  }

  let account = await signer.getAddress();
  const token1 = new Contract(address1, ERC20.abi, signer);
  const token2 = new Contract(address2, ERC20.abi, signer);
  const RouterSwap = new ethers.Contract(RouterChain, ROUTER.abi,signer);

  const token1Decimals = await getDecimals(token1);
  const token2Decimals = await getDecimals(token2);

  const amountIn1 = ethers.parseUnits(amount1, token1Decimals);
  const amountIn2 = ethers.parseUnits(amount2, token2Decimals);

  const amount1Min = ethers.parseUnits(amount1min, token1Decimals);
  const amount2Min = ethers.parseUnits(amount2min, token2Decimals);

  const time = Math.floor(Date.now() / 1000) + 200000;
  const deadline = BigInt(time);

  if (address1 === ethAdress) {
    const allowance2 = await token2.allowance(account, RouterSwap.getAddress());

    if (allowance2 < amountIn2) {
      let tx = await token2.approve(RouterSwap.getAddress(), BigInt(amountIn2));
      await tx.wait();
    }

    await RouterSwap.addLiquidityETH(
      address2,
      BigInt(amountIn2),
      BigInt(amount2Min),
      BigInt(amount1Min),
      deadline,
      { value: BigInt(amountIn1) }
    );
  } else if (address2 === ethAdress) {
    const allowance1 = await token1.allowance(account, RouterSwap.getAddress());

    if (allowance1 < amountIn1) {
      let tx = await token1.approve(RouterSwap.address, BigInt(amountIn1));
      await tx.wait();
    }

    await RouterSwap.addLiquidityETH(
      address1,
      BigInt(amountIn1),
      BigInt(amount1Min),
      BigInt(amount2Min),
      deadline,
      { value: BigInt(amountIn2) }
    );
  }
}

export async function quoteRemoveLiquidity(
  address1,
  address2,
  liquidity,
  signer,
  chain
) {
  let contractChain;
  let ProviderChain;
  let RouterChain;
  if(chain === 'ethereum'){
    ProviderChain = provider
    RouterChain = new ethers.Contract(
      "0xCEDd366065A146a039B92Db35756ecD7688FCC77",
      ROUTER.abi,
      provider
    );      
    contractChain = contractUni
  }
  else if(chain === 'base'){
    ProviderChain = baseprovider
    RouterChain = new ethers.Contract(
      "0x4Aab7db193bf3d0083e9db51735Ab3da0328C3d7",
      ROUTER.abi,
      baseprovider
    );  
    contractChain = BasecontractUni
  }
  const pairAddress = await contractChain.getPair(address1, address2);
  const pair = new Contract(pairAddress, PAIR.abi, signer);

  const reservesRaw = await fetchReserves(address1, address2, pair, signer); // Returns the reserves already formated as ethers
  const reserveA = reservesRaw[0];
  const reserveB = reservesRaw[1];

  /*
  const feeOn =
    (await factory.feeTo()) !== 0x0000000000000000000000000000000000000000;
  */

  //const _kLast = await pair.kLast();
  //const kLast = Number(ethers.utils.formatEther(_kLast));

  const _totalSupply = await pair.totalSupply();
  let totalSupply = Number(ethers.formatEther(_totalSupply));

  /*
  if (feeOn && kLast > 0) {
    const feeLiquidity =
      (totalSupply * (Math.sqrt(reserveA * reserveB) - Math.sqrt(kLast))) /
      (5 * Math.sqrt(reserveA * reserveB) + Math.sqrt(kLast));
    totalSupply = totalSupply + feeLiquidity;
  }
*/

  const Aout = (reserveA * liquidity) / totalSupply;
  const Bout = (reserveB * liquidity) / totalSupply;

  return [liquidity, Aout, Bout];
}

export async function removeLiquidity(
  address1,
  address2,
  liquidity_tokens,
  amount1min,
  amount2min,
  signer,
  ethAddress,
  chain
) {
  let RouterChain;
  let ProviderChain;
  let contractChain;
  if(chain === 'ethereum'){
    ProviderChain = provider
    RouterChain = new ethers.Contract(
      "0xCEDd366065A146a039B92Db35756ecD7688FCC77",
      ROUTER.abi,
      signer
    );      
    contractChain = contractUni
  }
  else if(chain === 'base'){
    ProviderChain = baseprovider
    RouterChain = new ethers.Contract(
      "0x4Aab7db193bf3d0083e9db51735Ab3da0328C3d7",
      ROUTER.abi,
      signer
    );  
    contractChain = BasecontractUni

  }

  let account = await signer.getAddress();
  const token1 = new Contract(address1, ERC20.abi, signer);
  const token2 = new Contract(address2, ERC20.abi, signer);
  const token1Decimals = await getDecimals(token1);
  const token2Decimals = await getDecimals(token2);

  const Getliquidity = (liquidity_tokens) => {
    if (liquidity_tokens < 0.001) {
      return BigInt(liquidity_tokens * 10 ** 18);
    }
    return ethers.parseUnits(String(liquidity_tokens), 18);
  };

  const liquidity = Getliquidity(liquidity_tokens);


  const amount1Min = ethers.parseUnits(
    String(amount1min),
    token1Decimals
  );
  const amount2Min = ethers.parseUnits(
    String(amount2min),
    token2Decimals
  );

  const time = Math.floor(Date.now() / 1000) + 200000;
  const deadline =BigInt(time);

  const pairAddress = await contractChain.getPair(address1, address2);
  const pair = new Contract(pairAddress, PAIR.abi, signer);

  const allowance = await pair.allowance(account, RouterChain.getAddress());

  if (Number(allowance) < Number(liquidity)) {
    let tx = await pair.approve(RouterChain.getAddress(), BigInt(liquidity));
    await tx.wait();
  }

  if (address1 === ethAddress) {

    await RouterChain.removeLiquidityETHSupportingFeeOnTransferTokens(
      address2,
      BigInt(liquidity),
      BigInt(amount2Min),
      BigInt(amount1Min),
      deadline
    );
  } else if (address2 === ethAddress) {
    await RouterChain.removeLiquidityETHSupportingFeeOnTransferTokens(
      address1,
      BigInt(liquidity),
      BigInt(amount1Min),
      BigInt(amount2Min),
      deadline
    );
  }
}

//REWARDS SECTION
export async function getStats(
  address1,
  signer,
  chain
) {
  
  let RouterChain;
  let ProviderChain;
  let contractChain;
  if(chain === 'ethereum'){
    ProviderChain = provider
    RouterChain = new ethers.Contract(
      "0xCEDd366065A146a039B92Db35756ecD7688FCC77",
      ROUTER.abi,
      signer
    );      
    contractChain = contractUni
  }
  else if(chain === 'base'){
    ProviderChain = baseprovider
    RouterChain = new ethers.Contract(
      "0x4Aab7db193bf3d0083e9db51735Ab3da0328C3d7",
      ROUTER.abi,
      signer
    );  
    contractChain = BasecontractUni
  }
  try {

    const address2 = await RouterChain.WETH();
    const pairAddress = await contractChain.getPair(address1, address2);
    const pairContract = new Contract(pairAddress, PAIR.abi, signer);
    const eth_price = (Number(await RouterChain.usdcToEth(1)))/Number((10 ** 18));
    if (pairAddress !== "0x0000000000000000000000000000000000000000") {
      let usrShare = (await pairContract.viewShare()).toString();
      let total = (await pairContract.totalCollected()).toString();
      let pool = (await pairContract.poolBalance()).toString();

      let share = (
        (Number(ethers.formatEther(usrShare)) * 1) /
        eth_price
      ).toFixed(2);
      let totalCollected = (
        (Number(ethers.formatEther(total)) * 1) /
        eth_price
      ).toFixed(2);
      let poolBalance = (
        (Number(ethers.formatEther(pool)) * 1) /
        eth_price
      ).toFixed(2);
      //let lockTime = Number(ethers.utils.formatEther(await pairContract.lockTime()));

      return [
        share.toString(),
        totalCollected.toString(),
        poolBalance.toString(),
      ];
    } else {
      //console.log("no rewards yet");
      return [0, 0, 0];
    }
  } catch (err) {
    //console.log(err);
    return [0, 0, 0];
  }
}

export async function claimRewards(
  address1,
  signer,
  chain
) {
  let RouterChain;
    let ProviderChain;
    let contractChain;
    if(chain === 'ethereum'){
      ProviderChain = provider
      RouterChain = new ethers.Contract(
        "0xCEDd366065A146a039B92Db35756ecD7688FCC77",
        ROUTER.abi,
        signer
      );      
      contractChain = contractUni
    }
    else if(chain === 'base'){
      ProviderChain = baseprovider
      RouterChain = new ethers.Contract(
        "0x4Aab7db193bf3d0083e9db51735Ab3da0328C3d7",
        ROUTER.abi,
        signer
      );  
      contractChain = BasecontractUni
    }


  const address2 = await RouterChain.WETH();
  const pairAddress = await contractChain.getPair(address1, address2);
  const pairContract = new Contract(pairAddress, PAIR.abi, signer);
  if (pairAddress !== "0x0000000000000000000000000000000000000000") {
    await pairContract.claimShare();
  }
}

//STAKE VISTA FUNCTIONS

export async function stake (
  amount,
  signer
) {
  const routerContract = new ethers.Contract("0xCEDd366065A146a039B92Db35756ecD7688FCC77", ROUTER.abi,signer);

  const network = await signer.provider.getNetwork();
  const chainId = Number(network.chainId);
  if (chainId == 1) {
    let accountAddress = await signer.getAddress();
    const stakeAddress = "0xEE5a6F8a55B02689138c195031d09BAFDc7d278F";
    const VISTA = "0xC9bCa88B04581699fAb5aa276CCafF7Df957cbbf";
    const token = new Contract(VISTA, ERC20.abi, signer);
    const allowance1 = await token.allowance(
      accountAddress,
      routerContract.getAddress()
    );

    if (Number(allowance1) < amount*(10**18)) {
      let tx = await token.approve(
        routerContract.getAddress(),
        BigInt(amount*(10**18))
      );
      await tx.wait();
    }

    await routerContract.hardstake(stakeAddress, VISTA, BigInt(amount*(10**18)));
  } else {
    //console.log("Can only stake VISTA in the ETH network")
  }
}

export async function unstake (
  amount,
  signer,
) {
  const network = await signer.provider.getNetwork();
  const chainId = Number(network.chainId);
  if (chainId == 1) {
    const stakeAddress = "0xEE5a6F8a55B02689138c195031d09BAFDc7d278F";
    const stakeContract = new Contract(stakeAddress, HARDSTAKE.abi, signer);
    await stakeContract.withdraw(BigInt(amount*(10**18)));
  } else {
    //console.log("Can only stake VISTA in the ETH network")
  }
}


export async function claimRewardsSTAKE(
  signer,
) {
  const network = await signer.provider.getNetwork();
  const chainId = Number(network.chainId);
  if (chainId == 1) {
  const stakeAddress = "0xEE5a6F8a55B02689138c195031d09BAFDc7d278F";
  const stakeContract = new Contract(stakeAddress, HARDSTAKE.abi, signer);
  await stakeContract.claimShare();
  } else {
    //console.log("Can only stake VISTA in the ETH network")
  }
}


function convertUnixToTimeString(unixTimestamp) {
  let seconds = unixTimestamp;

  // Calculate hours
  const hours = Math.floor(seconds / 3600); // Get full hours
  seconds %= 3600; // Remainder for minutes

  // Calculate minutes
  const minutes = Math.floor(seconds / 60); // Get full minutes
  if(unixTimestamp ===0 || unixTimestamp ===null || unixTimestamp ===''){
    return `0`
  }
  return `Hours: ${hours}, Minutes: ${minutes}`;
}


export async function getStatsSTAKE(
  signer
) {
  try {
    const routerContract = new ethers.Contract("0xCEDd366065A146a039B92Db35756ecD7688FCC77", ROUTER.abi,signer);

    const stakeAddress = "0xEE5a6F8a55B02689138c195031d09BAFDc7d278F";
    const stakeContract = new Contract(stakeAddress, HARDSTAKE.abi, signer);
    const eth_price = (Number(await routerContract.usdcToEth(1)))/Number((10 ** 18));
    
      let usrShare = (await stakeContract.viewShare()).toString();
      let total = (await stakeContract.totalCollected()).toString();
      let pool = (await stakeContract.poolBalance()).toString();
      //console.log(usrShare,total,pool)
      let share = (
        (Number(ethers.formatEther(usrShare)) * 1) /
        eth_price
      ).toFixed(2);
      let totalCollected = (
        (Number(ethers.formatEther(total)) * 1) /
        eth_price
      ).toFixed(2);
      let poolBalance = (
        (Number(ethers.formatEther(pool)) * 1) /
        eth_price
      ).toFixed(2);

      return [
        share.toString(),
        totalCollected.toString(),
        poolBalance.toString(),
      ];
  } catch (err) {
    //console.log(err)
    return [0, 0, 0];
  }
}

export async function getStakerInfoSTAKE(signer) {
  const staker = await signer.getAddress();
  const stakeAddress = "0xEE5a6F8a55B02689138c195031d09BAFDc7d278F";
  const stakeContract = new Contract(stakeAddress, HARDSTAKE.abi, signer);

  let [amountStaked, timeLeftToUnlock, currentShare] = await stakeContract.getStakerInfo(staker);

  let amount = Number(amountStaked)/(10**18);
  let timeLeftFormatted = convertUnixToTimeString(Number(timeLeftToUnlock)).toString();
  return { amount, timeLeftFormatted, currentShare };
}



//HARDLOCK FUNCTIONS


//================ LOCK LP ======================
export async function lock (
  address1,
  amount,
  signer,
  tokenAddress,
  chain
) {
  let RouterChain;
  let ProviderChain;
  const network = await signer.provider.getNetwork();
  const chainId = Number(network.chainId);
  if(chain === 'ethereum'){
    ProviderChain = provider
    RouterChain = new ethers.Contract(
      "0xCEDd366065A146a039B92Db35756ecD7688FCC77",
      ROUTER.abi,
      signer
    );  
    if(chainId !== 1){
      throw Error("Please Switch to ETH chain")
      return;
    }    
  }
  else if(chain === 'base'){
    ProviderChain = baseprovider
    RouterChain = new ethers.Contract(
      "0x4Aab7db193bf3d0083e9db51735Ab3da0328C3d7",
      ROUTER.abi,
      signer
    );  
    if(chainId !== 8453){
      throw Error("Please Switch to BASE chain")
      return;
    }
  }
  else{
    throw Error("Please connect to the right Network")
  }

  let lockerAddress;
  switch (chainId) {
    case 1:
      lockerAddress = "0xF6B510928ab880507246CD6946b7F061Eb8A9C78";
      break;
    case 8453:
      lockerAddress = "0x3EdC8cda5610442a5708CDF5930DbE83C4173257"
      break;
    case 42161:
      lockerAddress = "0xC1a8De4D5eD59385218e957D7CeB2d41c7c74B26"
      break;
    default:
      lockerAddress = "0xF6B510928ab880507246CD6946b7F061Eb8A9C78"
  }

  let accountAddress = await signer.getAddress();
  //const tokenAccount = new Contract(tokenAddress, ERC20.abi,signer)
  //const decimals = await getDecimals(tokenAccount)
  const token = new Contract(address1, ERC20.abi, signer);
  const allowance1 = await token.allowance(
    accountAddress,
    RouterChain.getAddress()
  );
  if (Number(allowance1) < amount*(10**18)) {
    let tx = await token.approve(
      RouterChain.getAddress(),
      BigInt(amount*(10**18))
    );
    await tx.wait();
  }

  await RouterChain.hardstake(lockerAddress, address1, BigInt(amount*(10**18)));
  
}

export async function lpvistalock (
  amount,
  signer
) {
  const routerContract = new ethers.Contract("0xCEDd366065A146a039B92Db35756ecD7688FCC77", ROUTER.abi,signer);

  const network = await signer.provider.getNetwork();
  const chainId = Number(network.chainId);
  if (chainId == 1) {
    let accountAddress = await signer.getAddress();
    const lockAddress = "0x9099EF7f34dC1af0d27e49Dc5B604bcCC03dCB21";
    const VISTA_LP = "0xfdd05552F1377aA488AFed744c8024358AF02041";
    const token = new Contract(VISTA_LP, ERC20.abi, signer);
    const allowance1 = await token.allowance(
      accountAddress,
      routerContract.getAddress()
    );

    if (Number(allowance1) < amount*(10**18)) {
      let tx = await token.approve(
        routerContract.address,
        BigInt(amount*(10**18))
      );
      await tx.wait();
    }
    await routerContract.hardstake(lockAddress, VISTA_LP, BigInt(amount*(10**18)));
  } else {
    //console.log("Can only stake VISTA-LP in the ETH network")
  }
}

export async function lpvistaunlock (
  amount,
  signer,
) {
  const network = await signer.provider.getNetwork();
  const chainId = Number(network.chainId);
  if (chainId == 1) {
    const lockAddress = "0x9099EF7f34dC1af0d27e49Dc5B604bcCC03dCB21";
    const lockContract = new Contract(lockAddress, HARDSTAKE.abi, signer);
    await lockContract.withdraw(BigInt(amount*(10**18)));
  } else {
    //console.log("Can only stake VISTA-LP in the ETH network")
  }
}

export async function lpvistastaker(signer) {
  const staker = await signer.getAddress();

  const lockAddress = "0x9099EF7f34dC1af0d27e49Dc5B604bcCC03dCB21";
  const lockContract = new Contract(lockAddress, HARDSTAKE.abi, signer);

  let [amountStaked, timeLeftToUnlock, currentShare] = await lockContract.getStakerInfo(staker);

  let amount = Number(amountStaked)/(10**18);
  let timeLeftFormatted = convertUnixToTimeString(Number(timeLeftToUnlock)).toString();

  return { amount, timeLeftFormatted, currentShare };
}

export async function claimRewardsLOCK(
  address1,
  signer,
) {
  const network = await signer.provider.getNetwork();
  const chainId = Number(network.chainId);
  let lockerAddress;
  switch (chainId) {
    case 1:
      if(address1 === "0xfdd05552F1377aA488AFed744c8024358AF02041") {
        lockerAddress = "0x9099EF7f34dC1af0d27e49Dc5B604bcCC03dCB21";
      } else {
        lockerAddress = "0xF6B510928ab880507246CD6946b7F061Eb8A9C78";
      }
      break;
    case 8453:
      lockerAddress = "0x3EdC8cda5610442a5708CDF5930DbE83C4173257"
      break;
    case 42161:
      lockerAddress = "0xC1a8De4D5eD59385218e957D7CeB2d41c7c74B26"
      break;
    default:
      lockerAddress = "0xF6B510928ab880507246CD6946b7F061Eb8A9C78"
  }
  if (lockerAddress == "0x9099EF7f34dC1af0d27e49Dc5B604bcCC03dCB21") {
    const lockerContract = new Contract(lockerAddress, HARDLOCK_VISTA.abi, signer);
    await lockerContract.claimShare();
  } else {
    const lockerContract = new Contract(lockerAddress, HARDLOCK.abi, signer);
    await lockerContract.claimShare(address1);
  }
}
export async function getPairAdress(address1,signer){
  try{
    const routerContract = new ethers.Contract("0xCEDd366065A146a039B92Db35756ecD7688FCC77", ROUTER.abi,signer);

    const wethAddress = await routerContract.WETH();

    const pairAddress = await contractUni.getPair(address1, wethAddress);
    return pairAddress;
  }catch(e){
    //console.log(e)
  }
}
export async function getStatsLOCK(
  address1,
  signer,
  chain
) {
  try {
    let contractChain;
    let ProviderChain;
    let RouterChain;
    if(chain === 'ethereum'){
      ProviderChain = provider
      RouterChain = new ethers.Contract(
        "0xCEDd366065A146a039B92Db35756ecD7688FCC77",
        ROUTER.abi,
        signer
      );      
      contractChain = contractUni
    }
    else if(chain === 'base'){
      ProviderChain = baseprovider
      RouterChain = new ethers.Contract(
        "0x4Aab7db193bf3d0083e9db51735Ab3da0328C3d7",
        ROUTER.abi,
        signer
      );  
      contractChain = BasecontractUni
    }
    
    const network = await signer.provider.getNetwork();
    const chainId = Number(network.chainId);
    let lockerAddress;
    switch (chainId) {
      case 1:
      if(address1 == "0xfdd05552F1377aA488AFed744c8024358AF02041") {

        lockerAddress = "0x9099EF7f34dC1af0d27e49Dc5B604bcCC03dCB21";
      } else {
        lockerAddress = "0xF6B510928ab880507246CD6946b7F061Eb8A9C78";
      }
      break;
      case 8453:
        lockerAddress = "0x3EdC8cda5610442a5708CDF5930DbE83C4173257"
        break;
      case 42161:
        lockerAddress = "0xC1a8De4D5eD59385218e957D7CeB2d41c7c74B26"
        break;
      default:
        lockerAddress = "0xF6B510928ab880507246CD6946b7F061Eb8A9C78"
    }
    let lockerContract;
    if (lockerAddress == "0x9099EF7f34dC1af0d27e49Dc5B604bcCC03dCB21") {
      lockerContract = new Contract(lockerAddress, HARDLOCK_VISTA.abi, signer);
    } else {
      lockerContract = new Contract(lockerAddress, HARDLOCK.abi, signer); 

    }

    const eth_price = (Number(await RouterChain.usdcToEth(1)))/Number((10 ** 18));
      let usrShare;
      if (lockerAddress == "0x9099EF7f34dC1af0d27e49Dc5B604bcCC03dCB21") {
        usrShare = (await lockerContract.viewShare()).toString();
      } else {
        usrShare = (await lockerContract.viewShare(address1)).toString();
      }
      let total = (await lockerContract.totalCollected()).toString();
      let share = (
        (Number(ethers.formatEther(usrShare)) * 1) /
        eth_price
      ).toFixed(2);
      let totalCollected = (
        (Number(ethers.formatEther(total)) * 1) /
        eth_price
      ).toFixed(2);
      let poolBalance = 0;
      return [
        share.toString(),
        totalCollected.toString(),
        poolBalance.toString(),
      ];
  } catch (err) {
    //console.log(err);
    return [0, 0, 0];
  }
}

export async function getStakerInfoLOCK(address1, signer) {
  try {
    const staker = await signer.getAddress();
    const network = await signer.provider.getNetwork();
    const chainId = Number(network.chainId);
    let lockerAddress;
    switch (chainId) {
      case 1:
        lockerAddress = "0xF6B510928ab880507246CD6946b7F061Eb8A9C78";
        break;
      case 8453:
        lockerAddress = "0x3EdC8cda5610442a5708CDF5930DbE83C4173257"
        break;
      case 42161:
        lockerAddress = "0xC1a8De4D5eD59385218e957D7CeB2d41c7c74B26"
        break;
      default:
        lockerAddress = "0xF6B510928ab880507246CD6946b7F061Eb8A9C78"
    }
    const lockerContract = new ethers.Contract(lockerAddress, HARDLOCK.abi, signer);

    const [amountStaked, currentShare] = await lockerContract.getStakerInfo(staker, address1);

    return { amountStaked, currentShare };
  } catch (error) {
    console.error("Error fetching staker info:", error);
    return { amountStaked: 0, currentShare: 0 }; // Return default values in case of error
  }
}

export async function launch(
  address1,
  address2,
  amount1,
  amount2,
  amount1min,
  amount2min,
  signer,
  buyLpFee,
  sellLpFee,
  buyCreatorFee,
  sellCreatorFee,
  protocolAddress,
  chain
) {
  try{
    let RouterChain;

if(chain === 'ethereum'){
      RouterChain = new ethers.Contract(
        "0xCEDd366065A146a039B92Db35756ecD7688FCC77",
        ROUTER.abi,
        signer
      );      
    }
    else if(chain === 'base'){
      RouterChain = new ethers.Contract(
        "0x4Aab7db193bf3d0083e9db51735Ab3da0328C3d7",
        ROUTER.abi,
        signer
      );  
    }
  let account = await signer.getAddress();

  const token1 = new Contract(address1, ERC20.abi, signer);
  const token2 = new Contract(address2, ERC20.abi, signer);

  const token1Decimals = await getDecimals(token1);
  const token2Decimals = await getDecimals(token2);

  const amountIn1 = ethers.parseUnits(amount1, token1Decimals);
  const amountIn2 = ethers.parseUnits(amount2, token2Decimals);

  const amount1Min = ethers.parseUnits(amount1min, token1Decimals);
  const amount2Min = ethers.parseUnits(amount2min, token2Decimals);
  //const time = Math.floor(Date.now() / 1000) + 200000;
  //const deadline = ethers.BigNumber.from(time);

  const wethAddress = await RouterChain.WETH();
  //console.log(wethAddress===address1)
  if (address1 === wethAddress) {

    const allowance2 = await token2.allowance(account, RouterChain.getAddress());

    if (allowance2 < amountIn2) {
      let tx = await token2.approve(RouterChain.getAddress(), amountIn2);
      await tx.wait();
    }

    
    await RouterChain.launch(
      address2,
      amountIn2,
      amount2Min,
      amount1Min,
      buyLpFee,
      sellLpFee,
      buyCreatorFee,
      sellCreatorFee,
      protocolAddress,
      { value: amountIn1 }
    );
  } else if (address2 === wethAddress) {
    const allowance1 = await token1.allowance(account, RouterChain.getAddress());

    if (allowance1 < amountIn1) {
      let tx = await token1.approve(RouterChain.getAddress(), amountIn1);
      await tx.wait();
    }

    await RouterChain.launch(
      address1,
      amountIn1,
      amount1Min,
      amount2Min,
      buyLpFee,
      sellLpFee,
      buyCreatorFee,
      sellCreatorFee,
      protocolAddress,
      { value: amountIn2 }
    );
  }
  }catch(e){
    //console.log(e)
  }
}
