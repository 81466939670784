import { type } from "@testing-library/user-event/dist/type";

const { ethers,Contract,parseUnits  } = require("ethers");
const { abi } = require("./abi.json");
const { getTokenIn, getEthIn } = require("./saleFunctions");

const ERC20 = require("./swapping/build/ERC20.json"); //DO NOT CHANGE Erc20 token not lp
const PAIR = require("./swapping/build/IUniswapV2Pair.json"); 
const feePercent = 2;
const FACTORY = require("./swapping/build/IUniswapV2Factory.json");
const ROUTER = require("./swapping/build/UniswapV2Router02.json"); 

//ETH ADDRESSES
const contractAddress = "0xdD218BD2B591Ce02782a1028dad9d314A5e1e7eA"; // Replace with actual contract address
const provider = new ethers.JsonRpcProvider(
  "https://mad-ultimate-halibut.callstaticrpc.com/"
); //We will use our own RPC
const contract = new ethers.Contract(contractAddress, abi, provider);
const contractUni = new ethers.Contract("0x9a27cb5ae0B2cEe0bb71f9A85C0D60f3920757B4", FACTORY.abi, provider);
const RouterSwap = new ethers.Contract("0xCEDd366065A146a039B92Db35756ecD7688FCC77", ROUTER.abi, provider);

//BASE ADDRESSES
const baseprovider = new ethers.JsonRpcProvider(
  "https://base-mainnet.g.alchemy.com/v2/8ijxKDI5eYpsPiEAYGHwnOVVUEz8Mtsh"
); //We will use our own RPC
 
const BaseRouterSwap = new ethers.Contract("0x4Aab7db193bf3d0083e9db51735Ab3da0328C3d7",ROUTER.abi,baseprovider)
const BasecontractUni = new ethers.Contract("0x9C9Dfc8b5D8F1cb1b7f854108Db16BE1C21ea400", FACTORY.abi, baseprovider);
async function fromWei(value) {
  return ethers.formatEther(value);
}
function getRelativeTime(timestamp) {
  const now = Date.now();
  const diffInSeconds = Math.floor((now - timestamp) / 1000);

  if (diffInSeconds < 60) {
    return `${diffInSeconds}s ago`;
  } else if (diffInSeconds < 3600) {
    const minutes = Math.floor(diffInSeconds / 60);
    return `${minutes} min${minutes > 1 ? 's' : ''} ago`;
  } else if (diffInSeconds < 86400) {
    const hours = Math.floor(diffInSeconds / 3600);
    return `${hours} hr${hours > 1 ? 's' : ''} ago`;
  } else {
    const days = Math.floor(diffInSeconds / 86400);
    return `${days} day${days > 1 ? 's' : ''} ago`;
  }
}
// Fetch recent swap events in the past 12 hours
async function fetchRecentSwapEvents(address,tokenCA,chain) {
  let providerChain;
  let RouterChain
  if(chain === 'ethereum'){
    providerChain = provider
    RouterChain = '0xCEDd366065A146a039B92Db35756ecD7688FCC77'
  }else if(chain === 'base'){
    providerChain = baseprovider
    RouterChain = "0x4Aab7db193bf3d0083e9db51735Ab3da0328C3d7"
  }else{ return;}

  const currentBlock = await providerChain.getBlockNumber();
  const pastBlock = currentBlock - Math.floor(16 * 60 * 60 ); 

  const CONTRACT_ADDRESS = address;
  console.log(CONTRACT_ADDRESS)
  const CONTRACT_ABI = [
    {
      "anonymous": false,
      "inputs": [
        { "indexed": true, "internalType": "address", "name": "sender", "type": "address" },
        { "indexed": false, "internalType": "uint256", "name": "amount0In", "type": "uint256" },
        { "indexed": false, "internalType": "uint256", "name": "amount1In", "type": "uint256" },
        { "indexed": false, "internalType": "uint256", "name": "amount0Out", "type": "uint256" },
        { "indexed": false, "internalType": "uint256", "name": "amount1Out", "type": "uint256" },
        { "indexed": true, "internalType": "address", "name": "to", "type": "address" }
      ],
      "name": "Swap",
      "type": "event"
    }
  ];
  const contractHistory = new ethers.Contract(CONTRACT_ADDRESS, CONTRACT_ABI, providerChain);


  const logs = await contractHistory.queryFilter(
    contractHistory.filters.Swap(),
    pastBlock,
    currentBlock
  );

  const recentLogs = logs.slice(-50).reverse();
  console.log(recentLogs)
  return Promise.all(recentLogs.map(async log => {
    const { transactionHash, args: { sender, amount0In, amount1In, amount0Out, amount1Out, to }, blockNumber } = log;

    const tx = await providerChain.getTransaction(transactionHash);
    const block = await providerChain.getBlock(blockNumber);
    let txType = 'Unknown';
    let tokensAmount, ethAmount;
    if ( to === RouterChain) {
          txType = 'Sell';
          tokensAmount =  Number(amount0In) >0  ?  amount0In :  amount1In;
          ethAmount =  Number(amount0Out) > 0 ?  amount0Out :  amount1Out;
        } else {
          txType = 'Buy';
          tokensAmount =  Number(amount0Out) >0 ?  amount0Out :  amount1Out;
          ethAmount =  Number(amount0In) >0 ?  amount0In :  amount1In;
    }
    const token1 = new Contract(tokenCA, ERC20.abi, providerChain);
    const decimals = await getDecimals(token1)
    const relativeTime = getRelativeTime(block.timestamp * 1000);
    return {
      transactionHash,
      txType,
      from: tx.from,
      tokensAmount: Math.round(Number(tokensAmount)/(10 ** Number(decimals))*1000)/1000,
      ethAmount:  Math.round(Number(ethAmount)/(10 ** 18)*1000)/1000,
      timestamp: relativeTime,
    };
  })).then(events => events.filter(event => event !== null));
}

export async function getAmountOut(address1, address2, amountIn,chain) {
  try {
    console.log(address1, address2, amountIn,chain)
    let RouterChain;
    let ProviderChain;
    if(chain === 'ethereum'){
      ProviderChain = provider
      RouterChain = new ethers.Contract(
        "0xCEDd366065A146a039B92Db35756ecD7688FCC77",
        ROUTER.abi,
        provider
      );      
    }
    else if(chain === 'base'){
      ProviderChain = baseprovider
      RouterChain = new ethers.Contract(
        "0x4Aab7db193bf3d0083e9db51735Ab3da0328C3d7",
        ROUTER.abi,
        baseprovider
      );  
    }
    const token1 = new Contract(address1, ERC20.abi, ProviderChain);
    const token2 = new Contract(address2, ERC20.abi, ProviderChain);

    const token1Decimals = await getDecimals(token1);

    const token2Decimals = await getDecimals(token2);
    const amountsOut = await RouterChain.getAmountsOut(
      ethers.parseUnits(String(amountIn), token1Decimals),
      [address1, address2]
    );

    // Use ethers.utils.formatUnits to format the output amount
    const amountOutFormatted = ethers.formatUnits(amountsOut[1], token2Decimals);

    // Convert the formatted string to a number (if desired)
    const amount_out = Number(amountOutFormatted);
    console.log(amount_out)
    return amount_out;
  } catch (e) {
    console.log(e);
    return false;
  }
}


export async function getBalanceAndSymbol(
  address,
  weth_address,
  signer
) {
  const accountAddress = await signer.getAddress();
  try {
    if (address === weth_address) {
      const balanceRaw = await signer.getBalance(accountAddress.toString());
      return {
        balance: ethers.utils.formatEther(balanceRaw),
      };
    } else {
      const token = new Contract(address, ERC20.abi, signer);
      const balanceRaw = await token.balanceOf(accountAddress.toString());
      return Number(balanceRaw / BigInt(10 ** 18))
        

    }
  } catch (error) {
    //console.log(error);
    return false;
  }
}

export async function getDecimals(token) {
  const decimals = await token
    .decimals()
    .then((result) => {
      return result;
    })
    .catch((error) => {
      return 18;
    });
  return decimals;
}



export async function swapTokens(
  address1,
  address2,
  amount,
  signer,
  slippage, // Slippage represented in basis points (e.g., 50 for 0.5%)
  chain
) {
  const network = await signer.provider.getNetwork();
  const chainId = Number(network.chainId);
  let RouterSwap2;
  let contractChain;
  let RouterAddress
  if(chainId === 1){ 
    RouterAddress = "0xCEDd366065A146a039B92Db35756ecD7688FCC77"
    RouterSwap2 = new ethers.Contract("0xCEDd366065A146a039B92Db35756ecD7688FCC77", ROUTER.abi, signer);
    contractChain = contractUni

   }else if(chainId === 8453){
    RouterAddress = "0x4Aab7db193bf3d0083e9db51735Ab3da0328C3d7"

    contractChain = BasecontractUni
    RouterSwap2 = new ethers.Contract("0x4Aab7db193bf3d0083e9db51735Ab3da0328C3d7", ROUTER.abi, signer);

  }else{
    throw Error("Wrong Chain")
  }

  let accountAddress = await signer.getAddress();

  const tokens = [address1, address2];
  const time = Math.floor(Date.now() / 1000) + 200000;
  const deadline = BigInt(time);
  console.log(amount)
  const token1 = new Contract(address1, ERC20.abi, signer);
  const tokenDecimals = await getDecimals(token1);
  // Convert amount to BigInt and handle decimals
  const amountIn = BigInt(Math.round((amount) * (10 ** Number(tokenDecimals))));
  const amountOut = await RouterSwap2.getAmountsOut(
    amountIn,
    tokens
  );

  const wethAddress = await RouterSwap2.WETH();

  const pairAddress = await contractChain.getPair(address1, address2);
  const pairContract = new Contract(pairAddress, PAIR.abi, signer);

  if (address1 === wethAddress) {
    // Buy operation
    const buyTotalFee = await pairContract.buyTotalFee();
    const liqFee = await RouterSwap2.usdcToEth(buyTotalFee);

    // Calculate amountOutMin using integer arithmetic
    const amountOutMin = (amountOut[1] * BigInt(Math.round(10000 - slippage))) / BigInt(10000);
    const totalValue = liqFee + amountIn;
//console.log(amountOutMin)
//console.log(totalValue)
    await RouterSwap2.swapExactETHForTokensSupportingFeeOnTransferTokens(
      amountOutMin,
      tokens,
      accountAddress,
      deadline,
      { value: totalValue }
    );
  } else if (address2 === wethAddress) {
    // Sell operation
    const token2 = new Contract(address1, ERC20.abi, signer);
    //console.log(accountAddress)
    //console.log(RouterSwap2)
    const allowance1 = await token2.allowance(
      accountAddress,
      RouterAddress
    );

    if (allowance1 < amountIn) {
      let tx = await token1.approve(
        RouterAddress,
        amountIn * BigInt(2)
      );
      await tx.wait();
    }

    const sellTotalFee = await pairContract.sellTotalFee();
    const liqFee = await RouterSwap2.usdcToEth(sellTotalFee);

    // Calculate amountOutMin using integer arithmetic
    const amountOutMin = (amountOut[1] * BigInt(Math.round(10000 - slippage))) / BigInt(10000);
    //console.log(amountOutMin)
    const totalValue = (liqFee * BigInt(103)) / BigInt(100); // Equivalent to liqFee * 1.03
    await RouterSwap2.swapExactTokensForETHSupportingFeeOnTransferTokens(
      amountIn,
      amountOutMin,
      tokens,
      accountAddress.toString(),
      deadline,
      { value: totalValue }
    );
  }
}

export function getFactory(address, signer) {
  return new Contract(address, FACTORY.abi, signer);
}
async function initializeEthers() {
  if (!window.ethereum) {
    alert("MetaMask is not installed. Please install it to use this dApp!");
    return null;
  }

  try {
    // Request account access if needed
    await window.ethereum.request({ method: "eth_requestAccounts" });

    // Create a new provider
    const provider = new ethers.BrowserProvider(window.ethereum);

    // Get the signer
    const signer = await provider.getSigner();

    return { provider, signer };
  } catch (error) {
    // console.error('User rejected the request:', error); // Removed //console.log
    return null;
  }
}
export function getRouter(address, signer) {
  return new Contract(address.toString(), ROUTER.abi, signer);
}
//========== READ ONLY ==============

async function getPairAddressForBondedTokenForGraph(address1, address2,chain) {
  try {
    let pairAdd
    console.log(chain)
    if(chain === "ethereum"){
      pairAdd = await contractUni.getPair(address1,address2)
    }
    else if(chain === "base"){
      pairAdd = await BasecontractUni.getPair(address1,address2)
    }
    else{
      throw Error("Not connected to right chain")
    }
    ////console.log(address1)
    ////console.log(address2)

    ////console.log("Pair Address:", pairAdd);
    return pairAdd;
  } catch (error) {
    console.error("Error fetching pair address:", error);
  }
}
async function getFirstBuyer(tokenAddress) {
  try {
    const firstBuyer = await contract.firstBuyer(tokenAddress);
    return firstBuyer;
  } catch (error) {
    // console.error('Error fetching buyer:', error); // Removed //console.log
    throw error;
  }
}

async function getSale(tokenAddress) {
  try {
    const sale = await contract.sales(tokenAddress);
    return {
      creator: sale.creator,
      name: sale.name,
      symbol: sale.symbol,
      totalRaised: Number(sale.totalRaised) / 10 ** 18,
      saleGoal: Number(sale.saleGoal) / 10 ** 18,
      launched: sale.launched,
      nonce: sale.creationNonce,
    };
  } catch (error) {
    // console.error('Error fetching sale:', error); // Removed //console.log
    throw error;
  }
}
async function getCreatorTokens(userAddress) {
  try {
    const creatorTokens = await contract.getCreatorTokens(userAddress);
    return creatorTokens;
  } catch (error) {
    // console.error('Error fetching user bought tokens:', error); // Removed //console.log
    throw error;
  }
}

async function getSaleMetadata(tokenAddress) {
  try {
    const metadata = await contract.saleMetadata(tokenAddress);
    return {
      logoUrl: metadata.logoUrl,
      websiteUrl: metadata.websiteUrl,
      twitterUrl: metadata.twitterUrl,
      telegramUrl: metadata.telegramUrl,
      description: metadata.description,
    };
  } catch (error) {
    // console.error('Error fetching sale metadata:', error); // Removed //console.log
    throw error;
  }
}

async function getUserBoughtTokens(userAddress) {
  try {
    const boughtTokens = await contract.getUserBoughtTokens(userAddress);
    return boughtTokens;
  } catch (error) {
    // console.error('Error fetching user bought tokens:', error); // Removed //console.log
    throw error;
  }
}

//=========== WRITE ==================
async function createSale(
  name,
  symbol,
  logoUrl,
  websiteUrl,
  twitterUrl,
  telegramUrl,
  description,
  ethAmount,
  signer
) {
  try {
    const connection = await initializeEthers();
    if (!connection) return;

    const { provider, signer } = connection;
    const contract = new ethers.Contract(contractAddress, abi, signer);

    const contractWithSigner = await contract.connect(signer);
    const weiAmount = BigInt(Math.round(ethAmount * 10 ** 18));
    const overrides = {
      value: weiAmount,
    };

    const tx = await contractWithSigner.createSale(
      name,
      symbol,
      logoUrl,
      websiteUrl,
      twitterUrl,
      telegramUrl,
      description,
      overrides
    );

    const receipt = await tx.wait();
    return receipt.hash;
  } catch (error) {
    // console.error('Error creating sale:', error); // Removed //console.log
    throw error;
  }
}
async function calculateTokensForEth(ethAmount) {
  const k = 0.222; // adjusted liquidity factor
  const alpha = 2.878 * 10 ** -9; // adjusted scaling factor

  // Calculate tokens based on the formula
  const tokens = Math.log(ethAmount / k + 1) / alpha;

  return tokens;
}
async function buyToken(tokenAddress, ethAmount, slippage, signer, firstBuy) {
  try {
    const contractWithSigner = contract.connect(signer);
    //const saleAddress = (await getSale(tokenAddress)).saleContract;
    /* global BigInt */

    const fee = (ethAmount * feePercent) / 100;
    const amountAfterFee = ethAmount - fee;
    let tokensToBuy;
    if (firstBuy === true) {
      tokensToBuy = await getTokenIn(tokenAddress, Number(amountAfterFee));
    } else {
      tokensToBuy = await calculateTokensForEth(amountAfterFee);
    }
    const minTokensOut = BigInt(Math.round(tokensToBuy * (1 - slippage / 100)));

    const tx = await contractWithSigner.buyToken(tokenAddress, minTokensOut, {
      value: BigInt(Math.round(ethAmount * 10 ** 18)),
    });
    await tx.wait();
    return tx.hash;
  } catch (error) {
    // console.error('Error buying tokens:', error); // Removed //console.log
    throw error;
  }
}

async function sellToken(tokenAddress, tokenAmount, slippage, signer) {
  try {
    const contractWithSigner = contract.connect(signer);
    //const saleAddress = (await getSale(tokenAddress)).saleContract;
    const ethOut = await getEthIn(tokenAddress, Number(tokenAmount));
    const minEthOut = BigInt(Math.round(ethOut * (1 - slippage / 100)));

    const tx = await contractWithSigner.sellToken(
      tokenAddress,
      BigInt(Math.round(tokenAmount * 10 ** 18)),
      minEthOut
    );
    await tx.wait();
    return tx.hash;
  } catch (error) {
    // console.error('Error selling tokens:', error); // Removed //console.log
    throw error;
  }
}

async function claim(tokenAddress, signer) {
  try {
    const contractWithSigner = contract.connect(signer);
    const tx = await contractWithSigner.claim(tokenAddress);
    await tx.wait();
    return tx.hash;
  } catch (error) {
    // console.error('Error claiming tokens:', error); // Removed //console.log
    throw error;
  }
}

async function setSaleMetadata(
  tokenAddress,
  logoUrl,
  websiteUrl,
  twitterUrl,
  telegramUrl,
  description,
  signer
) {
  try {
    const contractWithSigner = contract.connect(signer);
    const tx = await contractWithSigner.setSaleMetadata(
      tokenAddress,
      logoUrl,
      websiteUrl,
      twitterUrl,
      telegramUrl,
      description
    );
    await tx.wait();
    return tx.hash;
  } catch (error) {
    // console.error('Error setting sale metadata:', error); // Removed //console.log
    throw error;
  }
}
export {
  getSale,
  getFirstBuyer,
  getSaleMetadata,
  getUserBoughtTokens,
  createSale,
  buyToken,
  sellToken,
  claim,
  setSaleMetadata,
  getCreatorTokens,
  getPairAddressForBondedTokenForGraph,
  fetchRecentSwapEvents
};
