import React, { useState, useEffect } from "react";
import { launch } from '../../functions/dexfunctions'
import { ethers } from "ethers";
import { popupManager } from "../common/PopupManager";
import { useParams } from "react-router-dom";


const LaunchForm = () => {
  const [token1, setToken1] = useState("0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2"); // Default to ETH
  const [token2, setToken2] = useState("");
  const [liquidity1, setLiquidity1] = useState("");
  const [liquidity2, setLiquidity2] = useState("");
  const [buyFeeAmount, setBuyFeeAmount] = useState("");
  const [sellFeeAmount, setSellFeeAmount] = useState("");
  const [protocolAddress, setProtocolAddress] = useState("");
  const [protocolBuyFee, setProtocolBuyFee] = useState("");
  const [protocolSellFee, setProtocolSellFee] = useState("");
  const [showTokenSelector1, setShowTokenSelector1] = useState(false);
  const [showTokenSelector2, setShowTokenSelector2] = useState(false);
  const [signer, setSigner] = useState(null);
  const [provider, setProvider] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
    const {chain,tokenAddress } = useParams();
  
  const ethAddress = (chain === "base" ? "0x4200000000000000000000000000000000000006" : chain === 'ethereum' ? "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2" :"")



  const initializeProvider = async () => {
    try {
      let provider;
      let signerInstance;
      let chainIDHex = await window.ethereum.request({ method: "eth_chainId" });
      const chainId = await parseInt(chainIDHex, 16);
      //console.log(chainId);
      let chainIdURL = chain === "ethereum" ? 1 : chain === "base" ?  8453 : 1;

      if (chainId !== chainIdURL) {
        setIsConnected(false);
        popupManager.addPopup({
          id: Date.now(),
          type: "error",
          message: "Please Connect to Right Network! ",
          duration: 6000,
        });
        return;
      }
      if (window.ethereum == null) {

        provider = ethers.getDefaultProvider();
      } else {
        provider = new ethers.BrowserProvider(window.ethereum);
        signerInstance = await provider.getSigner();
      await setProvider(provider);
      await setSigner(signerInstance);

      
      setIsConnected(true);        
      }


    } catch (e) {
     //console.log(e)
    }
  };

  useEffect(() => {
    const handleAccountsChanged = async (accounts) => {
      if (accounts.length === 0) {
        // User disconnected all accounts
        setSigner(null);
        setProvider(null);
      } else {
        // User switched accounts
        const newAccount = accounts[0];

        const provider = new ethers.BrowserProvider(window.ethereum);
        const signer = await provider.getSigner();
        setProvider(provider);
        setSigner(signer);

        // Detect wallet type again if necessary
        let detectedWallet = "Unknown Wallet";
        if (window.ethereum.isMetaMask) {
          detectedWallet = "MetaMask";
        } else if (window.ethereum.isCoinbaseWallet) {
          detectedWallet = "Coinbase Wallet";
        } else if (window.ethereum.isBraveWallet) {
          detectedWallet = "Brave Wallet";
        } else if (window.ethereum.isTrust) {
          detectedWallet = "Trust Wallet";
        }
      }
    };

    if (window.ethereum) {
      window.ethereum.on("accountsChanged", handleAccountsChanged);
    }

    initializeProvider();

    return () => {
      if (window.ethereum) {
        window.ethereum.removeListener("accountsChanged", handleAccountsChanged);
      }
    };
  }, []);


  const launchAttempt = async () => {
    let id = Date.now();
      try{
        let chainIDHex = await window.ethereum.request({ method: "eth_chainId" });
        const chainId = await parseInt(chainIDHex, 16);
        //console.log(chainId);
        let chainIdURL = chain === "ethereum" ? 1 : chain === "base" ?  8453 : 1;

        if (chainId !== chainIdURL) {
          setIsConnected(false);
          popupManager.addPopup({
            id: Date.now(),
            type: "error",
            message: "Please Connect to Right Network! ",
            duration: 6000,
          });
          throw Error("Error")
        }
        popupManager.addPopup({
          id: id,
          type: "message",
          message: "Deploying Token...",
        });
        const response = await launch(token1,token2,liquidity1,liquidity2,
          parseFloat(liquidity1)*0.95.toFixed(6).toString(),parseFloat(liquidity2)*0.95.toFixed(6).toString(),signer,
            buyFeeAmount,sellFeeAmount,protocolBuyFee,protocolSellFee,protocolAddress,chain);

            popupManager.removePopup(id);
            let successful_id = Date.now();
      
            popupManager.addPopup({
              id: successful_id,
              type: "success",
              message:
                "Transaction sent!",
            });

        //console.log(response)
      }catch(e){
        popupManager.removePopup(id);
        popupManager.addPopup({
          id: Date.now(),
          type: "error",
          message: e.toString(),
          duration: 6000,
        });
        //console.log(e)
      }
    // Add your launch logic here
  };

  const validateAddress = (address) => {
    const ethAddressRegex = /^0x[a-fA-F0-9]{40}$/;
    return ethAddressRegex.test(address);
  };

  const TokenSelectorPopup = ({ onClose, onSelect }) => {
    const [inputValue, setInputValue] = useState("");
    const [error, setError] = useState("");

    useEffect(() => {
      if (validateAddress(inputValue)) {
        onSelect(inputValue);
        onClose();
      } else if (inputValue.length === 42) {
        setError(
          "Invalid Ethereum address. Must be 42 characters long and start with '0x'."
        );
      } else {
        setError("");
      }
    }, [inputValue, onClose, onSelect]);

    const handleSelectETH = () => {
      onSelect("0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2");
      onClose();
    };

    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
        <div className="bg-[#0e1018] p-6 rounded-lg shadow-lg   w-96">
          <h2 className="text-xl mb-4 kanit-light font-[400] text-white">Paste Address</h2>
          <input
            type="text"
            placeholder="Enter or paste token address"
            value={inputValue}
            onChange={(e) => {
              setInputValue(e.target.value);
            }}
            maxLength={42}
            className="w-full p-2 mb-2  bg-transparent text-white kanit-light border border-white/20  transition-color duration-300  rounded-md focus:outline-none"
          />
          {error && <p className="text-red-500 text-sm mb-2">{error}</p>}
          <button
            onClick={handleSelectETH}
            className="w-full text-white bg-white/5 hover:bg-white/10 border  border-white/10 py-2 rounded-md font-semibold  transition duration-300"
          >
            Select ETH
          </button>
          <button
            onClick={onClose}
            className="w-full text-white bg-white/5 hover:bg-white/10 border mt-2 border-white/10 py-2 rounded-md font-semibold  transition duration-300"
          >
            Cancel
          </button>
        </div>
      </div>
    );
  };

  const formatTokenDisplay = (token) => {
    if (token === "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2") {
      return "ETH";
    } else if (token.length === 42) {
      return `${token.slice(0, 6)}...${token.slice(-4)}`;
    } else {
      return "Select";
    }
  };

  return (
    <div className="p-6 rounded-lg shadow-lg   max-w-lg mx-auto mt-12">
      <h1 className="text-3xl kanit-light font-[500] mb-4 text-center flex items-center mt-4">
        Deploy Token
        <div className="relative group ml-2 mb-4">
          <svg
            className="w-4 h-4 text-gray-400 cursor-pointer"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path d="M18 10c0 4.418-3.582 8-8 8s-8-3.582-8-8 3.582-8 8-8 8 3.582 8 8zM9 7v2h2V7H9zm0 4v4h2v-4H9z" />
          </svg>
          <div
            className="absolute top-full left-1/2 transform -translate-x-1/2 mt-2 w-64 p-2 bg-[#0e1018] border border-white/15 text-white text-xs rounded-lg 
            hidden group-hover:block transition-opacity duration-200 font-[300] text-white"
          >
            Deploy a token by setting custom LP fees and Protocol fees for every buy and sell transaction, while specifying a protocol address that receives ETH from each swap.
          </div>
        </div>
      </h1>


      <form
        className="space-y-4"
        onSubmit={(e) => {
          e.preventDefault();
          launchAttempt();
        }}
      >
        {/* Token 1 and Liquidity */}
        <div>
          <label className="block mb-3 text-start text-[14px] font-semibold text-gray-300">
            Liquidity
          </label>
          <div className="flex items-center w-full p-3 border-t border-b border-white/15 text-gray-300 rounded-md bg-transparent focus:outline-none">
            <input
              type="number"
              value={liquidity1}
              onChange={(e) => setLiquidity1(e.target.value)}
              className="bg-transparent flex-1   focus:outline-none h-6"
              placeholder="Enter liquidity"
            />
            <button
              type="button"
              onClick={() => setShowTokenSelector1(true)}
              className="flex items-center ml-2 focus:outline-none rounded-full  hover:bg-white/5 border border-white/15 px-3 py-1"
            >
              <span className="mr-1 text-[13px]">{formatTokenDisplay(token1)}</span>
              <svg
                className="w-4 h-4  "
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M19 9l-7 7-7-7"
                />
              </svg>
            </button>
          </div>
        </div>

        {/* Token 2 and Liquidity */}
        <div>
          <div className="flex items-center w-full p-3 border-t border-b border-white/15  text-gray-300 rounded-md bg-transparent focus:outline-none">
            <input
              type="number"
              value={liquidity2}
              onChange={(e) => setLiquidity2(e.target.value)}
              className="bg-transparent flex-1   focus:outline-none h-8"
              placeholder="Enter liquidity"
            />
            <button
              type="button"
              onClick={() => setShowTokenSelector2(true)}
              className="flex items-center ml-2 focus:outline-none rounded-full  hover:bg-white/5 border border-white/30 px-3 py-1"
            >
              <span className="mr-1 text-[13px]">{formatTokenDisplay(token2)}</span>
              <svg
                className="w-4 h-4  "
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M19 9l-7 7-7-7"
                />
              </svg>
            </button>
          </div>
        </div>

        {/* Buy Fee Amount */}
        <div>
          <label className=" mb-2 text-start flex font-semibold text-[14px] text-gray-300 mt-8">
            Buy LP Fee Amount
            <div className="relative ml-2 group">
              <svg
                className="w-4 h-4 text-gray-400 cursor-pointer"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M18 10c0 4.418-3.582 8-8 8s-8-3.582-8-8 3.582-8 8-8 8 3.582 8 8zM9 7v2h2V7H9zm0 4v4h2v-4H9z" />
              </svg>
              <div className="absolute bottom-full left-1/2 transform  kanit-light font-[300] text-white -translate-x-1/2 mb-2 w-64 p-2 bg-[#0e1018] border border-white/15   text-xs rounded-lg opacity-0 group-hover:opacity-100 transition-opacity duration-200">
                Fee distributed to LP providers as a flat USD fee (in ETH) 
              </div>
            </div>
          </label>
          <div className="w-full p-2 border-t border-b text-[15px]  border-white/15 text-gray-300 rounded-md bg-transparent focus:outline-none">
            <input
              type="number"
              value={buyFeeAmount}
              onChange={(e) => setBuyFeeAmount(e.target.value)}
              className="bg-transparent w-full   focus:outline-none h-8"
              placeholder="Enter buy fee amount"
            />
          </div>
        </div>

        {/* Sell Fee Amount */}
        <div>
          <label className="flex mb-2 text-[14px] text-start font-semibold text-gray-300">
            Sell LP Fee Amount
            
          </label>
          <div className="w-full p-2 border-t border-b text-[15px] border-white/15 text-gray-300 rounded-md bg-transparent focus:outline-none">
            <input
              type="number"
              value={sellFeeAmount}
              onChange={(e) => setSellFeeAmount(e.target.value)}
              className="bg-transparent w-full   focus:outline-none h-8"
              placeholder="Enter sell fee amount"
            />
          </div>
        </div>

        {/* Protocol Address */}
        <div>
          <label className="text-[14px] mb-3 text-start font-semibold text-gray-300 flex items-center mt-8">
            Protocol Address
            <div className="relative ml-2 group">
              <svg
                className="w-4 h-4 text-gray-400 cursor-pointer"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M18 10c0 4.418-3.582 8-8 8s-8-3.582-8-8 3.582-8 8-8 8 3.582 8 8zM9 7v2h2V7H9zm0 4v4h2v-4H9z" />
              </svg>
              <div className="absolute bottom-full left-1/2 transform kanit-light font-[300] text-white -translate-x-1/2 mb-2 w-64 p-2 bg-[#0e1018] border border-white/15   text-xs rounded-lg opacity-0 group-hover:opacity-100 transition-opacity duration-200">
                Usually the creator address or the smart contract address which
                handles the fee
              </div>
            </div>
          </label>
          <div className="w-full px-2 py-1 text-[15px] border-t border-b border-white/15 text-gray-300 rounded-md bg-transparent focus:outline-none">
            <input
              type="text"
              value={protocolAddress}
              onChange={(e) => setProtocolAddress(e.target.value)}
              className="bg-transparent w-full text-gray-400 focus:outline-none h-12"
              placeholder="Enter Protocol Address"
            />
          </div>
        </div>

        {/* Protocol Buy Fee */}
        <div>
          <label className="block text-[14px] flex mb-2 text-start font-semibold text-gray-300">
            Protocol Buy Fee
            <div className="relative ml-2 group">
              <svg
                className="w-4 h-4 text-gray-400 cursor-pointer"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M18 10c0 4.418-3.582 8-8 8s-8-3.582-8-8 3.582-8 8-8 8 3.582 8 8zM9 7v2h2V7H9zm0 4v4h2v-4H9z" />
              </svg>
              <div className="absolute bottom-full left-1/2 transform kanit-light font-[300] text-white -translate-x-1/2 mb-2 w-64 p-2 bg-[#0e1018] border border-white/15 text-xs rounded-lg opacity-0 group-hover:opacity-100 transition-opacity duration-200">
                This fee is distributed to the protocol address as a flat USD (in ETH) amount when a buy occurs.
              </div>
            </div>
          </label>
          <div className="w-full p-2 border-t border-b text-[15px] border-white/15 text-gray-300 rounded-md bg-transparent focus:outline-none">
            <input
              type="number"
              value={protocolBuyFee}
              onChange={(e) => setProtocolBuyFee(e.target.value)}
              className="bg-transparent w-full   focus:outline-none h-8"
              placeholder="Enter protocol buy fee"
            />
          </div>
        </div>

        {/* Protocol Sell Fee */}
        <div>
          <label className="block text-[14px] mb-2 text-start font-semibold text-gray-300">
            Protocol Sell Fee
          </label>
          <div className="w-full p-2 border-t border-b text-[15px] border-white/15 text-gray-300 rounded-md bg-transparent focus:outline-none">
            <input
              type="number"
              value={protocolSellFee}
              onChange={(e) => setProtocolSellFee(e.target.value)}
              className="bg-transparent w-full   focus:outline-none h-8"
              placeholder="Enter protocol sell fee"
            />
          </div>
        </div>

        {/* Launch Button */}
        {isConnected=== true && 
        <button
          type="submit"
          className="w-full text-white bg-white/5 hover:bg-white/10 border  border-white/10 py-2 rounded-md font-semibold  transition duration-300"
        >
          Launch
        </button> 
      
        }

      </form>
      {isConnected !== true &&
          <button
          onClick={initializeProvider}
          className="w-full text-white bg-white/5 hover:bg-white/10 border  border-white/10 py-2 rounded-md font-semibold  transition duration-300"
        >
          Connect
        </button>      
      }
 
      {/* Token Selector Popups */}
      {showTokenSelector1 && (
        <TokenSelectorPopup
          onClose={() => setShowTokenSelector1(false)}
          onSelect={(token) => setToken1(token)}
        />
      )}
      {showTokenSelector2 && (
        <TokenSelectorPopup
          onClose={() => setShowTokenSelector2(false)}
          onSelect={(token) => setToken2(token)}
        />
      )}
    </div>
  );
};

export default LaunchForm;
