import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useParams } from "react-router-dom";

function formatNumber(number) {
  const parsedNumber = parseFloat(number);

  if (isNaN(parsedNumber)) return "0.0"; 
  
  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 5
  }).format(parsedNumber);
}

function Token({ token }) {
  const { chain } = useParams();

  const [isFallbackImage, setIsFallbackImage] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [tokenNotData,settokenNotData] = useState(null)
  const fallbackUrl = "https://dd.dexscreener.com/ds-data/tokens/ethereum/0xc9bca88b04581699fab5aa276ccaff7df957cbbf.png?size=lg&key=758833";

  const handleImageError = (e) => {
    e.target.onerror = null;
    e.target.src = fallbackUrl;
    setIsFallbackImage(true);
    settokenNotData(true)
  };

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  return (
    <Link  to={`/${chain}/token/${token.address}`}>
      <div className='flex items-center justify-between font-[600] w-full hover:bg-[#6b728010] py-5 rounded-lg relative'>
        <div className='flex gap-2 items-center kanit-light relative'>
          <div className='relative'>
            <img
              alt={token.name}
              onError={handleImageError}
              onLoad={handleImageLoad}
              className='w-5 h-5 sm:w-6 sm:h-6'
              src={token.logo}
            />
            {/* Show checkmark only if image loaded successfully and did not fail */}
            { tokenNotData !== true && imageLoaded && !isFallbackImage  && 
                  (token.address ==="0xC9bCa88B04581699fAb5aa276CCafF7Df957cbbf" ||
                     token.address === "0xD6175692026BcD7Cb12A515e39cF0256eF35cb86" ||
                     token.address === "0x6304563F6639c077B519209f38611C0F67D6f10d" ||
                     token.address === "0xd81E97027c21366Ead8E37428b3c033E95A7ADC2" ||
                     token.address === "0x6A064c1B4A0432c5d7Df441759E4E256F21af8BA" ||
                     token.address === "0x7b20798866Fe3320EC5395e9978a3C98195c7c36" ||
                     token.address === "0xE5f9f6d8a6fDa962cC7448fE903dbae89deA4eD8" ||
                     token.address ==="0x6F0D7bA19646D0eF82bf8f78f3A7456aD5B9481F"

        
                  )? 
                  (<img src='/checkmark.png' className={`absolute  bottom-4 left-[14px]  border-[#0e1018]  w-2 h-2 sm:w-4 sm:h-4 rounded-full flex items-center justify-center`} />)
                  :
                  (tokenNotData !== true && <img src='/checkmarkblue.png' className='absolute bottom-4 left-[14px]  border-[#0e1018]  w-2 h-2 sm:w-4 sm:h-4 rounded-full flex items-center justify-center' />)
          }
          </div>
          <p className='text-[13px] sm:text-[15px] font-[500] text-gray-300'>{token.symbol}</p>
          <p className='text-gray-500 text-[13px] ml-1 font-[300] hidden sm:block'>{token.name}</p>
        </div>
        <div className='flex gap-[3px] sm:gap-[40px] mr-6 sm:mr-12 md:gap-[64px] lg:mr-0 lg:gap-[95px] xl:gap-[116px] text-[9px] sm:text-[13px] outfit text-gray-200 font-[400] items-center'>
          <span className='w-[53px] text-start'>${formatNumber(token.price)}</span>

          <span
            className={`w-[53px] ${
              parseFloat(token.change24h) >= 0 ? 'text-green-400' : 'text-[#f66464]'
            }`}
          >
            {token.change24h}%
          </span>

          <span className='w-[53px] hidden sm:block'>${formatNumber(token.volume)}</span>
          <span className='w-[53px] '>${formatNumber(token.fdv)}</span>

          <span
            className={`w-[53px] hidden lg:block ${
              parseFloat(token.apy) >= 0 ? 'text-green-400' : 'text-[#f66464]'
            }`}
          >
            {Math.round(token.apy)}%
          </span>
        </div>
      </div>
    </Link>
  );
}

export default Token;
