import React, { useEffect,useState } from 'react'
import { getReserves,getBalanceAndSymbol } from '../functions/dexfunctions'
import AddLiquidity from './liquidity/AddLiquidity';
import RemoveLiquidity from './liquidity/RemoveLiquidity';
import Rewards from './liquidity/Rewards';
import { useParams } from "react-router-dom";

export default function Liquidity({token,signer,dataToken}) {
  const {chain,tokenAddress } = useParams();
    const ethAddress = (chain === "base" ? "0x4200000000000000000000000000000000000006" : chain === 'ethereum' ? "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2" :"")
    const [reserve, setReserve] = useState(null);
    const [ethBalance,setethBalance] = useState(null)
    const [tokenBalance,settokenBalance] = useState(null)
    const [activeTab, setActiveTab] = useState('add');
    const [loading, setLoading] = useState(true)
    const [isRotating, setIsRotating] = useState(false);
    const handleClick = () => {
      refreshInfo();
      setIsRotating(true);
    };
    const refreshInfo = async () => {
      fetchLiquidity()
      getBalances()
    }
    const fetchLiquidity = async () => {
        try {
          
          const data = await getReserves(ethAddress,token,signer,chain)
          setReserve(data)
          setLoading(false)

        } catch (error) {
          console.error("Error fetching ETH price:", error);
        }
    };
    const getBalances = async () => {
      try{
        const tokenbal = await getBalanceAndSymbol(token,ethAddress,signer)
        const ethbal = await getBalanceAndSymbol(ethAddress,ethAddress,signer)

        setethBalance(ethbal)
        settokenBalance(tokenbal)
      }catch(e){
        console.log(e)
      }
    }
    useEffect(() => {
        fetchLiquidity()
        getBalances()
    },[])
return (

<div className=" mb-4 w-full h-[80vh]">
{loading === true ? 

  <div className=' flex justify-center items-center w-full h-full'>
    <div className="w-full h-full justify-center items-center flex" role="status">
        <svg aria-hidden="true" class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-gray-600 dark:fill-gray-300" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
        </svg>
        <span class="sr-only">Loading...</span>
    </div>
  </div>

  :
  <>    
    {/* Pool Details */}
        {reserve && (
          <div className="mb-4 text-white mt-12">
            <div className='flex-row  justify-center'>
              <div className='flex justify-center mb-3'>
                <img src='/eth.svg' className='w-7 h-7 ' />
                <img src={dataToken.logo} className='w-7 h-7 rounded-full' />
              </div>
              <p className='kanit-light font-[500] text-2xl flex justify-center'>
  ETH-{dataToken.symbol}
  <div className="relative ml-2">
    <div className="group"> 
      <svg
        className="w-4 h-4 text-gray-400 cursor-pointer"
        fill="currentColor"
        viewBox="0 0 20 20"
      >
        <path d="M18 10c0 4.418-3.582 8-8 8s-8-3.582-8-8 3.582-8 8-8 8 3.582 8 8zM9 7v2h2V7H9zm0 4v4h2v-4H9z" />
      </svg>
      <div
        className="absolute top-full left-1/2 transform -translate-x-1/2 mt-2 w-64 p-2 bg-[#0e1018] border border-white/15 text-white text-xs rounded-lg 
        hidden group-hover:block transition-opacity duration-200 font-[300]"
      >
        In an automated market maker (AMM), liquidity enables decentralized token swaps by creating token pools provided by users, called liquidity providers (LPs). These pools ensure tokens are available for trading and determine prices using mathematical formulas.

        Liquidity plays a crucial role by:

        Enabling Trades: Pools allow traders to exchange tokens without a counterparty.<br />
        Reducing Slippage: Larger pools minimize price changes during swaps.<br />
        Rewarding LPs: LPs earn fees from every trade in their pool, incentivizing participation.
      </div>
    </div>
  </div>
</p>

            </div>
            <div className='flex justify-end w-full mt-6 ' >
            <button className='opacity-70 hover:opacity-100 ' onClick={handleClick}>
              <img
                src="/refresh2.svg"
                className={`h-[14px] w-[14px] mb-2 mr-4 ${isRotating ? 'rotate-once' : ''}`}
                onAnimationEnd={() => setIsRotating(false)}
                alt="Refresh"
              />
            </button> 
            </div>
            
            <div className=' border border-[#ffffff23] bg-gradient-to-b from-white/0   to-[#ffffff00] shadow-lg  rounded-xl py-6 px-4 '>
              <div className="flex items-center text-white/80 gap-3 font-[600] text-[14px] justify-between"><div className='flex gap-2 items-center font-[600]'><img src='/eth.svg' className='w-5 h-5 ' />ETH</div> <p>{reserve[0]}</p> </div>
              <div className="flex items-center mt-4 text-white/80 gap-3 font-[600] text-[14px] justify-between"><div className='flex gap-2 items-center font-[600]'><img src={dataToken.logo} className='w-5 h-5 rounded-full' />{dataToken.symbol}</div> <p>{reserve[1]}</p> </div>
            </div>
            <div className='    border border-[#ffffff23] bg-gradient-to-b from-white/0   to-[#ffffff00]  flex-row mt-5  rounded-xl py-2 px-4 text-center font-[500]  '>
              <p className='text-white/80 text-[13px] font-[400] noto-sans-display '>You have </p>
              <p className='text-white/80 text-[13px] font-[400] noto-sans-display my-[2px] px-2'>{reserve[2]} </p>
              <p className='text-white/80 text-[13px] font-[400] noto-sans-display '>{dataToken.symbol}-ETH LP Tokens</p>
            </div>
           {/*  <p className="">Your VISTA-LP Tokens: {reserve[2]}</p>
            <div>Your Balance</div>
            {ethBalance && <div>{ethBalance} ETH</div>}
            {tokenBalance && <div>{tokenBalance} Tokens</div>}*/}
          </div>
        )}

        {/* Tabs */}
        <div className="flex space-x-2 justify-center mb-2 mt-8 w-full  text-[18px] ">
          <button
            onClick={() => setActiveTab('add')}
            className={`px-3 lg:px-5 py-[3px] lg:py-[6px]  rounded-full text-white/60 border border-white/10   text-[11px] lg:text-[14px]  ${
              activeTab === 'add'
                ? ' bg-white/10  '
                : 'hover:bg-white/5 '
            }`}
          >
            Add Liquidity
          </button>
          <button
            onClick={() => setActiveTab('remove')}
            className={`px-3 lg:px-5 py-[3px] lg:py-[6px]  rounded-full text-white/60 border border-white/10   text-[11px] lg:text-[14px]  ${
              activeTab === 'remove'
                ? 'bg-white/10'
                : 'hover:bg-white/5 '
            }`}
          >
            Remove Liquidity
          </button>
          <button
            onClick={() => setActiveTab('rewards')}
            className={`px-3 lg:px-5 py-[3px] lg:py-[6px]  rounded-full text-white/60  border border-white/10   text-[11px] lg:text-[14px]  ${
              activeTab === 'rewards'
                ? 'bg-white/10 '
                : 'hover:bg-white/5'
            }`}
          >
            Earnings
          </button>
        </div>


        {/* Content */}
        <div className="mt-4">
          {activeTab === 'add' && (
            <AddLiquidity reserve={reserve} dataToken={dataToken} tokenAddress={token} signer={signer} balance={[tokenBalance,ethBalance]} chain={chain} />

          )}
          {activeTab === 'remove' && (
            <RemoveLiquidity reserve={reserve} dataToken={dataToken} tokenAddress={token} signer={signer} chain={chain} />

          )}
          {activeTab === 'rewards' && (
            <Rewards token={token} signer={signer} chain={chain} />

          )}
        </div>
    </>
  }

  </div>
  )
}
