import React, { useEffect, useState } from 'react';
import { getStats,claimRewards } from '../../functions/dexfunctions'
import { ethers } from "ethers";
import { popupManager } from '../common/PopupManager';
import { useParams } from "react-router-dom";
const tokenList = [
  { name: 'Token A', address: '0xTokenAAddress' },
  { name: 'Token B', address: '0xTokenBAddress' },
  { name: 'Token C', address: '0xTokenCAddress' },
];

export default function Rewards({token,signer,chain}) {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedToken, setSelectedToken] = useState(null);
  const [tokenAddress, setTokenAddress] = useState('');
  const [allTimeRewards, setAllTimeRewards] = useState(0);
  const [currentPoolRewards, setCurrentPoolRewards] = useState(0);
  const [featuredTokens, setfeaturedTokens] = useState([]);
  const [userRewards, setUserRewards] = useState(0);
  const [currentRewards, setcurrentRewards] = useState(0);
  const [provider, setProvider] = useState(null);
  const [isConnected, setIsConnected] = useState(false);


  async function fetchR(tokenAddress) {
    try {
      const stats = await getStats(
        tokenAddress,
        signer,
        chain
      );
      setUserRewards(stats[0]);
      setAllTimeRewards(stats[1]);
      setcurrentRewards(stats[2]);
    } catch (error) {
      console.error("Error fetching rewards:", error);
    }
  }


  const handleClaimRewards = async () => {
    let id = Date.now();
    try{
      if (!signer) {
        popupManager.addPopup({
          id: Date.now(),
          type: "error",
          message: "Please connect your wallet.",
          duration: 6000,
        });
        return;
      }
      let chainIDHex = await window.ethereum.request({ method: "eth_chainId" });
      const chainId = await parseInt(chainIDHex, 16);
      //console.log(chainId);
      let chainIdURL = chain === "ethereum" ? 1 : chain === "base" ?  8453 : 1;
      if (chainId !== chainIdURL) {
        popupManager.addPopup({
          id: Date.now(),
          type: "error",
          message: "Please Connect to Right Network! ",
          duration: 6000,
        });
        return;
      }
      popupManager.addPopup({
        id: id,
        type: "message",
        message: "Claiming Rewards",
      });
        const result = await claimRewards(token,signer)
        
        popupManager.removePopup(id);
        let successful_id = Date.now();

        popupManager.addPopup({
          id: successful_id,
          type: "success",
          message:
            "Transaction sent!",
        });
    }catch(e){
      popupManager.removePopup(id);
      popupManager.addPopup({
        id: Date.now(),
        type: "error",
        message: e.toString(),
        duration: 6000,
      });
        //console.log(e)
    }
    };

    const fetchTokens = async () => {
        const response = await fetch('https://etherfun.pro/vistadex/tokens?page=1&sort=fdv:DESC&chain=ethereum');
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setfeaturedTokens(data.data.slice(0,19))
    }
    useEffect(() => {
      fetchR(token)
    }, []);

    return (
    <div className=" mt-12 max-w-3xl mx-auto text-white">
      {/* Claim Button */}

      {token === "0xC9bCa88B04581699fAb5aa276CCafF7Df957cbbf" && <label className="text-center noto-sans-display text-[#989cac] text-[13px] mb-6 flex items-center justify-start">
        <>*You need to hardlock your LP tokens to start earning rewards </>
      </label>}
      {/* Rewards Information */}

      <div className="rewards-info">
  {/* All-Time Rewards */}
  <label className="text-start noto-sans-display text-[#7b7f8f] text-[13px] mb-2 flex items-center justify-start">
    {allTimeRewards  ? (
      <>All-Time Rewards for Users: ${allTimeRewards}</>
    ) : (
      <div className="h-5 animate-pulse bg-white/5 w-full"></div>
    )}
  </label>

  {/* Current Pool Amount of Rewards */}
  <label className="text-start noto-sans-display text-[#7b7f8f] text-[13px] mb-2 flex items-center justify-start">
    {currentRewards  ? (
      <>Current Pool Amount of Rewards: ${currentRewards}</>
    ) : (
      <div className="h-5 animate-pulse bg-white/5 w-full"></div>
    )}
  </label>

  {/* Your Rewards */}
  <label className="text-start noto-sans-display text-[#7b7f8f] text-[13px] mb-2 flex items-center justify-start">
    {userRewards  ? (
      <>Your rewards: ${userRewards}</>
    ) : (
      <div className="h-5 animate-pulse bg-white/5 w-full"></div>
    )}
  </label>
</div>

        <div className="mb-4">
          {userRewards>0 ? 
          <button
            onClick={handleClaimRewards}
            className="px-4 py-2 w-full text-white  bg-white/5 hover:bg-white/10 border  border-white/10 py-2 rounded-md font-semibold  transition duration-300 mt-8"
          >
            Claim Rewards ${userRewards}
          </button>  :
            <button
               disabled
               className="text-white/50 bg-white/5 w-full px-4 border  border-white/10 py-2 rounded-md font-semibold  transition duration-300 mt-8"
             >
               No rewards to claim
             </button> 
          }
        </div> 
    </div>
  );
}
