import React from "react";
import { useEffect, useRef, useState } from "react";

import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { ethers } from "ethers";
import {

  getPairAddressForBondedTokenForGraph,
  getAmountOut,
  swapTokens,
  getBalanceAndSymbol,
  fetchRecentSwapEvents,
} from "../../functions/factoryFunctions.js";
import {
  saleFunctions,
  getEthIn,
  getTokenIn,
  getAllTokenHolders,
  getContract,
  getTokenBalance,
} from "../../functions/saleFunctions.js";
import {
  getSale,
  getUserBoughtTokens,
} from "../../functions/factoryFunctions.js";
import { popupManager } from "../common/PopupManager";
import Liquidity from "../Liquidity.js";
import TradesHistory from "../token/TradesHistory.js";

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

function Token() {
  const {chain,tokenAddress } = useParams();
  const ethAddress = (chain === "base" ? "0x4200000000000000000000000000000000000006" : chain === 'ethereum' ? "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2" :"")
  const [isConnected, setIsConnected] = useState(false);
  const [walletAddress, setWalletAddress] = useState("");
  const [provider, setProvider] = useState(null);
  const [signer, setSigner] = useState(null);

  //const firstbuy = useRef(null);
  const [firstbuy, setfirstbuy] = useState(null);
  const [activeTab, setActiveTab] = useState(1);
  const [activeTradeTab, setActiveTradeTab] = useState(1);
  const [TokenRate, setTokenRate] = useState(null);


  const [ethSellAmount, setSellEthAmount] = useState(0);
  const [estimatedQuote, setEstimatedQuote] = useState(null);
  const [estimatedSellQuote, setEstimatedSellQuote] = useState(null);

  const [loadingBuyQuote, setloadingBuyQuote] = useState(false);
  const [loadingSellQuote, setloadingSellQuote] = useState(false);

  const [slippage, setSlippage] = useState(0.5);
  const [walletBalance, setWalletBalance] = useState(null);
  const [dataToken, setData] = useState([]);
  const [saleData, setSaleData] = useState(null);
  const [finished, setFinished] = useState(null);
  const [tokenPair, setTokenPair] = useState(null);

  const [error, setError] = useState(null);
  const [loading, setload] = useState(true);
  // Add refs to store timeout IDs
  const fetchTokenDataTimeoutId = useRef(null);
  const getFirstBuyTimeoutId = useRef(null);

  //DEX 
  const [ETHPrice,setETHPrice] = useState(null)
  const [MEVProtection, setMEVProtection] = useState(false)
  const [USDT,setUSDT] = useState(false)
  const [USDSelected,setUSDSelected] = useState(false)
  const [swapHistory,setswapHistory] = useState([])
  const [ethBuyAmount, setBuyEthAmount] = useState(0);
  const [USDBuyAmount, setUSDBuyAmount] = useState(0);
  const [tokenSellAmount, settokenSellAmount] = useState(0);
  const [USDSellAmount, setUSDSellAmount] = useState(0);
  const [loadingTrade, setLoadingTrade] = useState(false);

  //LIQUIDITY
  const [liquidityOpen, setliquidityOpen] = useState(false)
  const handletokenSellChange = (amount) => {
    settokenSellAmount(amount)
    setUSDSellAmount(amount*parseFloat(dataToken.price))
  };
  const handleUSDSellAmountChange = (amount) => {
    setUSDSellAmount(amount)
    settokenSellAmount(amount/parseFloat(dataToken.price))
  };
  const handleEthBuyChange = (amount) => {
      setBuyEthAmount(amount)
      setUSDBuyAmount(amount*ETHPrice)
  };
  const handleUSDBuyChange = (amount) => {
    setBuyEthAmount(parseFloat((amount / ETHPrice).toFixed(18)));
    setUSDBuyAmount(amount)  
  };

  const handleTradeTabChange = (tabNumber) => {
    setActiveTradeTab(tabNumber);
  };
  //READ FUNCTIONS
  const getTokenRates = async (amount) => {
    try {
      setloadingBuyQuote(true);

          let tokenCount = await getAmountOut(ethAddress,tokenAddress,amount,chain);
          console.log(tokenCount)
          //console.log(tokenCount)
          setEstimatedQuote(tokenCount);
          setTokenRate(tokenCount);
          setloadingBuyQuote(false);
          return;
        
      } catch (e) {
      //console.log(e)
    }
  };
  //Get how much ETH you are getting for a given amount of tokens
  const getTokenSellRates = async (amount) => {
    try {
        setloadingSellQuote(true);
        let EthCount = await getAmountOut(tokenAddress,ethAddress,amount,chain);

        setEstimatedSellQuote(EthCount)
        setloadingSellQuote(false);
    } catch (e) {
      // Error handling without //console.log
    }
  };

  const fetchETHPrice = async () => {
    try {
      const response = await fetch(
        'https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd'
      );
      const data = await response.json();
      ////console.log("ETH Price (USD):", data.ethereum.usd);
      setETHPrice(data.ethereum.usd)
      return data.ethereum.usd;
    } catch (error) {
      console.error("Error fetching ETH price:", error);
    }
  };
  

  const fetchData = async () => {
    try {
      const response = await fetch(
        `https://etherfun.pro/vistadex/search?query=${tokenAddress}&chain=${chain}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      ////console.log(data.data[0])
      if (data !== dataToken) {
        setload(true);
        setData(data.data[0]);
      }
      setload(false);
      if (data.launched) {
        setFinished(data.launched);
      }
      fetchHistory()
      
        //let tx_signer = await provider.getSigner();
        //////console.log(typeof balanceClaim)
        //////console.log(balanceClaimResponse)
        //
        //const balance = await getBalanceAndSymbol(tokenAddress,ethAddress,tx_signer )
        //////console.log(balance)
        //setWalletBalance(balance);


    } catch (error) {
      //console.log(error)
      // Error handling without //console.log
    }
  };
  const fetchHistory = async () => {
    let pairETH  = await getPairAddressForBondedTokenForGraph(tokenAddress,ethAddress,chain)
    setTokenPair(pairETH)
    const history = await fetchRecentSwapEvents(pairETH,tokenAddress,chain)
    //console.log(history)
    setswapHistory(history)
  }
  

  const SwapBuy = async () => {
    if (!isConnected || !provider || !signer) {
      popupManager.addPopup({
        id: Date.now(),
        type: "error",
        message: "Please connect your wallet before buying tokens.",
        duration: 6000,
      });
      return;
    }
    let chainIDHex = await window.ethereum.request({ method: "eth_chainId" });
    const chainId = await parseInt(chainIDHex, 16);
    //console.log(chainId);
    let chainIdURL = chain === "ethereum" ? 1 : chain === "base" ?  8453 : 1;
    if (chainId !== chainIdURL) {
      setIsConnected(false);
      setWalletAddress("");
      popupManager.addPopup({
        id: Date.now(),
        type: "error",
        message: "Please Connect to Right Network! ",
        duration: 6000,
      });
      return;
    }
    let id = Date.now();

    try {
      setLoadingTrade(true)

      let tx_signer = await provider.getSigner();
      popupManager.addPopup({
        id: id,
        type: "message",
        message: "Swapping " + ethBuyAmount + " ETH of " + tokenAddress,
      });

      let buy = await swapTokens(
        ethAddress,
        tokenAddress,
        ethBuyAmount,
        tx_signer,
        slippage,
        chain
      );
      popupManager.removePopup(id);
      let successful_id = Date.now();

      popupManager.addPopup({
        id: successful_id,
        type: "success",
        message:
          "Successfully Swapped " + ethBuyAmount + " ETH of " + tokenAddress,
      });
      setTimeout(() => {
        popupManager.removePopup(successful_id);
      }, 6000);
    } catch (e) {
      //console.log(e)
      popupManager.removePopup(id);
      popupManager.addPopup({
        id: Date.now(),
        type: "error",
        message: "Error trying to Swap Token: " + e.toString(),
        duration: 6000,
      });
    }finally{
      setLoadingTrade(false)

    }
  };
  const SwapSell = async () => {
    
    if (!isConnected || !provider || !signer) {
      popupManager.addPopup({
        id: Date.now(),
        type: "error",
        message: "Please connect your wallet before buying tokens.",
        duration: 6000,
      });
      return;
    }
    let chainIDHex = await window.ethereum.request({ method: "eth_chainId" });
    const chainId = await parseInt(chainIDHex, 16);
    //console.log(chainId);
    let chainIdURL = chain === "ethereum" ? 1 : chain === "base" ?  8453 : 1;
    if (chainId !== chainIdURL) {
      setIsConnected(false);
      setWalletAddress("");
      popupManager.addPopup({
        id: Date.now(),
        type: "error",
        message: "Please Connect to Right Network! ",
        duration: 6000,
      });
      return;
    }
    let id = Date.now();

    try {
      setLoadingTrade(true)

      let tx_signer = await provider.getSigner();
      popupManager.addPopup({
        id: id,
        type: "message",
        message: "Swapping " + ethSellAmount + " of " + tokenAddress,
      });
      //console.log(ethSellAmount)
      let sell = await swapTokens(
        tokenAddress,
        ethAddress,

        tokenSellAmount,
        tx_signer,
        slippage,
        chain
      );
      popupManager.removePopup(id);
      let successful_id = Date.now();

      popupManager.addPopup({
        id: successful_id,
        type: "success",
        message:
          "Successfully Swapped " + ethSellAmount + " of " + tokenAddress,
      });
      setTimeout(() => {
        popupManager.removePopup(successful_id);
      }, 6000);
      setLoadingTrade(false)

    } catch (e) {
      console.log(e)
      popupManager.removePopup(id);
      popupManager.addPopup({
        id: Date.now(),
        type: "error",
        message: "Error trying to Swap Token: " + e.toString().replace(/Uniswap/gi, "Ethervista"),
        duration: 6000,
      });
      setLoadingTrade(false)

    }
  };
  const SwapSend = async () => {
    if (activeTradeTab===1){
      //if(USDT===true){
        SwapBuy()
      //}
    }else if(activeTradeTab ===2){
      SwapSell()
    }
  }
  const checkIfWalletIsConnected = async () => {
    if (typeof window.ethereum !== "undefined") {
      try {
        const accounts = await window.ethereum.request({
          method: "eth_accounts",
        });
        let chainIDHex = await window.ethereum.request({
          method: "eth_chainId",
        });
        const chainId = await parseInt(chainIDHex, 16);
        //console.log(chainId);
        if (chainId != 1) {
          setIsConnected(false);
          setWalletAddress("");
          popupManager.addPopup({
            id: Date.now(),
            type: "error",
            message: "Please Connect to ETH Network! ",
            duration: 6000,
          });
          return;
        }
        const web3Provider = new ethers.BrowserProvider(window.ethereum);
        setProvider(web3Provider);

        if (accounts.length > 0) {
          setIsConnected(true);
          setWalletAddress(accounts[0]);
          const signerInstance = web3Provider.getSigner();
          setSigner(signerInstance);
        } else {
          setIsConnected(false);
          setWalletAddress("");
        }
      } catch (error) {
        // Error handling without //console.log
      }
    }
  };

  const initializeProvider = async () => {
    try {
      let provider;
      let signer;

      if (window.ethereum == null) {
        popupManager.addPopup({
          id: Date.now(),
          type: "error",
          message: "Try unlocking wallet, or installing Metamask/Phantom: ",
          duration: 6000,
        });
        provider = ethers.getDefaultProvider();
      } else {
        provider = new ethers.BrowserProvider(window.ethereum);
        signer = await provider.getSigner();
        await setProvider(provider)
        await setSigner(signer);
        setIsConnected(true);
      }



    } catch (e) {
      popupManager.addPopup({
        id: Date.now(),
        type: "error",
        message:
          "Try unlocking wallet, or installing Metamask/Phantom: " +
          e.toString(),
        duration: 6000,
      });
    }
  };

  useEffect(() => {
    const handleAccountsChanged = async (accounts) => {
      if (accounts.length === 0) {
        // User disconnected all accounts
        setSigner(null);
        setProvider(null);
      } else {
        // User switched accounts
        const newAccount = accounts[0];

        const provider = new ethers.BrowserProvider(window.ethereum);
        const signer = await provider.getSigner();
        setProvider(provider);
        setSigner(signer);

        // Detect wallet type again if necessary
        let detectedWallet = "Unknown Wallet";
        if (window.ethereum.isMetaMask) {
          detectedWallet = "MetaMask";
        } else if (window.ethereum.isCoinbaseWallet) {
          detectedWallet = "Coinbase Wallet";
        } else if (window.ethereum.isBraveWallet) {
          detectedWallet = "Brave Wallet";
        } else if (window.ethereum.isTrust) {
          detectedWallet = "Trust Wallet";
        }
      }
    };

    if (window.ethereum) {
      window.ethereum.on("accountsChanged", handleAccountsChanged);
    }

    initializeProvider();

    return () => {
      if (window.ethereum) {
        window.ethereum.removeListener("accountsChanged", handleAccountsChanged);
      }
    };
  }, []);

  useEffect(() => {
    if (isConnected && walletAddress) {
      fetchData();
      fetchETHPrice();
    } else {
      setTokenRate(null);
      setWalletBalance(null);
      fetchETHPrice();
      fetchData();
    }
    const interval = setInterval(fetchData, 8000);

    return () => clearInterval(interval);
  }, [isConnected, walletAddress]);

  useEffect(() => {
    if (ethBuyAmount !== 0) {

      getTokenRates(ethBuyAmount);

    } else {
      setEstimatedQuote(null);
    }
  }, [ethBuyAmount,USDBuyAmount]);

  

  useEffect(() => {
    if (tokenSellAmount !== 0) {

      getTokenSellRates(tokenSellAmount);
    } else {
      setEstimatedSellQuote(null);
    }
  }, [tokenSellAmount,USDSellAmount]);

  const handleTabChange = (tabNumber) => {
    setActiveTab(tabNumber);
  };


  useEffect(() => {
    return () => {
      if (fetchTokenDataTimeoutId.current) {
        clearTimeout(fetchTokenDataTimeoutId.current);
      }
      if (getFirstBuyTimeoutId.current) {
        clearTimeout(getFirstBuyTimeoutId.current);
      }
    };
  }, []);

  return (
  <div class="px-2 lg:px-12 mt-32  ">
  
  <div class="flex-col lg:flex lg:flex-row h-auto w-full justify-center mt-4 ">
    {/* TOKEN GRAPH */}
    <div className="w-full flex h-[440px]  px-4 lg:px-4 lg:mr-3 overflow-hidden rounded-lg ">
    <div className="flex h-[440px] w-full gap-12">
      <div className="w-full h-[440px]">
        <iframe
          title="Pair chart"
          src={`https://dexscreener.com/${chain}/${tokenPair}?embed=1&loadChartSettings=0&trades=0&info=0&chartLeftToolbar=0&chartTheme=dark&theme=dark&chartStyle=1&chartType=usd&interval=15`}
          width="100%"
          height="440px"
          frameBorder="0"
        ></iframe>
      </div>
    </div>
  </div>

    {/*<img src="/graph13.JPG" className="flex h-[440px] w-full " /> */}    

    {/* Buy/Sell Tab */}
    <div class={`xl:ml-3  mt-12 mx-auto lg:mt-0 lg:mx-0 w-full px-4 lg:px-3 lg:w-[400px] h-auto pb-8  rounded-lg border border-white/10 pt-3 `} >
          <div class="flex justify-center text-green-400    pb-8 ">
            <button
              className={` py-[8px] w-full flex justify-center text-[14px] lato font-[600] rounded-[2px] ${
                activeTradeTab === 1
                  ? "   border-green-400 border-b-2 "
                  : " text-gray-500    "
              } focus:outline-none`}
              onClick={() => handleTradeTabChange(1)}
            >
              <p className="w-44">Buy {dataToken.symbol}</p>
            </button>
            <button
              className={` py-[8px] w-full flex justify-center text-[14px] lato font-[600]  rounded-sm ${
                activeTradeTab === 2
                  ? "  border-red-400 text-red-400 border-b-2"
                  : " text-gray-500 border-transparent  "
              } focus:outline-none`}
              onClick={() => handleTradeTabChange(2)}
            >
              <p className="w-44">Sell {dataToken.symbol}</p>
            </button>
          </div>
            <div class="">
              {activeTradeTab === 1 ? USDT === false ? USDSelected === true  ? (
                        <div className="relative w-full">
                        <button className="absolute bottom-3 left-4 text-start text-[12px] text-gray-400 font-[600] pr-8 " onClick={()=>setUSDSelected(!USDSelected)}>
                          {ethBuyAmount} ETH
                        </button>
                        <label className="absolute top-[14px] left-[15px] text-start text-[16px] outfit text-gray-300 font-[700]  ">
                            $
                        </label>
                        <input
                          type="number"
                          id="USDBuyAmount"
                          name="USDBuyAmount"
                          value={USDBuyAmount}
                          onChange={(event) => handleUSDBuyChange(event.target.value)}
                          placeholder=""
                          min="0"
                          max="1000"
                          oninput="this.value = Math.min(this.value, 1000)" 

                          className=" overflow-hidden border-b-[1px]  border-t-[1px]  border-white/15 bg-transparent  rounded-lg text-[18px] text-gray-300 font-[700] p-4 pt-[12px] pb-[34px] w-full mb-[3px]  focus:outline-none  pl-[25px] "
                        />
                        <button
                          //onClick={()=>setUSDT(!USDT)}
                          className="absolute right-3 hover:bg-white/10 top-1/2 transform -translate-y-1/2 text-gray-300 text-md font-semibold appearance-none bg-white/5 pr-4 pl-3 py-1 rounded-lg"
                          //value={selectedCurrency}
                          //onChange={(event) => setSelectedCurrency(event.target.value)}
                        >
                          <span className="flex items-center gap-1 py-1">
                            <img src="/eth.svg" alt="ETH" className="w-4 h-4 mr-1" />
                            <p className="text-[13px] font-[700]">ETH</p>
                          {/*<img src="/change.svg" className="w-4 h-4 text-gray-300" alt="change currency" />*/}
                          </span>
                        </button>
                      </div>):(
                        <div className="relative w-full">
                        <button className="absolute bottom-3 left-4 text-start pr-8 text-[12px] text-gray-400 font-[600] " onClick={()=>setUSDSelected(!USDSelected)}>
                            ${USDBuyAmount}
                        </button>


                        <input
                          type="number"
                          id="ethBuyAmount"
                          name="ethBuyAmount"
                          value={ethBuyAmount}
                          onChange={(event) => handleEthBuyChange(event.target.value)}
                          placeholder=""
                          min="0"
                          max="100000000"

                          className="border-b-[1px]  border-t-[1px]  border-white/15 bg-transparent  rounded-lg text-[18px] text-gray-300 font-[700] p-4 pt-[12px] pb-[34px] w-full mb-[3px]  focus:outline-none  pl-4"
                        />
                        <button
                        //onClick={()=>setUSDT(!USDT)}
                          className="absolute right-3 hover:bg-white/10 top-1/2 transform -translate-y-1/2 text-gray-300 text-md font-semibold appearance-none bg-white/5 pr-4 pl-3 py-1 rounded-lg"
                          //value={selectedCurrency}
                          //onChange={(event) => setSelectedCurrency(event.target.value)}
                        >
                          <span className="flex items-center gap-1 py-1">
                            <img src="/eth.svg" alt="ETH" className="w-4 h-4 mr-1" />
                            <p className="text-[13px] font-[700]">ETH</p>
                          {/*<img src="/change.svg" className="w-4 h-4 text-gray-300" alt="change currency" />*/}
                          </span>
                        </button>
                      </div>
              ):(
                        
                              <div className="relative w-full">
                              <button className="absolute bottom-3 left-4 text-start text-[12px] text-gray-400 font-[600] pr-8" onClick={()=>setUSDSelected(!USDSelected)}>
                                {ethBuyAmount} ETH
                              </button>
                              <label className="absolute top-[14px] left-[15px] text-start text-[16px] outfit text-gray-300 font-[700]  ">
                                  $
                              </label>
                              <input
                                type="number"
                                id="USDBuyAmount"
                                name="USDBuyAmount"
                                value={USDBuyAmount}
                                onChange={(event) => handleUSDBuyChange(event.target.value)}
                                placeholder=""
                                min="0"
                                max="100000000"

                                className=" border-b-[1px]  border-t-[1px]  border-white/15 bg-transparent  rounded-lg text-[18px] text-gray-300 font-[700] p-4 pt-[12px] pb-[34px] w-full mb-[3px]  focus:outline-none  pl-[25px] "
                              />
                              <button
                                onClick={()=>setUSDT(!USDT)}
                                className="absolute right-3 hover:bg-white/10 top-1/2 transform -translate-y-1/2 text-gray-300 text-md font-semibold appearance-none bg-white/5 pr-4 pl-3 py-1 rounded-lg"
                                //value={selectedCurrency}
                                //onChange={(event) => setSelectedCurrency(event.target.value)}
                              >
                                <span className="flex items-center gap-1 py-1">
                                  <img src="/tether.svg" alt="USDT" className="w-4 h-4 mr-1" />
                                  <p className="text-[13px] font-[700]">USDT</p>
                                <img src="/change.svg" className="w-4 h-4 text-gray-300" alt="change currency" />
                                </span>
                              </button>
                            </div>
                       
            ):USDSelected === true  ? (
                      <div className="relative w-full ">
                      <button className="absolute bottom-3 left-4 text-start text-[12px] text-gray-400 font-[600] pr-8 " onClick={()=>setUSDSelected(!USDSelected)}>
                        {Math.round(tokenSellAmount*10000)/10000} Tokens
                      </button>
                      <label className="absolute top-[14px] left-[15px] text-start text-[16px] outfit text-gray-300 font-[700]  ">
                          $
                      </label>
                      <input
                        type="number"
                        id="USDBuyAmount"
                        name="USDBuyAmount"
                        value={USDSellAmount}
                        onChange={(event) => handleUSDSellAmountChange(event.target.value)}
                        placeholder=""
                        min="0"
                        max="100000000"
                        className=" border-b-[1px]  border-t-[1px]  border-white/15 bg-transparent  rounded-lg text-[18px] text-gray-300 font-[700] p-4 pt-[12px] pb-[34px] w-full mb-[3px] focus:outline-none  pl-[25px] "
                      />
                      <div
                      //onClick={()=>setUSDT(!USDT)}
                        className="absolute right-3  top-1/2 transform -translate-y-1/2 text-gray-300 text-md font-semibold appearance-none bg-white/5 pr-4 pl-3 py-1 rounded-lg"
                        //value={selectedCurrency}
                        //onChange={(event) => setSelectedCurrency(event.target.value)}
                      >
                        <span className="flex items-center gap-1 py-1">
                          <img src={dataToken.logo}
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src =
                                "https://dd.dexscreener.com/ds-data/tokens/ethereum/0xc9bca88b04581699fab5aa276ccaff7df957cbbf.png?size=md";
                            }} alt="Token" className="w-5 h-5 mr-1 rounded-full" />
                          <p className="text-[13px] font-[700]">{dataToken.symbol}</p>
                        </span>
                      </div>
                    </div>
                ):(
                      <div className="relative w-full">
                      <button className="absolute bottom-3 left-4 text-start pr-8 text-[12px] text-gray-400 font-[600] " onClick={()=>setUSDSelected(!USDSelected)}>
                          ${USDSellAmount}
                      </button>


                      <input
                        type="number"
                        id="tokenSellAmount"
                        name="tokenSellAmount"
                        value={tokenSellAmount}
                        onChange={(event) => handletokenSellChange(event.target.value)}
                        placeholder=""
                        min="0"
                        max="100000000"

                        className="border-b-[1px]  border-t-[1px]  border-white/15 bg-transparent  rounded-lg text-[18px] text-gray-300 font-[700] p-4 pt-[12px] pb-[34px] w-full mb-[3px]  focus:outline-none  pl-4"
                      />
                      <div
                      //onClick={()=>setUSDT(!USDT)}
                        className="absolute right-3  top-1/2 transform -translate-y-1/2 text-gray-300 text-md font-semibold appearance-none bg-white/5 pr-4 pl-3 py-1 rounded-lg"
                        //value={selectedCurrency}
                        //onChange={(event) => setSelectedCurrency(event.target.value)}
                      >
                        <span className="flex items-center gap-1 py-1">
                          <img src={dataToken.logo}
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src =
                                "https://dd.dexscreener.com/ds-data/tokens/ethereum/0xc9bca88b04581699fab5aa276ccaff7df957cbbf.png?size=md";
                            }} alt="Token" className="w-5 h-5 mr-1 rounded-full" />
                          <p className="text-[13px] font-[700]">{dataToken.symbol}</p>
                        </span>
                      </div>
                    </div>
          )}

              <div className="flex justify-between gap-6 mb-3 text-semibold opacity-90 mt-2 px-1 ">
                <div className="flex justify-start items-center gap-2">
                  <p className="text-start text-[12px] text-[#7b7f8f] noto-sans-display font-[500]">
                    Slippage
                  </p>
                  <div className="flex items-center">
                    <input
                      type="text"
                      className="w-12 bg-white/5 text-white text-[14px] font-semibold px-1 border-transparent py-[1px] outline-none"
                      min="0"
                      max="100000"

                      value={slippage}
                      onChange={(event) => setSlippage(event.target.value)}
                      placeholder="0"
                    />
                    <span className="ml-2 text-white  text-[14px] font-semibold">%</span>
                  </div>
                </div>
               {/*} <button onClick={()=>setMEVProtection(!MEVProtection)} class="flex gap-[7px] items-center p-3 ">
                <label for="default-checkbox" class="text-[12px] text-[#7b7f8f] noto-sans-display font-[500]  ">MEV Protection</label>

                    <div className={`w-4 h-4  rounded-[2px] bg-white/10 hover:bg-white/15 border-white/40`}>
                      {MEVProtection === true && <img alt="copy" src="/checkmark2.svg" className="w-full h-full pt-[3px] pb-[2px] px-[3px] text-white " />}

                    </div>
                </button>*/}






                {/*<div className="flex items-center text-[#76c1ff] gap-3 mt-1">
                  <input
                    type="range"
                    min="0"
                    max="100"
                    value={slippage}
                    onChange={(event) => setSlippage(event.target.value)}
                    className="w-full h-[6px] rounded-full  appearance-none cursor-pointer accent-[#627eea]"
                    style={{
                      background: `linear-gradient(to right, #627eea ${slippage}%, #E5E7EB10 ${slippage}%)`
                    }}
                  />
                </div>     
                <div className="grid grid-cols-4 gap-2 mt-2">
                    <button className={` hover:bg-white/10 ${slippage === 0.5 ? 'bg-white/10':'bg-white/5'}  kanit-light font-[400] text-[12px] py-1 w-full`} onClick={()=>setSlippage(0.5)}>0.5%</button>
                    <button className={` hover:bg-white/10 ${slippage === 1 ?   'bg-white/10':'bg-white/5'}  kanit-light font-[400] text-[12px] py-1 w-full`} onClick={()=>setSlippage(1)}>1%</button>
                    <button className={` hover:bg-white/10 ${slippage === 5 ?   'bg-white/10':'bg-white/5'}  kanit-light font-[400] text-[12px] py-1 w-full`} onClick={()=>setSlippage(10)}>10%</button>
                    <button className={` hover:bg-white/10 ${slippage === 25 ?  'bg-white/10':'bg-white/5'}  kanit-light font-[400] text-[12px] py-1 w-full`} onClick={()=>setSlippage(25)}>25%</button>
                </div>*/} 
              </div>
              <div className="h-[1px] bg-white/10 w-full mt-12"></div>

              {loadingBuyQuote === true || loadingSellQuote === true ? (
                <div className="flex flex-col gap-2 noto-sans-display text-[#7b7f8f] text-[13px] font-[500] mt-4">
                  <div className="flex justify-between items-center">
                    <span>You pay:</span>
                    <span className="bg-white/10  w-20 h-[13px]"></span>
                  </div>
                  <div className="flex justify-between items-center">
                    <span>You get:</span>
                    <span className="bg-white/10 w-20 h-[13px]"></span>
                  </div>
                </div>              
                ):
                activeTradeTab === 1 ? (
                <div className="flex flex-col gap-2  noto-sans-display text-[#7b7f8f] text-[13px] font-[500] mt-4">
                    <div className="flex justify-between items-center overflow-hidden "><span>You pay:</span> <span>{Math.round(ethBuyAmount*100000)/100000} ETH <span className="text-[13px] ml-1 ">(${Math.round(ethBuyAmount*ETHPrice*10000)/10000})</span></span></div>
                    <div className="flex justify-between items-center overflow-hidden"><span>You get:</span> <span>{Math.round(estimatedQuote * 0.98 * 100000) / 100000}{" "}{dataToken.symbol}</span></div>
                </div>     
                ):(
                <div className="flex flex-col gap-2  noto-sans-display text-[#7b7f8f] text-[13px] font-[500] mt-4">
                    <div className="flex justify-between items-center"><span>You sell:</span> <span>{tokenSellAmount} {dataToken.symbol}</span></div>
                    <div className="flex justify-between items-center"><span>You get:</span> <span>{Math.round(estimatedSellQuote * 0.98 * 100000) / 100000}{" "} ETH</span></div>
                </div>   
                )        
              }

                  
                  
              {isConnected ? (
                <>
                
                  {loadingTrade === true ? (
                        <button
                        disabled
                        className={`py-2 w-full rounded-[2px] h-10 font-semibold mt-8 flex  justify-center items-center ${
                        ((ethBuyAmount === 0 && activeTradeTab === 1 ) || (tokenSellAmount ===0 && activeTradeTab===2))
                            ? activeTradeTab===1 ? ('bg-green-400 text-black'):('bg-red-400 text-black')
                            : activeTradeTab===1 ? ('bg-green-400 text-black'):('bg-red-400 text-black')
                        } focus:outline-none`}
                        onClick={SwapSend}
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-7 w-auto" viewBox="0 0 200 200"><radialGradient id="a12" cx=".66" fx=".66" cy=".3125" fy=".3125" gradientTransform="scale(1.5)"><stop offset="0" stop-color="#000000"></stop><stop offset=".3" stop-color="#000000" stop-opacity=".9"></stop><stop offset=".6" stop-color="#000000" stop-opacity=".6"></stop><stop offset=".8" stop-color="#000000" stop-opacity=".3"></stop><stop offset="1" stop-color="#000000" stop-opacity="0"></stop></radialGradient><circle transform-origin="center" fill="none" stroke="url(#a12)" stroke-width="15" stroke-linecap="round" stroke-dasharray="200 1000" stroke-dashoffset="0" cx="100" cy="100" r="70"><animateTransform type="rotate" attributeName="transform" calcMode="spline" dur="1" values="360;0" keyTimes="0;1" keySplines="0 0 1 1" repeatCount="indefinite"></animateTransform></circle><circle transform-origin="center" fill="none" opacity=".2" stroke="#000000" stroke-width="15" stroke-linecap="round" cx="100" cy="100" r="70"></circle></svg>                 
                         </button>
                        )
                        :(
                          <button
                          disabled={(ethBuyAmount === 0 && activeTradeTab === 1) || (tokenSellAmount ===0 && activeTradeTab===2)}
                          className={`py-2 w-full rounded-[2px] h-10 font-semibold mt-16  ${
                          ((ethBuyAmount === 0 && activeTradeTab === 1 ) || (tokenSellAmount ===0 && activeTradeTab===2))
                              ? "text-white/40 bg-gray-600/40"
                              : activeTradeTab===1 ? ('bg-green-400 hover:bg-[#4fe988] text-black'):('bg-red-400 hover:bg-[#ff7b7b] text-black')
                          } focus:outline-none`}
                          onClick={SwapSend}
                        >
                          Send Trade
                        </button>
                  )}

                </>
              ) : (
                <button
                  className="py-2 w-full rounded-md font-semibold mt-10 bg-gradient-to-r from-green-400 to-[#34E7A3] text-gray-900 focus:outline-none"
                  onClick={initializeProvider}
                >
                  Connect Wallet
                </button>
              )}

              

            </div>
          {/*{signer && <div>{signer.address.slice(0, 7)}</div>}*/}
        </div>
      </div>
  


  <div class="h-auto w-full mt-8 flex-row bg-black/5 justify-start mb-2">
    {/* Tab buttons */}

    <div className="flex justify-start   mt-6 font-[700]">
    <button
      className={`px-2 py-[6px] border-gray-500 border text-[12px] border-gray-500/40 w-28 ${
        activeTab === 1 ? " bg-white/5 " : "hover:bg-white/5 hover:border-gray-500/40 hover:border-r-transparent border-transparent text-white/50 "
      } focus:outline-none`}
      onClick={() => handleTabChange(1)}
    >
      <span >
      Trades
      </span>
    </button>
    <button
      className={`px-2 py-[6px] border-gray-500 border text-[12px] border-gray-500/40 w-28 ${
        activeTab === 2 ? " bg-white/5 " : "hover:bg-white/5 hover:border-gray-500/40 border-transparent text-white/50 hover:text-white"
      } focus:outline-none`}
      onClick={() => handleTabChange(2)}
    >
      <span>
      Bubble Map
      </span>
    </button>
    { isConnected===true && <button
      className={`px-2 py-[6px] border-gray-500 border text-[12px] border-gray-500/40 w-28 ${
        activeTab === 3 ? " bg-white/5 " : "hover:bg-white/5 hover:border-gray-500/40 border-transparent text-white/50 hover:text-white"
      } focus:outline-none`}
      onClick={() => setliquidityOpen(true)}
    >
      <span>
      Liquidity
      </span>
    </button>}
    </div>
  </div>
  
  {activeTab === 1 && (
  <div className="w-full flex flex-col mt-8 ">
    <div className="grid grid-cols-4 sm:grid-cols-5 md:grid-cols-6 lg:grid-cols-7 xl:grid-cols-8 gap-8 text-white/80  font-[700] border-black/10 mb-4 ">
      <div className=" px-2 py-1 text-start   font-[500]  text-gray-300 text-[12px] sm:text-[14px] border-white/5 ">Type</div>
      <div className=" px-2 py-1 text-start   font-[500]  text-gray-300 text-[12px] sm:text-[14px] border-white/5 ">{dataToken.symbol}</div>
      <div className=" px-2 py-1 text-start   font-[500]  text-gray-300 text-[12px] sm:text-[14px] border-white/5 ">ETH</div>
      <div className=" px-2 py-1 hidden lg:block  text-start   font-[500]  text-gray-300 text-[14px] border-white/5 ">USD</div>
      <div className=" px-2 py-1 text-start hidden sm:block  font-[500]  text-gray-300 text-[12px] sm:text-[14px] border-white/5 ">Trader</div>
      <div className=" px-2 py-1 hidden xl:block text-start   font-[500]  text-gray-300 text-[12px] sm:text-[14px] border-white/5 ">Price</div>
      <div className=" px-2 py-1 text-start   font-[500]  text-gray-300 text-[12px] sm:text-[14px] border-white/5 ">Date</div>
      <div className=" px-2 py-1 hidden md:block text-center   font-[500]  text-gray-300 text-[12px] sm:text-[14px] border-white/5 ">Transaction</div>
    </div>
    <TradesHistory swapHistory={swapHistory} ETHPrice={ETHPrice} />
  </div>
)}

  {activeTab === 2 && (
    <div className="w-full">
      <iframe
        title="bubblemap"
        src={`https://app.insightx.network/bubblemaps/1/${tokenAddress}`}
        className="w-full h-[600px]"
        allowFullScreen
      ></iframe>
    </div>
  )}
{/* Popup */}
{liquidityOpen && (
  <>
    {/* Overlay: covers entire screen and handles outside clicks */}
    <div
      className="fixed inset-0 bg-black bg-opacity-70 z-[9994]"
      onClick={() => setliquidityOpen(false)}
    ></div>

    {/* Modal Container: positioned and with higher z-index than overlay */}
    <div className="fixed inset-0 z-[9999] flex items-center justify-center p-4 "
         onClick={(e) => e.stopPropagation()}>
      <div className="bg-[#0e1018] rounded-lg px-6 py-3 shadow-md w-[90%] max-w-[600px] max-h-[90vh] overflow-y-auto">
        

        <div className="flex mb-4 justify-center items-center w-full h-full relative">
                <button
          className="absolute top-3 right-3 text-white hover:text-gray-300 z-[11999] p-2"
          onClick={() => setliquidityOpen(false)}
        >
          ✕
        </button>
          <Liquidity token={tokenAddress} signer={signer} dataToken={dataToken} />
        </div>

      </div>
    </div>
  </>
)}



  <div className="h-32"></div>
</div>

  );
}

export default Token;
