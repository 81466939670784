import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useParams } from "react-router-dom";

export default function Featured() {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [newListings, setNewListings] = useState([]);
  const [topVolume, setTopVolume] = useState([]);
  const [topGainers, setTopGainers] = useState([]);
  const { chain } = useParams();

  const fetchFeatured = async () => {
    try {
      setError(null);
      setLoading(true);

      let response = await fetch(
        `https://etherfun.pro/vistadex/dashboard?chain=${chain}`
      );

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const fetchedData = await response.json();
      //console.log(fetchedData);
      setNewListings(fetchedData.newlistings.slice(0, 4));
      setTopGainers(fetchedData.topgainers.slice(0, 4));
      setTopVolume(fetchedData.topvolume.slice(0, 4));
    } catch (error) {
      setError(error);
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchFeatured();
  }, [chain]);

  const renderSkeletonRows = (count) => {
    return Array.from({ length: count }).map((_, index) => (
      <div
        key={index}
        className="w-full grid grid-cols-3 py-2 my-1 pr-3 rounded-sm justify-between items-center text-[13px]"
      >
        <div className="flex items-center">
          <div className="w-6 h-6 bg-gray-800 rounded-full animate-pulse"></div>
          <div className="bg-gray-800 h-4 w-20 ml-2 rounded animate-pulse"></div>
        </div>
        <div className="bg-gray-800 h-4 w-20 mx-auto rounded animate-pulse"></div>
        <div className="bg-gray-800 h-4 w-16 ml-auto rounded animate-pulse"></div>
      </div>
    ));
  };

  const renderTable = (title, data, customClass = '') => (
    <div
      className={`w-full h-auto flex-col p-3 pl-4 border border-white/15 rounded-[15px] ${customClass}`}
    >
      <div className="w-full mb-1">
        <p className="text-gray-300 noto-sans-display text-[13px] font-[500] text-start mb-3">
          {title}
        </p>
      </div>
      {loading
        ? renderSkeletonRows(4)
        : data.map((item, index) => (
            <Link key={index} to={`/${chain}/token/${item.address}`}>
              <div className="w-full grid grid-cols-3 py-[7px] px-2 my-1 pr-3 rounded-[4px] hover:bg-gray-500/10 justify-between items-center text-[9px] sm:text-[14px]">
                <div className="flex items-center">
                  <img
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src =
                        'https://dd.dexscreener.com/ds-data/tokens/ethereum/0xc9bca88b04581699fab5aa276ccaff7df957cbbf.png?size=lg&key=758833';
                    }}
                    alt={item.name}
                    className="w-6 h-6 rounded-full"
                    src={item.logo}
                  />
                  <p className="font-[500] text-[12px] sm:text-[13px] text-gray-200 pl-2">
                    {item.symbol}
                  </p>
                </div>
                <p className="text-gray-300 open-sans font-[500] text-start text-[14px]">
                  ${item.price}
                </p>
                <p
                  className={`font-[500] text-[13px] text-end ${
                    item.change24h >= 0 ? 'text-green-400' : 'text-[#f66464]'
                  }`}
                >
                  {item.change24h}%
                </p>
              </div>
            </Link>
          ))}
      {error && (
        <div className="text-center text-red-500 mt-4">
          Error: {error.message}
        </div>
      )}
    </div>
  );

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 rounded-[17px] mx-auto w-full max-w-[1240px] mt-6 ">
      {renderTable('New Listings', newListings)} {/* Always visible */}
      {renderTable('Top Volume', topVolume, 'hidden sm:block')} {/* Hidden on lg */}
      {renderTable('Top Gainers', topGainers, 'hidden lg:block')} {/* Hidden on lg */}
    </div>
  );
}
