import React, { useEffect, useState } from "react";
import { ethers } from "ethers";
import { popupManager } from "./PopupManager";

function Login({chain}) {
  const [providerSet, setProvider] = useState(null);
  const [signerSet, setSigner] = useState(null);
  const [error, setError] = useState(null); 
  const [walletType, setWalletType] = useState(null);
  const [showCopiedNotification, setShowCopiedNotification] = useState(false); 
  const [currentAccount, setCurrentAccount] = useState(null);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [mevProtectionEnabled, setMevProtectionEnabled] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(signerSet.address);
    setShowCopiedNotification(true);
    setTimeout(() => {
      setShowCopiedNotification(false);
    }, 2000);
  };

  const initializeProvider = async () => {
    try {
      let provider;
      let signer;
      if (window.ethereum == null) {
        setError("Wallet not detected. Please install a compatible wallet extension.");
        provider = ethers.getDefaultProvider();
      } else {
        provider = new ethers.BrowserProvider(window.ethereum);
        signer = await provider.getSigner();
        let detectedWallet = "Unknown Wallet";
        if (window.ethereum.isMetaMask) {
          detectedWallet = "MetaMask";
        } else if (window.ethereum.isCoinbaseWallet) {
          detectedWallet = "Coinbase Wallet";
        } else if (window.ethereum.isBraveWallet) {
          detectedWallet = "Brave Wallet";
        } else if (window.ethereum.isTrust) {
          detectedWallet = "Trust Wallet";
        }
        setWalletType(detectedWallet);
      }
      const accounts = await window.ethereum.request({ method: "eth_accounts" });
      setCurrentAccount(accounts[0]);
      setProvider(provider);
      setSigner(signer);
      setError(null);
    } catch (e) {
      console.error(e);
      setError("An error occurred while connecting to the wallet.");
    }
  };

  useEffect(() => {
    const handleAccountsChanged = async (accounts) => {
      if (accounts.length === 0) {
        setCurrentAccount(null);
        setSigner(null);
        setProvider(null);
        setWalletType(null);
      } else {
        const newAccount = accounts[0];
        setCurrentAccount(newAccount);

        const provider = new ethers.BrowserProvider(window.ethereum);
        const signer = await provider.getSigner();
        setProvider(provider);
        setSigner(signer);

        let detectedWallet = "Unknown Wallet";
        if (window.ethereum.isMetaMask) {
          detectedWallet = "MetaMask";
        } else if (window.ethereum.isCoinbaseWallet) {
          detectedWallet = "Coinbase Wallet";
        } else if (window.ethereum.isBraveWallet) {
          detectedWallet = "Brave Wallet";
        } else if (window.ethereum.isTrust) {
          detectedWallet = "Trust Wallet";
        }
        setWalletType(detectedWallet);
      }
    };

    if (window.ethereum) {
      window.ethereum.on("accountsChanged", handleAccountsChanged);
    }

    initializeProvider();

    return () => {
      if (window.ethereum) {
        window.ethereum.removeListener("accountsChanged", handleAccountsChanged);
      }
    };
  }, []);

  const addCustomRPC = async () => {

    if (!window.ethereum || walletType !== "MetaMask") {
      alert("This functionality is only supported on MetaMask. Please add the RPC URL manually.");
      return;
    }

    try {

      await window.ethereum.request({
        method: "wallet_addEthereumChain",
        params: [{
          chainId: "0x1", // Ethereum Mainnet
          chainName: "Ethereum Flashbots",
          rpcUrls: ["https://rpc.flashbots.net/fast"],
          nativeCurrency: {
            name: "Ether",
            symbol: "ETH",
            decimals: 18
          },
          blockExplorerUrls: ["https://etherscan.io"]
        }]
      });
      setMevProtectionEnabled(true)
    } catch (error) {
      console.error("Error adding custom RPC:", error);
      alert("Failed to add the RPC. Please try again or add it manually.");
    }
  };

  return (
    <div className="flex gap-2 relative">
      {signerSet ? (
        <div className="flex">
          <button
            className="z-50 text-bold bg-white/10 hover:bg-yellow-400/10 py-[5px] px-1 xxs:px-3 text-[6px] xxs:text-[9px] md:text-[13px] rounded-full font-[500] outfit flex items-center border border-transparent hover:border-yellow-200/40 transition-all duration-300"
          >
            {walletType === "MetaMask" && (
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/36/MetaMask_Fox.svg/2048px-MetaMask_Fox.svg.png"
                alt="MetaMask"
                className="hidden sm:block w-4 h-4 md:w-6 md:h-6 mr-3"
              />
            )}
            {walletType === "Coinbase Wallet" && (
              <img
                src="https://avatars.githubusercontent.com/u/18060234?s=280&v=4"
                alt="Coinbase Wallet"
                className="hidden sm:block w-4 h-4 md:w-6 md:h-6 mr-3"
              />
            )}
            {walletType === "Brave Wallet" && (
              <img src="/brave.png" alt="Brave Wallet" className="w-4 h-4 md:w-6 md:h-6 mr-3" />
            )}
            {walletType === "Trust Wallet" && (
              <img
                src="https://avatars.githubusercontent.com/u/32179889?s=280&v=4"
                alt="Trust Wallet"
                className="hidden sm:block w-4 h-4 md:w-6 md:h-6 mr-2 md:mr-3"
              />
            )}
            {signerSet.address.slice(0, 7) + "..." + signerSet.address.slice(-5)}
            <button
              onClick={copyToClipboard}
              className="hidden sm:block text-gray-400 hover:text-white ml-2 md:ml-3 "
            >
              {showCopiedNotification ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-5 h-5 hidden sm:block text-green-400"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                </svg>
              ) : (
                <img alt="copy" src="/clone.svg" className="w-4 hidden sm:block h-4 md:w-5 md:h-5 text-white opacity-50 hover:opacity-100" />
              )}
            </button>
          </button>

          {/* Settings button */}
          { chain !== "base" &&  <button
              className="bg-white/10 text-bold text-[10px] ml-1 sm:text-[13px] lg:text-[16px] px-1 hover:bg-white/15 sm:ml-2 xs:px-2 sm:px-3 rounded-full"
              onClick={() => setSettingsOpen(true)}
            >&#x1F916;
            {/*<img src="/settings.svg" className="h-[14px] w-[14px]" alt="Settings" />*/}
          </button>}

          {/* Full-screen popup overlay */}
          {settingsOpen && (
            <div
              className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
              onClick={() => setSettingsOpen(false)}
            >
              <div
                className="bg-[#12151e] rounded-[17px] p-6 max-w-md w-full relative"
                onClick={(e) => e.stopPropagation()}
              >
                <button
                  className="absolute top-5 right-7 text-gray-300 hover:text-gray-100"
                  onClick={() => setSettingsOpen(false)}
                >
                  ✕
                </button>
                <h2 className="text-lg font-[400] mb-5  text-white/90 kanit-light ">BOT Protection</h2>
                
                <div className="flex items-center gap-2 mb-1">
                 
                  <label className="text-white/90 font-medium kanit-light mb-1 ">MEV Protection</label>
                </div>
                
                <p className="text-sm text-start font-[300] text-white/40  kanit-light mb-5">
                MEV Protection shields you from front-running attacks on Ethereum by configuring a custom RPC URL in Metamask. Once you add the Flashbot RPC to Metamask and activate it, your transactions are secured with anti-bot protection
                </p>

                
                <div className="flex justify-end gap-4 text-sm">
                  <button
                    className="px-6 py-2 bg-white/5 border border-white/10 text-white/80 hover:bg-white/10 rounded-md font-semibold"
                    onClick={() => setSettingsOpen(false)}
                  >
                    Close
                  </button>
                  {mevProtectionEnabled ?
                    <button
                    disabled
                    className="px-5 py-2 border flex gap-2 bg-white/5  border-white/10 text-white/80 hover:bg-white/10 rounded-md font-semibold"
                  >
                    Added <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-5 h-5 hidden sm:block text-green-400"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                </svg>
                  </button>:
                  <button
                    className="px-6 py-2 border bg-white/5 border-white/10  hover:bg-white/10 rounded-md font-semibold text-white/80"
                    onClick={addCustomRPC}
                  >
                    Add
                  </button>}
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <button
          className="z-50 bg-white/10 hover:text-yellow-300/80 hover:bg-yellow-400/10 py-[5px] text-[10px] px-3 xxs:text-[10px] xxs:px-4 sm:px-5 xs:text-[12px] sm:text-[13px] rounded-full font-[500] outfit flex items-center border border-transparent hover:border-yellow-200/40 transition-all duration-300"
          onClick={initializeProvider}
        >
          Connect
        </button>
      )}
    </div>
  );
}

export default Login;
